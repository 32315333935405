import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IResponseRole, IRole } from "../types/admin";
import { IResponse } from "../types/api";

const url = RouteApi.roles;

export const getListRole = () => {
	return apiClient.get(url).json<IResponse<Array<IResponseRole>>>();
};

export const createRole = (payload: IRole) => {
	return apiClient.post(url, { json: payload }).json<IResponse<any>>();
};

export const deleteRole = async (idRole: string) => {
	const res = await apiClient.delete(url + "/" + idRole).json<any>();
	return res;
};
export const updateRole = async ({ idRole, ...payload }: any) => {
	const res = await apiClient
		.put(url + "/" + idRole, { json: payload })
		.json<any>();
	return res;
};
