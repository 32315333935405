import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ClipboardIcon, MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import TooltipContainer from "../../../../components/TooltipContainer";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { toast } from "../../../../components/ui/use-toast";
import { useCopyToClipboard } from "../../../../hooks/useCopy";
import { getTopTracking } from "../../../../services/affiliateService/tracking";

const TopLinkTracking = () => {
  const { data: result } = useQuery({
    queryKey: ["topTrackingAffiliate"],
    queryFn: () => getTopTracking(),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const getTop = result?.data || [];

  const [, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        toast({
          title: "Copy thành công",
          description: text,
        });
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };
  return (
    <div>
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader>
          <CardTitle>Top link tracking</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Mã link</TableHead>
                {/* <TableHead>Trạng thái</TableHead> */}
                <TableHead className="text-center">Desktop</TableHead>
                <TableHead className="text-center">Mobile</TableHead>
                {/* <TableHead className="text-center">Lượt booking</TableHead>
                <TableHead className="text-center">
                  Booking hoàn thành
                </TableHead> */}
                <TableHead className="hidden text-nowrap md:table-cell">
                  Ngày tạo
                </TableHead>
                <TableHead>
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {(getTop?.length || 0) > 0 &&
                getTop.slice(0, 5).map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="flex items-center justify-start gap-2 font-medium">
                        <span>{item.affiliateLink?.code}</span>
                        {item.affiliateLink.status.status !== -1 && (
                          <TooltipContainer content="copy link">
                            <Button
                              onClick={() => {
                                if (!!item.affiliateLink?.reference) {
                                  handleCopy(
                                    `${process.env.REACT_APP_LINK_AFFILIATE}/${item.affiliateLink._id}?expert=${
                                      item.affiliateLink?.reference
                                        ?.expertname || ""
                                    }`,
                                  );
                                  return;
                                }
                                handleCopy(
                                  `${process.env.REACT_APP_LINK_AFFILIATE}/${item.affiliateLink._id}`,
                                );
                              }}
                              type="button"
                              variant="ghost"
                            >
                              <ClipboardIcon className="h-4 w-4" />
                            </Button>
                          </TooltipContainer>
                        )}
                      </TableCell>
                      {/* <TableCell className="font-medium">
                        <StatusBadgeLink
                          status={item.affiliateLink?.status || 0}
                        />
                      </TableCell> */}
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalDesktopClick}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalMobileClick}
                      </TableCell>
                      {/* <TableCell className="text-center">
                        {item?.affiliateLink?.totalBook}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalBookSuccess}
                      </TableCell> */}
                      <TableCell className="hidden md:table-cell">
                        {dayjs(item?.affiliateLink?.createdAt || "").format(
                          "DD-MM-YYYY ",
                        )}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem>
                              <Link
                                className="w-full"
                                to={`/links/${item?.affiliateLink?._id}`}
                              >
                                Chi tiết
                              </Link>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default TopLinkTracking;
