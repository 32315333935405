import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Lock } from "lucide-react";
import React from "react";
import { Control, FieldPath, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { editprofileUser, loginUser } from "../../services/affiliate";
import { useInfoUserStore } from "../../store/user.store";
import { IRequestBodyEditUser } from "../../types/user";
import { toast } from "../ui/use-toast";
import { ERoleAccount } from "../../types/enum";
import { updateAdmin } from "../../services/administrators";

const schemaResetPassword = z
  .object({
    passwordOld: z.string(),
    password: z
      .string()
      .min(5, { message: "Mật khẩu phải ít nhất 5 ký tự" })
      .max(20, { message: "Mật khẩu tối đa 20 ký tự" }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Mật khẩu không giống nhau",
    path: ["confirmPassword"],
  });

interface IFromReset {
  onHandle?: () => void;
  role?: ERoleAccount;
}
const FormResetPassword = (props: IFromReset) => {
  const {
    onHandle = () => {
      return;
    },
    role = ERoleAccount.User,
  } = props;
  const infoUser = useInfoUserStore((state) => state.inforUser);

  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    IRequestBodyEditUser,
    unknown
  >({
    mutationFn: role === ERoleAccount.Admin ? updateAdmin : editprofileUser,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      formResetPassword.reset();
      toast({
        variant: "success",
        title: "Cập nhập thành công",
        duration: 2000,
      });
      onHandle();
    },
  });

  const { mutate: login, isPending: isPendingLogin } = useMutation<
    any,
    HTTPError,
    { username: string; password: string },
    unknown
  >({
    mutationFn: loginUser,
    async onError(error, variables, context) {
      formResetPassword.setError("passwordOld", { message: "Sai mật khẩu" });
    },
    onSuccess(data, variables, context) {
      mutate({ password: formResetPassword.getValues().password });
    },
  });
  const pending = isPending && isPendingLogin;
  const formResetPassword = useForm<z.infer<typeof schemaResetPassword>>({
    resolver: zodResolver(schemaResetPassword),
    defaultValues: {
      passwordOld: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof schemaResetPassword>) => {
    const { passwordOld } = values;
    login({ username: infoUser.username, password: passwordOld });
  };

  return (
    <div>
      <Form {...formResetPassword}>
        <form
          onSubmit={formResetPassword.handleSubmit(onSubmit)}
          className="space-y-2"
        >
          <SignupFormField
            name="passwordOld"
            label="Mật khẩu cũ"
            placeholder="Vui lòng nhập mật khẩu cũ"
            description=""
            inputType="password"
            formControl={formResetPassword.control}
            icon={Lock}
          />
          <SignupFormField
            name="password"
            label="Mật khẩu mới"
            placeholder="Vui lòng nhập mật khẩu mới"
            description=""
            inputType="password"
            formControl={formResetPassword.control}
            icon={Lock}
          />
          <SignupFormField
            name="confirmPassword"
            label="Nhập lại mật khẩu"
            placeholder="Vui lòng nhập lại mật khẩu"
            description=""
            inputType="password"
            formControl={formResetPassword.control}
            icon={Lock}
          />
          <div className="text-end">
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="defaultCustom"
              type="submit"
              disabled={pending}
            >
              Cập nhập mật khẩu
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof schemaResetPassword>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof schemaResetPassword>, any>;
  icon: any;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <div className="py-1"></div>
          {/* {description && <FormDescription>{description}</FormDescription>} */}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormResetPassword;
