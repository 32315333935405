import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Lock } from "lucide-react";
import React from "react";
import { useCookies } from "react-cookie";
import { Control, FieldPath, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import useLogout from "../../hooks/useLogout";
import { setPassword_Admin } from "../../services/adminService/auth";
import { setPassword } from "../../services/affiliate";
import { ERoleAccount } from "../../types/enum";
import { toast } from "../ui/use-toast";
import { useSearchParams } from "react-router-dom";

const schemaResetPassword = z.object({
  passwordConfirm: z.string(),
  password: z.string().min(5, "Mật khẩu phải ít nhất 5 ký tự"),
});

interface IFromReset {
  onHandle?: () => void;
  role?: ERoleAccount;
}
const FormSetPassword = (props: IFromReset) => {
  const { role = ERoleAccount.User } = props;
  const handleLogout_Admin = useLogout({ redirect: "/admin/login" });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie, removeCookie] = useCookies(["token"]);
  const handleLogout = () => {
    removeCookie("token", { path: "/login" });
    window.location.href = "/login";
  };
  const [searchParams] = useSearchParams();
  const verifyToken = searchParams.get("verifyToken") || "";
  const { mutate: mutateSetPassword } = useMutation<
    any,
    HTTPError,
    { password: string; token: string },
    unknown
  >({
    mutationFn: role === ERoleAccount.Admin ? setPassword_Admin : setPassword,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Đổi mật khẩu thất bại",
      });
    },
    onSuccess(data, variables, context) {
      toast({
        variant: "success",
        title: "Đổi mật khẩu thành công",
      });
      if (role === ERoleAccount.Admin) {
        handleLogout_Admin();
        return;
      }
      handleLogout();
    },
  });
  const formResetPassword = useForm<z.infer<typeof schemaResetPassword>>({
    resolver: zodResolver(schemaResetPassword),
    defaultValues: {
      passwordConfirm: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof schemaResetPassword>) => {
    const { password } = values;
    mutateSetPassword({ password: password, token: verifyToken });
  };

  return (
    <div>
      <Form {...formResetPassword}>
        <form
          onSubmit={formResetPassword.handleSubmit(onSubmit)}
          className="space-y-4"
        >
          <SignupFormField
            name="password"
            label="Mật khẩu mới"
            placeholder="Vui lòng nhập mật khẩu mới"
            description=""
            inputType="password"
            formControl={formResetPassword.control}
            icon={Lock}
          />
          <SignupFormField
            name="passwordConfirm"
            label="Nhập lại mật khẩu"
            placeholder="Vui lòng nhập lại mật khẩu"
            description=""
            inputType="password"
            formControl={formResetPassword.control}
            icon={Lock}
          />
          <div className="text-end">
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="defaultCustom"
              type="submit"
            >
              Cập nhập mật khẩu
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof schemaResetPassword>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof schemaResetPassword>, any>;
  icon: any;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormSetPassword;
