import { Navigate } from "react-router-dom";
import { useInfoAdminStore } from "../../store/admin.store";
import { useInfoUserStore } from "../../store/user.store";
import LoginEmailPasswordAdmin from "./loginEmailPassword";

const AdminLogin = () => {
  const infoAdmin = useInfoAdminStore((state) => state.inforAdmin);
  const infoUser = useInfoUserStore((state) => state.inforUser);

  if (infoAdmin._id || infoUser._id) {
    return <Navigate to={"/admin"} />;
  }

  return (
    <div>
      <div className="flex h-screen items-center justify-start bg-background">
        <div className="hidden h-screen w-[80%] lg:block">
          <img
            className="h-full w-full object-cover"
            src="https://preview.colorlib.com/theme/bootstrap/login-form-02/images/bg_1.jpg.webp"
            alt="login"
          />
        </div>
        <div className="flex h-screen w-full items-center justify-center bg-card p-8">
          <div className="w-[500px] max-w-[500px] lg:w-[400px]">
            <LoginEmailPasswordAdmin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
