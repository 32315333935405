import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  FormatMoneyToFixed,
  FormatPercentNumber,
} from "../../../../helpers/formatNumber";
import { useInfoUserStore } from "../../../../store/user.store";
import { IInforDetailAdmin } from "../../../../types/admin";

interface IDashboardCard extends IInforDetailAdmin {
  overviews: {
    commission: number;
    income: number;
    totalBook: number;
    totalBookSuccess: number;
    totalClick: number;
    totalLink: number;
  };
}

const DashboardCard = () => {
  const inforUser = useInfoUserStore((state) => state.inforUser);
  const getDataUser = inforUser as IDashboardCard;

  const DataAnalitisHome = [
    {
      icon: "/icons/ic_sale.png",
      price: FormatPercentNumber(getDataUser.commissionPercent || 0),
      title: "% hoa hồng",
      priceChange: "0.43%",
    },
    {
      icon: "/icons/ic_sale.png",
      price:
        FormatMoneyToFixed(getDataUser.overviews?.commission || 0) + " VNĐ",
      title: "Tiền hoa hồng(Tạm tính)",
      priceChange: "0.43%",
      link: "/income",
    },
    {
      icon: "/icons/ic_payment.png",
      price: FormatMoneyToFixed(getDataUser.overviews?.income || 0) + " VNĐ",
      title: "Tiền về ngân hàng",
      priceChange: "0.43%",
      link: "/income",
    },
    {
      icon: "/icons/ic_total_user.png",
      price: `${getDataUser.overviews?.totalBook}`,
      title: "Tổng gói dịch vụ",
      priceChange: "0.43%",
    },
    {
      icon: "/icons/ic_total_user.png",
      price: `${getDataUser.overviews?.totalBookSuccess}`,
      title: "Gói dịch vụ hoàn thành",
      priceChange: "0.43%",
    },
    {
      icon: "/icons/ic_payment.png",
      price: getDataUser.overviews?.totalClick,
      title: "Tổng lượt click",
      priceChange: "0.43%",
    },
    {
      icon: "/icons/ic_payment.png",
      price: getDataUser.overviews?.totalLink,
      title: "Tổng link đã tạo",
      priceChange: "0.43%",
    },
  ];
  return (
    <div className="grid gap-4 md:grid-cols-4">
      {DataAnalitisHome.map((item, index) => {
        const isLink = !!item?.link;
        return (
          <Card className="relative" key={index}>
            {isLink && (
              <Link
                className="absolute z-10 block h-full w-full bg-neutral-800 opacity-0 transition-opacity duration-300 hover:opacity-50"
                to={`${item?.link}` || ""}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#FFFF"
                  stroke="#FFFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="tra absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"
                >
                  <path d="M18 8L22 12L18 16" />
                  <path d="M2 12H22" />
                </svg>
              </Link>
            )}
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-base font-medium">
                {item.title}
              </CardTitle>
              <img
                className="size-12 object-contain"
                src={item.icon}
                alt="askany"
              />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{item.price}</div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default DashboardCard;
