import { RouteApi } from "../../apis/routeApi";
import apiClient from "../../apis/user.api";
const url = RouteApi.affiliates;

export const verifyPhone = async (data: { otp: string }) => {
  const res = await apiClient
    .get(url + "/phone-verification", { searchParams: data })
    .json<any>();
  return res;
};
