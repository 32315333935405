import apiAdmin from "../apis/admin.api";
import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IListUser } from "../types/admin";
import { IListResponse, IResponse } from "../types/api";
import {
  IRequestAdminUpdateUser,
  IRequestBodyUser,
  IUser,
} from "../types/user";
import { CreateLinkRequest, EditLinkRequest, IAffiliateLink } from "./link";

const url = RouteApi.adminAffiliate;

export interface GetLinkUserRequset {
  skip: number;
  limit: number;
  q: string;
  status: string;
  email: string;
  phone: string;
}

interface GetLinkListRequset {
  status: string;
  sort: number;
  skip: number;
  limit: number;
  affiliateId: string;
  startTime: string;
  endTime: string;
}
interface IAffiliateCreateLink {
  sort: string;
  skip: number;
  limit: number;
  search: string;
  startTime: string;
  endTime: string;
}

interface ResAffiliateCreatLink_Admin {
  affiliate: IUser;
  totalBook: number;
  totalBookSuccess: number;
  totalClick: number;
  totalDesktopClick: number;
  totalLink: number;
  totalMobileClick: number;
}

export interface ReqCreateTransaction {
  affiliate: string;
  // affiliateLink: string;
  bank: string;
  amount: string;
  orderID: string;
}

export const getListUser = (
  payload: Partial<GetLinkUserRequset> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliates", { searchParams: { ...fields } })
    .json<IListResponse<IListUser>>();
};

export const createUser = (payload: IRequestBodyUser) => {
  return apiAdmin.post(url + "/affiliates", { json: payload }).json<any>();
};

export const updateUser = ({
  id,
  payload,
}: {
  id: string;
  payload: IRequestAdminUpdateUser;
}) => {
  return apiAdmin
    .put(url + "/affiliates/" + id, { json: { ...payload } })
    .json<any>();
};

export const getLinksAdmin = (payload: Partial<GetLinkListRequset>) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliate-links", { searchParams: { ...fields } })
    .json<IListResponse<IAffiliateLink>>();
};

export const getLinkOffersAdmin = (
  payload: Partial<{
    skip?: number;
    limit?: number;
    sort?: string;
    affiliateId?: string;
    affiliateLink?: string;
    type: string;
  }> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliate-links/offers", { searchParams: { ...fields } })
    .json<
      IResponse<{
        isOver: boolean;
        offers: any[];
        total: number;
      }>
    >();
};

export const createLinkAdmin = (payload: CreateLinkRequest) => {
  return apiClient
    .post(url + "/affiliate-links", { json: payload })
    .json<IAffiliateLink>();
};

export const editLinkAdmin = ({ id, ...payload }: EditLinkRequest) => {
  return apiClient
    .put(url + "/affiliate-links/" + id, { json: payload })
    .json<IAffiliateLink>();
};

export const deleteLinkAdmin = (id: string) => {
  return apiClient.delete(url + "/affiliates" + id).json<IAffiliateLink>();
};

export const getAffiliateCreateLinks = (
  payload: Partial<IAffiliateCreateLink> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliate-links/affiliate", {
      searchParams: { ...fields },
    })
    .json<IListResponse<ResAffiliateCreatLink_Admin>>();
};

export const createTransactionAffiliate = (payload: ReqCreateTransaction) => {
  return apiClient.post(url + "/transactions", { json: payload }).json<any>();
};

export const getOverviewAdmin = () => {
  return apiClient.get(url + "/overviews").json<IResponse<any>>();
};
