import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import {
  Banknote,
  ChevronsUpDown,
  CreditCard,
  Mail,
  SquareUserRound,
  User,
} from "lucide-react";
import React, { useState } from "react";
import {
  Control,
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldValues,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { cn } from "../../lib/utils";
import {
  createCardBank,
  updateCardBankUser,
} from "../../services/bank.services";
import { useBankStore } from "../../store/bank.store";
import { useInfoUserStore } from "../../store/user.store";
import { IInforDetailAdmin } from "../../types/admin";
import {
  EActionForm,
  EKeyQueryApi,
  EStatusBank,
  ETypeCardBank,
} from "../../types/enum";
import { TDetailBank } from "../../types/user";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { toast } from "../ui/use-toast";

const schemaCreateBank = z.object({
  accountName: z.string().min(3, { message: "Vui lòng nhập tên chủ thẻ" }),
  cardNumber: z.string().min(5, { message: "Vui lòng nhập số tài khoản" }),
  mst: z.string().min(3, { message: "Vui lòng nhập mã số thuế" }),
  cccd: z.string().min(3, { message: "Vui lòng nhập mã căn cước" }),
  email: z
    .string()
    .min(1, { message: "Vui lòng nhập email" })
    .email("Email không hợp lệ"),
  bankCode: z.string(),
});

interface IFromReset {
  onHandle?: () => void;
  dataUser?: IInforDetailAdmin;
  dataBank?: Partial<TDetailBank>;
  actionForm?: EActionForm;
}
const FormCreatCardBank = (props: IFromReset) => {
  const {
    onHandle = () => {
      return;
    },
    dataBank = {},
    actionForm = EActionForm.ADD,
  } = props;
  const queryClient = useQueryClient();

  const [openSelect, setOpenSelect] = useState(false);

  const formCreateBank = useForm<z.infer<typeof schemaCreateBank>>({
    resolver: zodResolver(schemaCreateBank),
    defaultValues: {
      accountName: dataBank?.accountName || "",
      bankCode: dataBank?.bankCode || "",
      cardNumber: dataBank?.cardNumber || "",
      mst: dataBank?.mst || "",
      cccd: dataBank?.cccd || "",
      email: dataBank?.email || "",
    },
  });

  const infoUser = useInfoUserStore((state) => state.inforUser);

  const { mutate } = useMutation<any, HTTPError, any, unknown>({
    mutationFn:
      actionForm === EActionForm.ADD ? createCardBank : updateCardBankUser,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title:
          actionForm === EActionForm.ADD
            ? "Thêm thẻ thất bại"
            : "Chỉnh sửa thẻ thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyUser] });
      formCreateBank.reset();
      toast({
        variant: "success",
        title:
          actionForm === EActionForm.ADD
            ? "Thêm thành công"
            : "Cập nhập thành công",
        duration: 2000,
      });
      onHandle();
    },
  });

  const listBank = useBankStore((state) => state.listBank);
  const updateInfoBank = useBankStore((state) => state.updateInfoBank);

  const onSubmit = async (values: z.infer<typeof schemaCreateBank>) => {
    const temListBank = listBank || [];
    const indexExist = temListBank?.findIndex(
      (item: any) => item.code === values.bankCode,
    );

    const payload = {
      affiliate: infoUser._id,
      accountName: values.accountName,
      bankCode: listBank[indexExist || 0].code,
      cardNumber: values.cardNumber,
      mst: values.mst,
      cccd: values.cccd,
      email: values.email,
      bankName: listBank[indexExist || 0].name,
      cardType: ETypeCardBank.CREDIT,
      status: EStatusBank.Pending,
    };
    if (actionForm === EActionForm.ADD) {
      mutate(payload);
    } else {
      const {
        formState: { dirtyFields },
      } = formCreateBank;
      const payloadEdit = getDirtyFields(values, dirtyFields);

      const result = await updateInfoBank({
        ...payloadEdit,
        status: EStatusBank.Pending,
        idBank: dataBank?._id,
      });

      if (!result.error) {
        toast({
          variant: "success",
          title: "Cập nhật thành công",
          duration: 2000,
        });
        formCreateBank.reset();
        onHandle();
        return;
      }
      toast({
        variant: "destructive",
        title:
          result?.message === "AFF_0016"
            ? "Thẻ ngân hàng đã bị xóa!"
            : "Cập nhật thất bại",
        duration: 2000,
      });
      formCreateBank.reset();
      onHandle();
      return;
    }
  };

  return (
    <div className="">
      <Form {...formCreateBank}>
        <form
          onSubmit={formCreateBank.handleSubmit(onSubmit)}
          className="space-y-4"
        >
          <FormField
            control={formCreateBank.control}
            name="bankCode"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Ngân hàng</FormLabel>
                <Popover open={openSelect} onOpenChange={setOpenSelect} modal>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openSelect}
                        className={cn(
                          "justify-between",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value
                          ? listBank.find(
                              (language: any) => language.code === field.value,
                            )?.name
                          : "Chọn ngân hàng"}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="PopoverContent p-0">
                    <Command>
                      <CommandInput placeholder="Tìm kiếm..." />
                      <CommandEmpty>Không tìm thấy kết quả</CommandEmpty>
                      <CommandGroup>
                        <CommandList>
                          {listBank.map((language: any) => (
                            <CommandItem
                              value={language.code}
                              key={language.id}
                              onSelect={() => {
                                formCreateBank.setValue(
                                  "bankCode",
                                  language.code,
                                  { shouldDirty: true },
                                );
                                setOpenSelect(false);
                              }}
                              className="flex items-start justify-start gap-3 text-foreground"
                            >
                              <div className="size-16 flex-shrink-0 rounded-lg bg-white">
                                <img
                                  className="h-full w-full object-contain"
                                  src={language?.logo}
                                  alt="bank"
                                />
                              </div>
                              <div className="flex h-full flex-col items-start justify-between gap-1">
                                <p className="line-clamp-1 block text-base font-semibold">
                                  {language.shortName}
                                </p>
                                <p className="line-clamp-2 block text-xs text-slate-300">
                                  {language.name}
                                </p>
                              </div>
                            </CommandItem>
                          ))}
                        </CommandList>
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>

                <FormMessage />
              </FormItem>
            )}
          />

          <SignupFormField
            name="accountName"
            label="Tên chủ thẻ"
            placeholder="Nguyen van A"
            description="At least 3 characters."
            formControl={formCreateBank.control}
            icon={User}
          />
          <SignupFormField
            name="email"
            label="Email"
            placeholder="abc@gmail.com"
            description="At least 8 characters."
            formControl={formCreateBank.control}
            icon={Mail}
          />
          <SignupFormField
            name="cardNumber"
            label="Số tài khoản"
            placeholder="XXX 123 XXX"
            description="At least 8 characters."
            formControl={formCreateBank.control}
            icon={CreditCard}
          />
          <SignupFormField
            name="mst"
            label="Mã số thuế"
            placeholder="090xxxxxx0"
            description="At least 8 characters."
            formControl={formCreateBank.control}
            icon={Banknote}
          />
          <SignupFormField
            name="cccd"
            label="CCCD/Hộ chiếu"
            placeholder="090xxxxxx0"
            description="At least 8 characters."
            formControl={formCreateBank.control}
            icon={SquareUserRound}
          />
          <div className="flex-item flex justify-end gap-5">
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="outline"
              type="button"
              onClick={onHandle}
            >
              Hủy
            </Button>
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="defaultCustom"
              type="submit"
            >
              Cập nhập
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof schemaCreateBank>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof schemaCreateBank>, any>;
  icon: any;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}

export default FormCreatCardBank;
