import { RouteApi } from "../../apis/routeApi";
import apiClient from "../../apis/user.api";
import { IResponse } from "../../types/api";

const url = RouteApi.adminAffiliate;

export const getChartClickTracking_Admin = (
  payload: Partial<{
    year?: number;
  }>,
) => {
  return apiClient
    .get(url + "/affiliate-trackings/clicks/statistics", {
      searchParams: { ...payload },
    })
    .json<IResponse<any>>();
};
export const getTopTracking_Admin = () => {
  return apiClient
    .get(url + "/affiliate-trackings/clicks/top")
    .json<IResponse<any>>();
};
