import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { PenLine } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import TooltipContainer from "../../../components/TooltipContainer";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { useToast } from "../../../components/ui/use-toast";
import { EditLinkRequest, editLink } from "../../../services/link";
import { EKeyQueryApi } from "../../../types/enum";
import ReferenceField from "./ReferenceField";

const formEditLinkSchema = z.object({
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string().optional(),
});

const EditLinkForm = ({ link }: any) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const haveReference = !!link.reference;
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    EditLinkRequest,
    unknown
  >({
    mutationFn: editLink,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyLink] });
      toast({
        variant: "success",
        title: "Chỉnh sửa link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });

  const form = useForm<z.infer<typeof formEditLinkSchema>>({
    resolver: zodResolver(formEditLinkSchema),
    defaultValues: {
      description: link.description,
      name: link.name,
    },
  });

  const onSubmit = (values: z.infer<typeof formEditLinkSchema>) => {
    if (haveReference) {
      mutate({ id: link._id, ...values });
    } else {
      const { reference, ...payload } = values;
      mutate({ id: link._id, ...payload });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <TooltipContainer content="Chỉnh sửa">
        <DialogTrigger asChild>
          <Button size="icon" variant="outlineCustom">
            <PenLine className="size-4" />
          </Button>
        </DialogTrigger>
      </TooltipContainer>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Chỉnh sửa link</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {haveReference && (
              <ReferenceField
                form={form}
                name="reference"
                defaultReference={link.reference}
              />
            )}
            <FormItem>
              <FormLabel>Code</FormLabel>
              <FormControl>
                <Input disabled value={link.code} />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit, (e) => {
              console.log("e", e);
            })}
          >
            Lưu
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditLinkForm;
