/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { Loader2, Search } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { cn } from "../../lib/utils";
import {
  ICategory,
  getListCategories,
} from "../../services/categories.services";
import { EKeyQueryApi } from "../../types/enum";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

const CategoryInput = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (cateId?: ICategory) => void;
}) => {
  const [category, setCategory] = useState<ICategory>();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (category?._id !== value) {
      setOpen(false);
    }
    onChange?.(category);
  }, [category, value]);

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="inline-flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-background px-3 py-2 text-sm font-medium text-muted-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
        {category?.vi.name || "Chọn danh mục"}
      </PopoverTrigger>
      <PopoverContent className="PopoverContent p-0">
        <ListCategory setCategory={setCategory} currentCategory={value} />
      </PopoverContent>
    </Popover>
  );
};

const ListCategory = ({
  setCategory,
  currentCategory,
}: {
  setCategory?: (cate?: ICategory) => void;
  currentCategory?: string;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [EKeyQueryApi.keyCategories],
    queryFn: getListCategories,
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
  });
  const listAll = useMemo(() => {
    const list: ICategory[] = [];
    const queue = [...data.data];
    while (queue.length > 0) {
      const cate = queue.pop();
      if (cate) {
        if (cate.childs && cate.childs.length > 0) {
          queue.push(...cate.childs);
        }
        list.push(cate);
      }
    }
    return list;
  }, [data.data]);

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    setCategory?.(listAll.find((c) => c._id === currentCategory));
  }, [currentCategory, listAll, setCategory]);

  return (
    <div>
      <div className="flex items-center border-b px-3">
        <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
        <input
          className="flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="scrollbar max-h-[200px] px-4">
        {!search
          ? data?.data?.map((cate) => (
              <CategoryAccordion
                setCategory={setCategory}
                cate={cate}
                key={cate?._id}
              />
            ))
          : listAll
              .filter((cate) =>
                cate?.vi?.name?.toLowerCase().includes(search.toLowerCase()),
              )
              .map((cate) => (
                <button
                  className="flex w-full flex-1 items-center justify-between border-b py-2 text-sm last:border-0 hover:underline"
                  onClick={() => setCategory?.(cate)}
                >
                  {cate?.vi?.name || " "}
                </button>
              ))}
        {isLoading && (
          <div className="grid w-full place-items-center py-2">
            <Loader2 className="animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
};

const CategoryAccordion = ({
  cate,
  onClick,
  _first = true,
  setCategory,
}: {
  cate: ICategory;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  _first?: boolean;
  setCategory?: (cate: ICategory) => void;
}) => {
  if ((cate.childs?.length || 0) <= 0) {
    return (
      <button
        className={cn(
          "flex w-full flex-1 items-center justify-between border-b py-2 text-sm last:border-0 hover:underline",
          _first ? "" : "pl-4",
        )}
        onClick={(e) => {
          e.stopPropagation();
          setCategory?.(cate);
          onClick?.(e);
        }}
      >
        {cate?.vi?.name || ""}
      </button>
    );
  }
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={cate._id} className={_first ? "" : "pl-4"}>
        <AccordionTrigger className="py-2 text-sm font-normal">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setCategory?.(cate);
              onClick?.(e);
            }}
          >
            {cate?.vi?.name || ""}
          </button>
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {cate.childs?.map((c) => (
            <CategoryAccordion
              _first={false}
              key={c._id}
              cate={c}
              onClick={onClick}
              setCategory={setCategory}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default CategoryInput;
