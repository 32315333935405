import { useQuery } from "@tanstack/react-query";
import React from "react";
import TitlePage from "../../../components/titlePage";
import { Skeleton } from "../../../components/ui/skeleton";
import WidgetTile from "../../../components/widgetTitle";
import { FormatMoneyToFixed } from "../../../helpers/formatNumber";
import { getIncomeTransactions } from "../../../services/transactions";
import HistoryPayment from "./historyPayment";

const Income = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["icome"],
    queryFn: () => getIncomeTransactions(),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const renderCard = [
    {
      title: "Tiền hoa hồng(Tạm tính)",
      amount: FormatMoneyToFixed(data?.data?.commisionWallet || 0),
    },
    {
      title: "Tiền về ngân hàng",
      amount: FormatMoneyToFixed(data?.data?.incomeWallet || 0),
    },
  ];
  return (
    <div>
      <TitlePage title="Thu nhập" />
      <WidgetTile title="Thông tin thu nhập" />
      <div className="flex flex-wrap items-center justify-start gap-5">
        {renderCard.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="w-full max-w-[350px] rounded-xl border bg-card text-card-foreground shadow">
                <div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
                  <h3 className="text-sm font-medium tracking-tight">
                    {item.title}
                  </h3>
                  <div className="rounded-xl bg-blue-600 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-circle-dollar-sign"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8" />
                      <path d="M12 18V6" />
                    </svg>
                  </div>
                </div>
                <div className="p-6 pt-0">
                  {isFetching ? (
                    <Skeleton className="h-8 w-[250px]" />
                  ) : (
                    <div className="text-2xl font-bold">{item?.amount} VNĐ</div>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <HistoryPayment />
    </div>
  );
};

export default Income;
