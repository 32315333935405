import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IResponse } from "../types/api";

const url = RouteApi.adminAffiliate;

export const getTransactionsAdmin_Admin = (
	{
		affiliateId,
		...payload
	}: Partial<{
		skip?: number;
		limit?: number;
		affiliateId?: string;
		type?: string;
		status?: string;
	}> = { skip: 0, limit: 5 }
) => {
	const fields = Object.fromEntries(
		Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined)
	);
	return apiClient
		.get(url + "/transactions/" + affiliateId, { searchParams: { ...fields } })
		.json<
			IResponse<{
				isOver: boolean;
				listData: any[];
				total: number;
			}>
		>();
};

export const getTransactionsPayRecent_Admin = (
	{
		affiliateId,
		...payload
	}: Partial<{
		skip?: number;
		limit?: number;
		affiliateId?: string;
		type?: string;
		status?: string;
	}> = { skip: 0, limit: 5 }
) => {
	const fields = Object.fromEntries(
		Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined)
	);
	return apiClient
		.get(url + "/transactions/pay/recent", {
			searchParams: { ...fields },
		})
		.json<
			IResponse<{
				isOver: boolean;
				listData: any[];
				total: number;
			}>
		>();
};

export const getTransactionsRevenue_Admin = (
	payload: Partial<{
		year?: number;
	}>
) => {
	return apiClient
		.get(url + "/transactions/revenue/monthly", {
			searchParams: { ...payload },
		})
		.json<IResponse<any>>();
};
