import { Navigate, Outlet } from "react-router-dom";
import { useInfoUserStore } from "../store/user.store";
import { ROLE } from "../constants/roles.constants";

const PrivateAdminRoutes = () => {
	const role = sessionStorage.getItem('role')

	if(role !== ROLE.ADMIN) {
		return <Navigate to={'/'} replace />
	}
	return (
		<>
			<Outlet />
		</>
	);
};

export default PrivateAdminRoutes;
