import { useMemo } from "react";
import {
  createPath,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
} from "../ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const Paginate = ({
  skip,
  limit,
  total,
  isOver = true,
}: {
  skip: number;
  limit: number;
  total: number;
  isOver: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const pageNumber = Math.ceil((total || 1) / limit);
  const currentPage = Math.floor(skip / limit);
  const lastPage = pageNumber - 1;

  const listPages = useMemo(() => {
    const list: number[] = [];
    const cal = (pageNumber - currentPage > 2) ? 
      (currentPage - 2) : 
      (pageNumber - 5);
    for(let i = Math.max(0, cal); i < currentPage; i++) {
      list.push(i);
    }
    const currentLenth = 5 - list.length;
    for(let i = currentPage; i < Math.min(currentPage + currentLenth, pageNumber); i++) {
      list.push(i);
    }
    return list;
  }, [currentPage, pageNumber])

  return (
    <Pagination className="justify-end">
      <PaginationContent>
        <PaginationItem>
          <Select
            defaultValue={limit.toString()}
            onValueChange={(l) => {
              setSearchParams((params) => {
                params.set("limit", l);
                params.set("skip", "0");
                return params;
              });
            }}
          >
            <SelectTrigger className="w-16">
              <SelectValue placeholder="Số dòng" />
            </SelectTrigger>
            <SelectContent className="w-16">
              <SelectItem value="5">5</SelectItem>
              <SelectItem value="10">10</SelectItem>
              <SelectItem value="20">20</SelectItem>
              <SelectItem value="100">100</SelectItem>
            </SelectContent>
          </Select>
        </PaginationItem>
        {(() => {
          const sp = createSearchParams(searchParams);
          sp.set("skip", ((currentPage - 1) * limit).toString())
          return (
            <PaginationItem className="sm:block hidden">
              <PaginationPrevious
                to={createPath({
                  ...location,
                  search: sp.toString(),
                })}
                className={currentPage === 0 ? "hidden" : ""}
              >
              </PaginationPrevious>
            </PaginationItem>
          );
        })()}
        {listPages.map((p) => (
          <PageItem limit={limit} page={p} isActive={currentPage === p} key={p}/>
        ))}
        {(() => {
          const sp = createSearchParams(searchParams);
          sp.set("skip", ((currentPage + 1) * limit).toString());
          return (
            <PaginationItem className="sm:block hidden">
              <PaginationNext
                to={createPath({
                  ...location,
                  search: sp.toString(),
                })}
                className={currentPage === lastPage ? "hidden" : ""}
              >
              </PaginationNext>
            </PaginationItem>
          );
        })()}
      </PaginationContent>
    </Pagination>
  );
};

export default Paginate;

const PageItem = ({
  isActive,
  page,
  limit,
}: {
  isActive?: boolean;
  page: number;
  limit: number;
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const sp = createSearchParams(searchParams);
  sp.set("skip", ((page) * limit).toString());
  return (
    <PaginationItem>
      <PaginationLink
        to={createPath({
          ...location,
          search: sp.toString(),
        })}
        isActive={isActive}
      >
        {page + 1}
      </PaginationLink>
    </PaginationItem>
  );
};
