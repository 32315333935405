/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { getListExpertsByCategory } from "../../services/experts.services";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

const ExpertByCategoryInput = ({
  category,
  disabled,
  onChange,
  defaultExpert,
}: {
  category?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (expert: any) => void;
  defaultExpert?: any;
}) => {
  const [expert, setExpert] = useState<any>(defaultExpert);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    onChange?.(expert);
  }, [expert]);

  useEffect(() => {
    setExpert(undefined);
  }, [category]);

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className="inline-flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-background px-3 py-2 text-sm font-medium text-muted-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
        disabled={disabled || !category}
      >
        {expert?.fullname || "Chọn chuyên gia"}
      </PopoverTrigger>
      <PopoverContent className="PopoverContent p-0">
        <ListExpertByCategory
          category={category}
          onExpertClick={(e) => {
            setExpert(e);
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

const ListExpertByCategory = ({
  category,
  onExpertClick,
  disabled = false,
  value,
  onChange,
}: {
  category?: string;
  onExpertClick?: (exprt: any) => void;
  disabled?: boolean;
  value?: string;
  onChange?: (exprt: any) => void;
}) => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ["experts", category],
    queryFn: ({ pageParam }) =>
      getListExpertsByCategory({
        category: category || "",
        skip: pageParam,
        limit: 20,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => pages?.length * 10,
    enabled: !disabled && !!category,
  });

  return (
    <div className="scrollbar max-h-[200px]">
      {data?.pages.map((page) =>
        page.data.listData.map((e) => (
          <button
            key={e._id}
            className="flex w-full items-center gap-2 border-b p-2 text-sm last:border-0 hover:underline"
            onClick={() => onExpertClick?.(e)}
          >
            <Avatar className="h-5 w-5">
              <AvatarImage src={e.avatar?.location} />
              <AvatarFallback>{e.fullname[0]}</AvatarFallback>
            </Avatar>
            {e.fullname}
          </button>
        )),
      )}
      {!isFetching && hasNextPage && (
        <button
          onClick={() => fetchNextPage()}
          className="w-full py-2 text-sm hover:underline"
        >
          {" "}
          Tải thêm
        </button>
      )}
      {isFetching && (
        <div className="grid w-full place-items-center py-2">
          <Loader2 className="animate-spin" />
        </div>
      )}
    </div>
  );
};

export default ExpertByCategoryInput;
