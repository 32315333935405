import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { ArrowLeft, Phone } from "lucide-react";
import React, { useState } from "react";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import FormSetPassword from "../../../components/form/forSetPassword";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../../../components/ui/input-otp";
import { useToast } from "../../../components/ui/use-toast";
import {
  forgotPassVerify_Admin,
  forgotPassword_Admin,
} from "../../../services/adminService/auth";
import {
  UserLoginPhoneResponse,
  UserLoginResponse,
  forgotPassVerify,
  forgotPassword,
} from "../../../services/affiliate";
import { ERoleAccount } from "../../../types/enum";

interface ILoginPhoneNumber {
  onBack?: () => void;
  role?: ERoleAccount;
}
const schemaPhoneNumber = z.object({
  phone: z
    .string()
    .min(3, {
      message: "Vui lòng nhập số điện thoại",
    })
    .max(12)
    .regex(new RegExp(/0{1}\d{9}$/), "Vui lòng nhập số điện thoại hợp lệ"),
});

const schemaOtp = z.object({
  otp: z.string().min(6, {
    message: "Vui lòng nhập mã otp",
  }),
});
// const schemaPassword = z.object({
//   password: z.string().min(3).max(12),
// });

interface FormPhoneFieldProps {
  name: FieldPath<z.infer<typeof schemaPhoneNumber>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof schemaPhoneNumber>, any>;
  icon: any;
}

// interface FormOTPFieldProps {
//   name: FieldPath<z.infer<typeof schemaOtp>>;
//   label: string;
//   placeholder: string;
//   description?: string;
//   inputType?: string;
//   formControl: Control<z.infer<typeof schemaOtp>, any>;
//   icon: any;
// }

// interface FormPasswordFieldProps {
//   name: FieldPath<z.infer<typeof schemaPassword>>;
//   label: string;
//   placeholder: string;
//   description?: string;
//   inputType?: string;
//   formControl: Control<z.infer<typeof schemaPassword>, any>;
//   icon: any;
// }

enum statusForm {
  FormPhone = "FormPhone",
  FormOtp = "FormOtp",
  FormPassword = "FormPassword",
}
const ForgetPassword = (props: ILoginPhoneNumber) => {
  const {
    onBack = () => {
      return;
    },
    role = ERoleAccount.User,
  } = props;
  const { toast } = useToast();

  const [isFormOtp, setFormOtp] = useState<{
    isOpen: statusForm;
    code: string;
  }>({
    isOpen: statusForm.FormPhone,
    code: "",
  });
  const { mutate } = useMutation<
    UserLoginPhoneResponse,
    HTTPError,
    { phone: string },
    unknown
  >({
    mutationFn:
      role === ERoleAccount.Admin ? forgotPassword_Admin : forgotPassword,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
      });
    },
    onSuccess(data, variables, context) {
      setFormOtp({ isOpen: statusForm.FormOtp, code: variables.phone });
    },
  });

  const [, setSearchParams] = useSearchParams();

  const { mutate: mutateOtp } = useMutation<
    UserLoginResponse,
    HTTPError,
    { otp: string; phone: string },
    unknown
  >({
    mutationFn:
      role === ERoleAccount.Admin ? forgotPassVerify_Admin : forgotPassVerify,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xác thực thất bại",
      });
    },
    onSuccess(data) {
      setSearchParams((sp) => {
        sp.set("verifyToken", data.data.token);
        return sp;
      });
      toast({
        variant: "success",
        title: "Xác thực thành công",
      });
      setFormOtp({ isOpen: statusForm.FormPassword, code: "" });
    },
  });

  const form = useForm<z.infer<typeof schemaPhoneNumber>>({
    resolver: zodResolver(schemaPhoneNumber),
    defaultValues: {
      phone: "",
    },
  });

  const formOTP = useForm<z.infer<typeof schemaOtp>>({
    resolver: zodResolver(schemaOtp),
    defaultValues: {
      otp: "",
    },
  });

  const onSubmitNumberPhone = (values: z.infer<typeof schemaPhoneNumber>) => {
    mutate(values);
  };
  const onSubmitOtp = (values: z.infer<typeof schemaOtp>) => {
    const payload = {
      otp: values.otp,
      phone: isFormOtp.code,
    };
    mutateOtp(payload);
  };

  return (
    <div className="mt-10">
      {isFormOtp.isOpen === statusForm.FormOtp ? (
        <Form {...formOTP}>
          <form
            onSubmit={formOTP.handleSubmit(onSubmitOtp)}
            className="w-full space-y-6"
          >
            <FormField
              control={formOTP.control}
              name="otp"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nhập otp</FormLabel>
                  <FormControl>
                    <InputOTP
                      containerClassName="justify-between"
                      maxLength={6}
                      {...field}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormDescription>Vui lòng nhập mã otp</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="text-right">
              <Button className="w-[100px]" type="submit">
                Gửi
              </Button>
            </div>
          </form>
        </Form>
      ) : isFormOtp.isOpen === statusForm.FormPassword ? (
        <FormSetPassword role={role} />
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitNumberPhone)}
            className="space-y-4"
          >
            <SignupFormField
              name="phone"
              label="Số điện thoại"
              placeholder="Vui lòng nhập sđt"
              description=""
              formControl={form.control}
              icon={Phone}
              inputType="string"
            />

            <div className="text-end">
              <Button
                className="w-full rounded-[8px] py-1 text-base font-medium"
                variant="defaultCustom"
                type="submit"
              >
                Tiếp tục
              </Button>
            </div>
          </form>
        </Form>
      )}

      <div
        onClick={onBack}
        className="mt-5 flex w-fit cursor-pointer items-center justify-start gap-1 text-sm text-foreground hover:underline"
      >
        <ArrowLeft className="size-5" />
        <span className="font-medium">Quay lại</span>
      </div>
    </div>
  );
};

const SignupFormField: React.FC<FormPhoneFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <div className="py-1"></div>
          {/* {description && <FormDescription>{description}</FormDescription>} */}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
// const FormFieldOTP: React.FC<FormOTPFieldProps> = ({
//   name,
//   label,
//   placeholder,
//   description,
//   inputType,
//   formControl,
//   icon,
// }) => {
//   return (
//     <FormField
//       control={formControl}
//       name={name}
//       render={({ field }) => (
//         <FormItem>
//           <FormLabel className="text-sm font-semibold">{label}</FormLabel>
//           <FormControl>
//             <Input
//               placeholder={placeholder}
//               type={inputType || "text"}
//               className="font-medium"
//               Icon={icon}
//               {...field}
//             />
//           </FormControl>
//           <div className="py-1"></div>
//           {/* {description && <FormDescription>{description}</FormDescription>} */}
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   );
// };
// const FormFieldPassword: React.FC<FormPasswordFieldProps> = ({
//   name,
//   label,
//   placeholder,
//   description,
//   inputType,
//   formControl,
//   icon,
// }) => {
//   return (
//     <FormField
//       control={formControl}
//       name={name}
//       render={({ field }) => (
//         <FormItem>
//           <FormLabel className="text-sm font-semibold">{label}</FormLabel>
//           <FormControl>
//             <Input
//               placeholder={placeholder}
//               type={inputType || "text"}
//               className="font-medium"
//               Icon={icon}
//               {...field}
//             />
//           </FormControl>
//           <div className="py-1"></div>
//           {/* {description && <FormDescription>{description}</FormDescription>} */}
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   );
// };

export default ForgetPassword;
