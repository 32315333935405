"use client";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { FormatMoneyToFixed } from "../../../../helpers/formatNumber";
import { getTransactionsRevenue_Admin } from "../../../../services/adminTransactions";

export function Overview() {
  const [isYearCommission, setYearCommission] = useState("2024");
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      year: isYearCommission,
    }),
  );
  const year = parseInt(searchParams.get("year") || "2024");
  const { data: result } = useQuery({
    queryKey: ["revenue", year],
    queryFn: () =>
      getTransactionsRevenue_Admin({
        year: year,
      }),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const chartConfig = {
    totalRevenue: {
      label: "Doanh thu",
      color: "hsl(var(--chart-1))",
    },
    totalCommission: {
      label: "Hoa hồng",
      color: "hsl(var(--chart-2))",
    },
  } satisfies ChartConfig;

  return (
    <>
      <CardHeader className="flex flex-row items-start justify-between">
        <CardTitle>Doanh thu</CardTitle>
        <Select
          value={isYearCommission}
          onValueChange={(value) => {
            setYearCommission(value);
            setSearchParams((params) => {
              params.set("year", value);
              return params;
            });
          }}
        >
          <SelectTrigger className="w-[120px]">
            <SelectValue placeholder="Chọn năm" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Năm</SelectLabel>
              <SelectItem value="2024">2024</SelectItem>
              <SelectItem value="2023">2023</SelectItem>
              <SelectItem value="2022">2022</SelectItem>
              <SelectItem value="2021">2021</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent>
        <ChartContainer className="w-full" config={chartConfig}>
          <BarChart accessibilityLayer data={result.data}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => `T${value}`}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${FormatMoneyToFixed(value || 0)}`}
              hide
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <ChartLegend content={<ChartLegendContent />} />
            <Bar
              dataKey="totalCommission"
              fill="var(--color-totalCommission)"
              radius={4}
              name="Hoa hồng"
            />
            <Bar
              dataKey="totalRevenue"
              name="Doanh thu"
              fill="var(--color-totalRevenue)"
              radius={4}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </>
  );
}
