import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CategoryInput from "../../components/CategoryInput";
import ExpertByCategoryInput from "../../components/ExpertByCategoryInput";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { useToast } from "../../components/ui/use-toast";
import {
  IReqReferencePopulars,
  createReferencePopulars_Admin,
} from "../../services/adminService/referencePopulars.services";
import { EKeyQueryApi } from "../../types/enum";

interface IFormCreatTop {
  onClose?: () => void;
}

const schemaReference = z.object({
  reference: z.string(),
});
export default function FormCreateTopExpert(props: IFormCreatTop) {
  const {
    onClose = () => {
      return;
    },
  } = props;
  const { toast } = useToast();
  const [categorySlug, setCategorySlug] = useState<string>();
  const queryClient = useQueryClient();
  const formSchema = schemaReference;
  const { mutate } = useMutation<
    any,
    HTTPError,
    IReqReferencePopulars,
    unknown
  >({
    mutationFn: createReferencePopulars_Admin,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Thêm chuyên gia thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyPopular] });
      toast({
        variant: "success",
        title: "Thêm chuyên gia thành công",
        duration: 2000,
      });
      onClose();
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reference: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormItem>
          <FormLabel>Danh mục</FormLabel>
          <CategoryInput
            onChange={(cate) => {
              setCategorySlug(cate?.vi.slug);
            }}
          />
          <FormMessage />
        </FormItem>
        <FormField
          control={form.control}
          name="reference"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Chuyên gia</FormLabel>
              <ExpertByCategoryInput
                category={categorySlug}
                value={field.value}
                onChange={(expert) => {
                  field.onChange(expert?._id);
                }}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="text-end">
          <Button
            className="w-fit rounded-[8px] py-4 text-sm font-medium"
            variant="defaultCustom"
            type="submit"
          >
            Thêm chuyên gia
          </Button>
        </div>
      </form>
    </Form>
  );
}
