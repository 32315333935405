import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getFromLocalStorage, saveToLocalStorage } from "./helpers/localstore";
import { getListRole } from "./services/Role.service";
import { getAdminMeInfo } from "./services/administrators";
import { getMeInfo } from "./services/affiliate";
import { getListGroupPermission } from "./services/permissions";
import { useInfoAdminStore } from "./store/admin.store";
import { useBankStore } from "./store/bank.store";
import { usePermissionStore } from "./store/permisstion.store";
import { useRoleStore } from "./store/role.store";
import { useInfoUserStore } from "./store/user.store";
import { EKeyQueryApi, EModeTheme } from "./types/enum";
import { ROLE } from "./constants/roles.constants";

type Theme = "light" | "dark";
type TThemeContext = { theme: Theme; toggleTheme: (item: EModeTheme) => void };

export const ThemeContext = React.createContext<TThemeContext>(
	{} as TThemeContext
);

export const ThemeProvider = ({ children }: any) => {
	const themeHtml: HTMLHtmlElement | null = document.querySelector("html");
	const [theme, setTheme] = useState<EModeTheme>(
		themeHtml?.className || getFromLocalStorage("theme") || EModeTheme.DarkMode
	);
	const toggleTheme = (item: EModeTheme) => {
		setTheme(item);
	};
	const themes = theme;

	useEffect(() => {
		if (themes) {
			if (theme === EModeTheme.DarkMode) {
				saveToLocalStorage("theme", EModeTheme.DarkMode);
				themeHtml?.classList.remove(EModeTheme.LightMode);
				themeHtml?.classList.add(EModeTheme.DarkMode)!;
			} else {
				saveToLocalStorage("theme", EModeTheme.LightMode);
				themeHtml?.classList.remove(EModeTheme.DarkMode);
				themeHtml?.classList.add(EModeTheme.LightMode);
			}
		}
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [theme, themes]);

	const getInfoAdmin = useInfoAdminStore((state) => state.getInfoAdmin);
	const getInfoUser = useInfoUserStore((state) => state.getInfoUser);
	const [cookies] = useCookies(["token"]);
	const token = cookies.token;

	const getInfoByToken = async () => {
		try {
			const result = await getAdminMeInfo();
			let codes = result.data.roles.flatMap((outerGroup) =>
				outerGroup.permissionGroups.flatMap((subGroup: any) =>
					subGroup.permissions
						.map((item: any) => item.code)
						.filter((code: any) => code !== undefined)
				)
			);
			let uniqueNumbers = Array.from(new Set(codes));

			getInfoUser(result?.data);
			getInfoAdmin(result?.data);
			sessionStorage.setItem("role", ROLE.ADMIN);
			sessionStorage.setItem("permission", JSON.stringify(uniqueNumbers));
			return result?.data;
		} catch (error) {
			try {
				const resultUser = await getMeInfo();
				sessionStorage.setItem("role", ROLE.USER);
				getInfoUser(resultUser?.data);
				return resultUser?.data;
			} catch (error) {
				if (
					window.location.pathname !== "/login" &&
					window.location.pathname !== "/admin/login"
				) {
					window.location.href = "/login";
				}
				throw error;
			}
		}
	};
	const getListCardBank = useBankStore((state) => state.getListCardBank);
	useEffect(() => {
		getListCardBank();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (!token) {
			if (
				window.location.pathname !== "/login" &&
				window.location.pathname !== "/admin/login"
			) {
				window.location.href = "/login";
			}
		}
	}, [token]);

	const getRole = sessionStorage.getItem("role");

	// User
	const { isFetching } = useQuery({
		queryKey: [EKeyQueryApi.keyUser, token],
		queryFn: () => getInfoByToken(),
		enabled: !!token,
		retry: false,
	});
	const getListGroupPermissionStore = usePermissionStore(
		(state) => state.getListGroupPermission
	);
	const getListRoleStore = useRoleStore((state) => state.getListRole);

	// Admin
	const getListPermissionAsync = async () => {
		if (!getRole || getRole === ROLE.USER) return "";
		try {
			const result = await getListGroupPermission();
			getListGroupPermissionStore(result?.data || []);
			return result?.data;
		} catch (error) {
			getListGroupPermissionStore([]);
			throw error;
		}
	};
	const getListRoleAsync = async () => {
		if (!getRole || getRole === ROLE.USER) return "";
		try {
			const result = await getListRole();
			getListRoleStore(result?.data || []);
			return result?.data;
		} catch (error) {
			getListRoleStore([]);
			throw error;
		}
	};

	useQuery({
		queryKey: [EKeyQueryApi.keyGroupPermission, token],
		queryFn: () => getListPermissionAsync(),
		enabled: !!token,
		retry: false,
	});

	useQuery({
		queryKey: [EKeyQueryApi.keyRole, token],
		queryFn: () => getListRoleAsync(),
		enabled: !!token,
		retry: false,
	});

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{!isFetching && children}
		</ThemeContext.Provider>
	);
};
