export enum DISCOUNT_TYPE {
	DISCOUNT_BY_PERCENT = 0,
	DISCOUNT_BY_DIRECT = 1,
	DISCOUNT_BY_TIME = 3,
}
export enum TYPE_METHOD_BOOKING {
	call = "Gọi điện",
	meet = "Gặp mặt",
	show = "Đặc biệt",
}
