import { Moon, Sun } from "lucide-react";
import { useContext } from "react";
import { ThemeContext } from "../themeProvider";
import { EModeTheme } from "../types/enum";
import { Button } from "./ui/button";
import { Command, CommandList } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const SwitchMode = () => {
	return <SliderToggle />;
};

const SliderToggle = () => {
	const { theme, toggleTheme } = useContext(ThemeContext);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button size="uniform" variant="outline">
					{theme === EModeTheme.DarkMode ? (
						<Moon className="h-5 w-5" />
					) : (
						<Sun className="h-5 w-5" />
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent align="end" className="w-32 p-1">
				<Command className="">
					<CommandList>
						<Button
							type="button"
							variant="ghost"
							onClick={() => {
								toggleTheme(EModeTheme.DarkMode);
							}}
							className="w-full"
						>
							<Moon className="mr-2 h-4 w-4" />
							<span>Dark</span>
						</Button>
						<Button
							variant="ghost"
							type="button"
							className="w-full"
							onClick={() => toggleTheme(EModeTheme.LightMode)}
						>
							<Sun className="mr-2 h-4 w-4" />
							<span>Light</span>
						</Button>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default SwitchMode;
