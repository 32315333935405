"use client";
import { useInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef } from "react";
import CardTransactionRecent from "../../../../components/SkeletonContainer/CardTransactionRecent";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../components/ui/avatar";
import { FormatMoneyToFixed } from "../../../../helpers/formatNumber";
import { getTransactionsPayRecent_Admin } from "../../../../services/adminTransactions";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { CircleChevronUp } from "lucide-react";

const RecentTransaction = () => {
  const MAX_LIMIT = 10;
  const observer = useRef<IntersectionObserver>();
  const { data, error, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery({
      queryKey: ["experts"],
      queryFn: ({ pageParam }) =>
        getTransactionsPayRecent_Admin({
          skip: pageParam,
          limit: MAX_LIMIT,
        }),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.data?.listData?.length
          ? allPages.length * MAX_LIMIT
          : undefined;
      },
    });

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading],
  );

  const todos = useMemo(() => {
    return data?.pages.reduce((acc: any, page) => {
      return [...acc, ...page.data.listData];
    }, []);
  }, [data]);

  if (isLoading) return <CardTransactionRecent />;

  if (error) return <h1>lỗi hiển thị dữ liệu</h1>;

  return (
    <>
      <CardHeader>
        <CardTitle>Giao dịch gần đây</CardTitle>
        <CardDescription>
          Bạn có {data?.pages[0]?.data?.total || 0} giao dịch trong tháng này
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="scrollbar max-h-[300px] w-full">
          <div className="flex w-full flex-col items-center justify-between gap-5">
            {todos?.map((product: any) => (
              <div
                key={product._id}
                className="flex w-full items-center"
                ref={lastElementRef}
              >
                <Avatar className="h-9 w-9">
                  <AvatarImage
                    src={product.affiliate?.avatar?.location}
                    alt="Avatar"
                  />
                  <AvatarFallback>OM</AvatarFallback>
                </Avatar>
                <div className="ml-4 space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {product.affiliate?.fullname}
                  </p>
                  <p className="text-xs font-medium text-muted-foreground">
                    ID giao dịch: {product?.orderID}
                  </p>
                </div>

                <div className="ml-auto flex gap-2">
                  <div className="flex flex-col items-end">
                    <p className="text-xs font-medium text-muted-foreground">
                      {dayjs(product.createdAt).format("DD/MM/YYYY")}
                    </p>
                    <p className="text-sm font-medium">
                      {FormatMoneyToFixed(product?.amount || 0)} vnđ
                    </p>
                  </div>
                  <CircleChevronUp className="size-4 font-semibold text-green-500" />
                </div>
              </div>
            ))}
            {isFetching && <CardTransactionRecent quantity={1} />}
          </div>
        </div>
      </CardContent>
    </>
  );
};

export default RecentTransaction;
