import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";

interface IPModalSheetContainer {
  children: React.ReactElement;
  isVisible: boolean;
  closeModal: (value: boolean) => void;
  titleModal?: string;
  className?: string;
}

const SheetContainer = (props: IPModalSheetContainer) => {
  const {
    children,
    isVisible,
    closeModal,
    titleModal = "",
    className = "w-full",
  } = props;
  return (
    <Sheet open={isVisible} onOpenChange={closeModal}>
      <SheetContent className={className}>
        <SheetHeader>
          <SheetTitle>{titleModal}</SheetTitle>
        </SheetHeader>
        {children}
      </SheetContent>
    </Sheet>
  );
};

export default SheetContainer;
