import "cropperjs/dist/cropper.css";
import React, { useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { compressedImage, isFileImage } from "../../helpers/image";
import { uploadImage } from "../../services/administrators";
import { editprofileUser } from "../../services/affiliate";
import { IRequestBodyEditUser } from "../../types/user";
import ModalHandleContainer from "../modalHandle/ModalContainer";
import { Button } from "../ui/button";
import { ModalDrawerBody, ModalDrawerFooter } from "../ui/modal-drawer";
import { toast } from "../ui/use-toast";
import { EKeyQueryApi } from "../../types/enum";

interface Props {
	children: any;
}

const CroppedModal = (props: Props) => {
	const { children } = props;
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const cropperRef = useRef<ReactCropperElement>(null);
	const queryClient = useQueryClient();
	const [imgSrc, setImgSrc] = useState<string>();
	const { mutate, isPending } = useMutation<
		any,
		HTTPError,
		IRequestBodyEditUser,
		unknown
	>({
		mutationFn: editprofileUser,
		async onError(error, variables, context) {
			const res: { message: string; systemCode: string } =
				await error.response.json();
			toast({
				variant: "destructive",
				title: res.message,
				duration: 2000,
			});
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyUser] });
			toast({
				variant: "success",
				title: "Cập nhập thành công",
				duration: 2000,
			});
			setOpen(false);
		},
	});
	const handleChangeAvatar: React.ChangeEventHandler<HTMLInputElement> = async (
		e
	) => {
		if (!e.target.files) {
			return;
		}
		if (isFileImage(e.target.files[0])) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				setImgSrc(reader.result?.toString());
				setOpen(true);
			});
			reader.readAsDataURL(e.target.files[0]);
		} else {
			toast({
				variant: "destructive",
				title: "Vui lòng chọn file đúng định dạng là hình ảnh",
				duration: 2000,
			});
		}
	};
	const saveImage = async () => {
		if (loading) return;
		const cropper = cropperRef.current?.cropper;
		const options = {
			maxSizeMB: 1, // Set the maximum file size in MB
			maxWidthOrHeight: 500, // Set the maximum width or height
		};
		cropper?.getCroppedCanvas().toBlob((blob) => {
			if (blob) {
				setLoading(true);
				const file = new File([blob], "name.jpeg", { type: "image/png" });
				compressedImage(file, options).then(async (image: any) => {
					const formData = new FormData();
					formData.append("files", image);
					const rs = await uploadImage(formData);
					mutate({ avatar: rs.data.files[0] });
				});
			}
		});
	};

	return (
		<>
			<label htmlFor="avatar">{children}</label>
			<input
				type="file"
				id="avatar"
				name="avatar"
				accept="image/*"
				hidden
				onChange={handleChangeAvatar}
				onClick={(e) => {
					e.currentTarget.value = "";
				}}
			/>
			<ModalHandleContainer
				isVisible={open}
				closeModal={setOpen}
				titleModal="Thay đổi hình ảnh"
				dismissible={false}
			>
				<ModalDrawerBody>
					<Cropper
						src={imgSrc}
						style={{
							height: 300,
							width: "100%",
							overflow: "hidden",
						}}
						initialAspectRatio={1}
						aspectRatio={1}
						background={true}
						responsive={true}
						minCropBoxHeight={100}
						minCropBoxWidth={100}
						ref={cropperRef}
						className="w-full h-full mb-5"
					/>
				</ModalDrawerBody>
				<ModalDrawerFooter>
					<div className="w-full flex gap-4 justify-end">
						<Button
							className="w-[100px]"
							onClick={() => setOpen(false)}
							disabled={isPending}
							variant="destructive"
						>
							Hủy
						</Button>
						<Button
							className="w-[100px]"
							onClick={saveImage}
							disabled={isPending}
						>
							Lưu
						</Button>
					</div>
				</ModalDrawerFooter>
			</ModalHandleContainer>
		</>
	);
};

export default CroppedModal;
