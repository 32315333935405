// import React from "react";
// import FormLogin from "../../components/form/formLogin";

// const LoginEmailPassword = () => {
// 	return (
// 		<div>
// 			<FormLogin />
// 		</div>
// 	);
// };

// export default LoginEmailPassword;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { KeyRound, User } from "lucide-react";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Button } from "../../../components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { useToast } from "../../../components/ui/use-toast";
import { UserLoginResponse, loginUser } from "../../../services/affiliate";

const formSchema = z.object({
	username: z
		.string({ required_error: "Vui lòng nhập tên đăng nhập" })
		.min(3, "Tên đăng nhập tối thiểu 3 ký tự")
		.max(50),
	password: z
		.string({ required_error: "Vui lòng nhập mật khẩu" })
		.min(5, "Vui lòng nhập mật khẩu"),
});

const LoginEmailPassword = () => {
	const { toast } = useToast();
	const [isForgetPassword] = useState<boolean>(false);
	const navigate = useNavigate();
	const { mutate } = useMutation<
		UserLoginResponse,
		HTTPError,
		{ username: string; password: string },
		unknown
	>({
		mutationFn: loginUser,
		async onError(error, variables, context) {
			toast({
				variant: "destructive",
				title: "Đăng nhập thất bại",
				duration: 2000,
			});
		},
		onSuccess(data, variables, context) {
			const cookie = new Cookies();
			cookie.set("token", data.data.token);
			cookie.set("permission", JSON.stringify(data.data.permissions));
			cookie.set("id", data.data._id);
			toast({
				variant: "success",
				title: "Đăng nhập thành công",
				duration: 2000,
			});
			navigate("/");
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			username: "",
			password: "",
		},
	});

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		mutate(values);
	};

	return (
		<>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
					<SignupFormField
						name="username"
						label="Tên tài khoản"
						placeholder="Vui lòng nhập tên"
						description="At least 3 characters."
						formControl={form.control}
						icon={User}
						focus
					/>
					<SignupFormField
						name="password"
						label="Mật khẩu"
						placeholder="Vui lòng nhập password"
						description="At least 8 characters."
						inputType="password"
						formControl={form.control}
						icon={KeyRound}
					/>

					<div className="text-end">
						<Button
							className="w-full py-6 rounded-[10px] text-base font-semibold"
							variant="defaultCustom"
							type="submit"
						>
							Đăng nhập
						</Button>
					</div>
				</form>
			</Form>

			{isForgetPassword && ""}
		</>
	);
};

interface SignupFormFieldProps {
	name: FieldPath<z.infer<typeof formSchema>>;
	label: string;
	placeholder: string;
	description?: string;
	inputType?: string;
	formControl: Control<z.infer<typeof formSchema>, any>;
	icon: any;
	focus?: boolean;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
	name,
	label,
	placeholder,
	description,
	inputType,
	formControl,
	icon,
	focus = false,
}) => {
	return (
		<FormField
			control={formControl}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel className="text-sm font-semibold text-foreground">
						{label}
					</FormLabel>
					<FormControl>
						<Input
							placeholder={placeholder}
							type={inputType || "text"}
							className="font-medium text-foreground"
							Icon={icon}
							autoFocus={focus}
							{...field}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default LoginEmailPassword;
