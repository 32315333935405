import ky from "ky";
import apiAdmin from "../apis/admin.api";
import { RouteApi } from "../apis/routeApi";
import { IListUser, IRequestAdmin } from "../types/admin";
import { IResponse } from "../types/api";
import { IUser } from "../types/user";
import { UserLoginResponse } from "./affiliate";

export type AdminLoginResponse = IResponse<{
  token: string;
  permissions: any[];
  _id: string;
}>;
const url = RouteApi.administrators;

export const loginAdmin = async (data: {
  username: string;
  password: string;
}) => {
  const res = await apiAdmin
    .post(`${url}/login`, { json: data })
    .json<UserLoginResponse>();
  return res;
};

export type AdminOtp = IResponse<{
  token: string;
  permissions: any[];
  _id: string;
}>;

export const verifyOTP = async (data: { otp: string; accountId: string }) => {
  const res = await apiAdmin
    .post(`${url}/verify-otp`, {
      searchParams: data,
    })
    .json<AdminOtp>();
  return res;
};

export const getAdminInfoById = (id: string) => {
  return apiAdmin.get(`${url}/${id}`).json();
};

export const loginAdminVerifyPhone = async (data: {
  otp: string;
  accountId: string;
}) => {
  const res = await apiAdmin
    .get(`${url}/verify-otp`, { searchParams: data })
    .json<UserLoginResponse>();
  return res;
};

// Tạo tài khoản user

export const uploadImage = (payload: any) => {
  try {
    return ky
      .post(`${process.env.REACT_APP_API_UPLOAD}`, {
        body: payload,
      })
      .json<any>();
  } catch (error: any) {
    return error.message.json();
  }
};

export const getAdminMeInfo = () => {
  return apiAdmin.get(url + "/me").json<IResponse<IUser>>();
};

export const getListUserAdmin = () => {
  return apiAdmin.get(url).json<IResponse<Array<IListUser>>>();
};

export const createAdmin = (payload: Omit<IRequestAdmin, "roles">) => {
  return apiAdmin
    .post(url + "/create", { json: payload })
    .json<IResponse<any>>();
};

export const deleteAdmin = async (idRole: string) => {
  const res = await apiAdmin.delete(url + "/delete/" + idRole).json<any>();
  return res;
};
export const updateAdmin = async ({ idRole, ...payload }: any) => {
  const res = await apiAdmin
    .put(url + "/update/" + idRole, { json: payload })
    .json<any>();
  return res;
};
