import { create } from "zustand";
import { IResponseRole } from "../types/admin";
interface IRoleStore {
	listRole: Array<IResponseRole>;
	getListRole: (group: Array<IResponseRole>) => void;
}
export const useRoleStore = create<IRoleStore>((set, get) => ({
	listRole: [],
	getListRole: (group: Array<IResponseRole>) => {
		const amountState = get().listRole;
		set({ ...amountState, listRole: group });
	},
}));
