import { Search } from "lucide-react";
import { PopoverAccount } from "./popover/PopoverAccount";
import SwitchMode from "./switchMode";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

const Navbar = () => {
  return (
    <div className="top static left-[300px] right-0 top-0 z-50 flex items-center justify-between gap-5 bg-card px-5 py-3 xl:fixed xl:h-16">
      <div className="flex w-full items-center justify-between gap-10">
        <p className="font-semibold uppercase xl:hidden">Askany affiliate</p>
        <div className="hidden w-[400px] items-center justify-start space-x-2 xl:flex">
          <Input
            className="flex-grow"
            type="search"
            placeholder="search..."
            Icon={Search}
          />
          <Button type="submit">Tìm kiếm</Button>
        </div>
        <div className="flex items-center gap-5">
          <SwitchMode />
          <PopoverAccount />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
