import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface ITooltipContainer extends React.PropsWithChildren {
  content?: string;
}

const TooltipContainer = (props: ITooltipContainer) => {
  const { content = "", children } = props;
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        {!!content && (
          <TooltipContent>
            <p>{content}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipContainer;
