export const saveToLocalStorage = (key: string, value: any) => {
	if (typeof window !== 'undefined') {
		window.localStorage.setItem(key, JSON.stringify(value));
	}
};

export const getFromLocalStorage = (key: string): any => {
	try {
		if (typeof window === 'undefined') return null;
		const value = window.localStorage.getItem(key);
		if (!value) return null;
		const parsedValue = JSON.parse(value);
		return parsedValue;
	} catch (e) {
		return null;
	}
};

export const removeFromLocalStorage = (key: string) => {
	window.localStorage.removeItem(key);
};
