import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IListResponse, IResponse } from "../types/api";

const urlTransactions = RouteApi.affiliateTransactions;

export const getTransactions = (
  payload: Partial<{
    skip?: number;
    limit?: number;
    type?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
  }> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(urlTransactions, { searchParams: { ...fields } })
    .json<IListResponse<any>>();
};

export const getIncomeTransactions = () => {
  return apiClient
    .get(urlTransactions + "/statement/overview")
    .json<IResponse<any>>();
};
