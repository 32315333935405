import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IResponse } from "../types/api";
import { IRequestBodyEditUser, IUser } from "../types/user";

const url = RouteApi.affiliates;

export type UserLoginResponse = IResponse<{
  token: string;
  permissions: any[];
  _id: string;
}>;

export type UserLoginPhoneResponse = IResponse<{
  _id: string;
}>;

export const loginUser = async (data: {
  username: string;
  password: string;
}) => {
  const res = await apiClient
    .post(url + "/login", { json: data })
    .json<UserLoginResponse>();
  return res;
};

export type UserOtp = IResponse<{
  token: string;
  permissions: any[];
  _id: string;
}>;

export const verifyOTP = (data: { otp: string; accountId: string }) => {
  return apiClient
    .post(url + "/verify-otp", {
      searchParams: data,
    })
    .json<UserOtp>();
};

export const getUserInfoById = (id: string) => {
  return apiClient.get(`${url}/${id}`).json();
};

export const loginUserByPhone = async (data: { phone: string }) => {
  const res = await apiClient
    .post(url + "/login-phone", { json: data })
    .json<UserLoginPhoneResponse>();
  return res;
};

export const loginUserVerifyPhone = async (data: {
  otp: string;
  affiliateId: string;
}) => {
  const res = await apiClient
    .get(url + "/login-phone-verification", { searchParams: data })
    .json<UserLoginResponse>();
  return res;
};

export const getMeInfo = () => {
  return apiClient.get(url + "/me").json<IResponse<IUser>>();
};

export const editprofileUser = async (data: IRequestBodyEditUser) => {
  const res = await apiClient.put(url, { json: data }).json<any>();
  return res;
};

// forgot password
export const forgotPassword = async (data: { phone: string }) => {
  const res = await apiClient
    .post(url + "/forgot-pass", { json: data })
    .json<any>();
  return res;
};

export const forgotPassVerify = async (data: {
  otp: string;
  phone: string;
}) => {
  const res = await apiClient
    .post(url + "/forgot-pass-verification", { json: data })
    .json<UserLoginResponse>();
  return res;
};

export const setPassword = async (data: {
  password: string;
  token: string;
}) => {
  const res = await apiClient
    .post(url + "/set-password", {
      json: {
        password: data.password,
      },
      headers: {
        Authorization: ` Bearer ${data.token}`,
      },
    })
    .json<any>();
  return res;
};

export const getChartClickTracking = (
  payload: Partial<{
    year?: number;
  }>,
) => {
  return apiClient
    .get(url + "/affiliate-trackings/clicks/statistics", {
      searchParams: { ...payload },
    })
    .json<IResponse<any>>();
};
