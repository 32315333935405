import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Loader2, Trash } from "lucide-react";
import { useState } from "react";
import TooltipContainer from "../../../components/TooltipContainer";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useToast } from "../../../components/ui/use-toast";
import { deleteLink } from "../../../services/link";
import { EKeyQueryApi } from "../../../types/enum";

const DeleteLinkDialog = ({ link }: any) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteLink,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xóa link thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyLink] });
      toast({
        variant: "success",
        title: "Xóa link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <TooltipContainer content="Xóa">
        <DialogTrigger asChild>
          <Button size="icon" variant="destructive">
            <Trash className="size-4" />
          </Button>
        </DialogTrigger>
      </TooltipContainer>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader className="pb-3">
          <DialogTitle>Xóa link</DialogTitle>
        </DialogHeader>
        <div>
          <p>Bạn có muốn xóa link này không?</p>
          <p className="mt-3 rounded-md border-yellow-500 bg-yellow-500/10 px-3 py-2 text-sm text-yellow-500 backdrop-opacity-10">
            Lưu ý: Khi xóa link bạn sẽ không được nhận hoa hồng offer hiện tại
            và sau này
          </p>

          <div className="mt-5 flex items-center justify-end gap-3">
            <Button
              onClick={() => setOpen(false)}
              className="px-10"
              variant="ghostCustom"
            >
              Hủy
            </Button>
            <Button
              disabled={isPending}
              onClick={() => mutate(link._id)}
              className="px-5"
              variant={"destructive"}
            >
              {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Tiếp tục
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteLinkDialog;
