import React from "react";
import {
	ModalDrawer,
	ModalDrawerContent,
	ModalDrawerHeader,
	ModalDrawerTitle,
} from "../ui/modal-drawer";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "../../lib/utils";
interface IPModalContainer extends React.PropsWithChildren {
	isVisible: boolean;
	closeModal: (value: boolean) => void;
	titleModal?: string;
	className?: string;
	dismissible?: boolean;
}

const ModalHandleContainer = (props: IPModalContainer) => {
	const {
		children,
		isVisible,
		closeModal,
		titleModal = "",
		className = "w-full",
		dismissible,
	} = props;
	return (
		<ModalDrawer
			open={isVisible}
			onOpenChange={closeModal}
			dismissible={dismissible}
		>
			<ModalDrawerContent className={cn("max-h-[96%]", className)}>
				<ModalDrawerHeader className="pb-3">
					<ModalDrawerTitle>{titleModal}</ModalDrawerTitle>
				</ModalDrawerHeader>
				<ScrollArea className="overflow-auto">
					<div className="px-5 py-3 sm:px-1">{children}</div>
				</ScrollArea>
			</ModalDrawerContent>
		</ModalDrawer>
	);
};

export default ModalHandleContainer;
