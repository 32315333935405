import { Badge } from "../components/ui/badge";
import { ELinkStatus } from "../types/enum";

export const regexPhoneNumber = new RegExp(/0{1}\d{9}$/);
export const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    "-7": {
      variant: "reject",
      text: "Đã hủy",
    },
    "-6": {
      variant: "pending",
      text: "Chờ duyệt",
    },
    "-3": {
      variant: "reject",
      text: "Khiếu nại",
    },
    "-2": {
      variant: "reject",
      text: "Đã hủy",
    },
    "-1": {
      variant: "reject",
      text: "Đã bị xóa",
    },
    "1": {
      variant: "active",
      text: "Đang diễn ra",
    },
    "2": {
      variant: "active",
      text: "Đã chọn offer",
    },
    "3": {
      variant: "pending",
      text: "Chờ xác nhận",
    },
    "4": {
      variant: "active",
      text: "Đã xác nhận",
    },
    "5": {
      variant: "active",
      text: "Hoàn thành/chưa thanh toán",
    },
    "6": {
      variant: "active",
      text: "Hoàn thành, đã thanh toán",
    },
    "7": {
      variant: "active",
      text: "Đã tư vấn",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};

export const StatusBadgeLink = ({ status }: { status: any }) => {
  const badge: any = {
    [ELinkStatus.DELETE]: {
      variant: "reject",
      text: "Đã xóa",
    },
    [ELinkStatus.ACTIVE]: {
      variant: "active",
      text: "Hoạt động",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};
