import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, toDate } from "date-fns";
import { vi } from "date-fns/locale";
import { HTTPError } from "ky";
import {
  CalendarIcon,
  Check,
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  CircleUserRound,
  Filter,
  FilterX,
  ListFilter,
  LoaderCircleIcon,
  MoreHorizontal,
  PlusCircle,
  RefreshCcw,
} from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";
import CategoryInput from "../../../components/CategoryInput";
import ExpertByCategoryInput from "../../../components/ExpertByCategoryInput";
import Paginate from "../../../components/Paginate";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Card } from "../../../components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  ModalDrawer,
  ModalDrawerBody,
  ModalDrawerContent,
  ModalDrawerFooter,
  ModalDrawerHeader,
  ModalDrawerTitle,
  ModalDrawerTrigger,
} from "../../../components/ui/modal-drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Switch } from "../../../components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { useToast } from "../../../components/ui/use-toast";
import { ROLE } from "../../../constants/roles.constants";
import useUserData from "../../../hooks/useUserData";
import { cn } from "../../../lib/utils";
import {
  createLinkAdmin,
  getAffiliateCreateLinks,
  getListUser,
} from "../../../services/adminAffiliates.service";
import { CreateLinkRequest } from "../../../services/link";
import { EKeyQueryApi, ERoleAccount, EStatusUser } from "../../../types/enum";

export default function ManagerLink() {
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const search = searchParams.get("search") || "";
  const sort = searchParams.get("sort") || "";

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyAffiliateCreateLink, limit, skip, search, sort],
    queryFn: () =>
      getAffiliateCreateLinks({
        skip: skip,
        limit: limit,
        startTime,
        endTime,
        search,
        sort,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const linkList = result?.data;

  return (
    <>
      <div className="flex items-center">
        <h2 className="text-2xl font-bold tracking-tight">Danh sách link</h2>
      </div>
      <div className="my-5 flex items-center gap-2">
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
        <AddLinkForm />
      </div>
      <div>
        <LinkFilter open={showFilter} />
      </div>
      <div className="mt-4"></div>
      <div className="mt-3">
        <Tabs defaultValue={ERoleAccount.User}>
          <div className="flex flex-wrap items-center gap-4">
            <div>
              <TabsList>
                <TabsTrigger value="user">
                  <CircleUserRound className="mr-2 size-5" />
                  Người dùng
                </TabsTrigger>
              </TabsList>
            </div>
          </div>
          <TabsContent value={ERoleAccount.User}>
            <Card>
              <Table className="min-w-[1400px] overflow-x-auto">
                <TableHeader>
                  <TableRow>
                    <TableHead className="text-center">Họ tên</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Số điện thoại</TableHead>
                    <TableHead className="text-center">Click Desktop</TableHead>
                    <TableHead className="text-center">Click Mobile</TableHead>
                    <TableHead className="text-center">Lượt booking</TableHead>
                    {/* <TableHead className="text-center">
                      Số lượng offer hoàn thành
                    </TableHead> */}
                    <TableHead className="text-center">Trạng thái</TableHead>
                    <TableHead>
                      <span className="sr-only">Thao tác</span>
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isFetching &&
                    (linkList?.listData?.length || 0) > 0 &&
                    linkList?.listData
                      .filter((item) => !!item?.affiliate)
                      .map((affiliateLink, index) => (
                        <TableRow key={index}>
                          <TableCell className="font-medium">
                            <div className="flex items-center justify-start gap-5">
                              <img
                                src={
                                  affiliateLink.affiliate?.avatar?.location ||
                                  "https://github.com/shadcn.png"
                                }
                                alt="avt"
                                className="size-16 rounded-md object-cover"
                              />
                              <span>{affiliateLink.affiliate?.fullname}</span>
                            </div>
                          </TableCell>
                          <TableCell>
                            {affiliateLink.affiliate?.email}
                          </TableCell>
                          <TableCell>
                            {affiliateLink.affiliate?.phone}
                          </TableCell>
                          <TableCell className="text-center">
                            {affiliateLink?.totalDesktopClick || 0}
                          </TableCell>
                          <TableCell className="text-center">
                            {affiliateLink?.totalMobileClick || 0}
                          </TableCell>
                          <TableCell className="text-center">
                            {affiliateLink?.totalBook || "N/A"}
                          </TableCell>
                          {/* <TableCell className="text-center">
                            {affiliateLink?.totalBookSuccess || "N/A"}
                          </TableCell> */}
                          <TableCell className="text-center">
                            <StatusBadge
                              status={affiliateLink.affiliate?.status}
                            />
                          </TableCell>
                          <TableCell>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  aria-haspopup="true"
                                  size="icon"
                                  variant="outlineCustom"
                                >
                                  <MoreHorizontal className="h-4 w-4" />
                                  <span className="sr-only">Toggle menu</span>
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuItem>
                                  <Link
                                    to={`${affiliateLink.affiliate._id}?percent=${affiliateLink.affiliate.commissionPercent}`}
                                  >
                                    Xem chi tiết
                                  </Link>
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              {!isFetching && linkList?.listData.length <= 0 && (
                <div className="my-10 w-full text-center">
                  Không tìm thấy kết quả
                </div>
              )}
              {isFetching && (
                <div className="grid h-20 w-full place-content-center">
                  <LoaderCircleIcon className="animate-spin" />
                </div>
              )}
            </Card>
            {!isFetching && (
              <div className="mt-2">
                <Paginate
                  isOver={linkList.isOver}
                  total={linkList.total}
                  limit={limit}
                  skip={skip}
                />
              </div>
            )}
          </TabsContent>
          {/* <TabsContent value={ERoleAccount.Admin}>
						<TableManagerLinkAdmin />
					</TabsContent> */}
        </Tabs>
      </div>
    </>
  );
}

const schemaLink = z.object({
  affiliate: z.string().min(1, "Vui lòng nhập affiliate"),
  code: z.string().min(1, "Vui lòng nhập mã code"),
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string().optional(),
});

const schemaLinkReference = z.object({
  affiliate: z.string().min(1, "Vui lòng nhập affiliate"),
  code: z.string().min(1, "Vui lòng nhập code"),
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string(),
});

const AddLinkForm = () => {
  const [userData] = useUserData();
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [categorySlug, setCategorySlug] = useState<string>();
  const [haveReference, setHaveReference] = useState(false);
  const queryClient = useQueryClient();
  const formSchema = haveReference ? schemaLinkReference : schemaLink;
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    CreateLinkRequest,
    unknown
  >({
    mutationFn: createLinkAdmin,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Tạo link thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyLinkAdmin] });
      toast({
        variant: "success",
        title: "Tạo link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });
  const [q, setQ] = useState("");

  const {
    data: {
      data: { listData: listUser },
    },
  } = useQuery({
    queryKey: [EKeyQueryApi.keyListUser, q],
    queryFn: () =>
      getListUser({
        skip: 0,
        limit: 10,
        q: q,
      }),

    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
    enabled: open,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      name: "",
      affiliate: userData?.role === ROLE.USER ? userData?._id : "",
      code: "",
    },
  });

  const HandleRandomCode = () => {
    const uniqueId = Math.random().toString(36).substring(2, 14).toUpperCase();
    form.setValue("code", uniqueId);
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (haveReference) {
      mutate(values);
    } else {
      const { reference, ...payload } = values;
      mutate(payload);
    }
  };

  return (
    <ModalDrawer open={open} onOpenChange={setOpen}>
      <ModalDrawerTrigger asChild>
        <Button size="sm" className="h-8 gap-1">
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Tạo link
          </span>
        </Button>
      </ModalDrawerTrigger>
      <ModalDrawerContent className="sm:max-w-[425px]">
        <ModalDrawerHeader>
          <ModalDrawerTitle>Tạo link</ModalDrawerTitle>
        </ModalDrawerHeader>
        <ModalDrawerBody>
          <Form {...form}>
            <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="affiliate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Affiliate</FormLabel>
                    <Popover modal>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "w-full justify-between",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value
                              ? listUser?.find(
                                  (user) => user._id === field.value,
                                )?.fullname
                              : "Select affiliate"}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="PopoverContent p-0">
                        <Command>
                          <CommandInput
                            placeholder="Tìm kiếm..."
                            value={q}
                            onValueChange={setQ}
                          />
                          <CommandEmpty>Không tìm thấy người dùng</CommandEmpty>
                          <CommandList>
                            {listUser?.map((user: any) => (
                              <CommandItem
                                value={user.fullname}
                                key={user._id}
                                onSelect={() => {
                                  form.setValue("affiliate", user._id);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    user._id === field.value
                                      ? "opacity-100"
                                      : "opacity-0",
                                  )}
                                />
                                {user.fullname}
                              </CommandItem>
                            ))}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center space-x-2">
                <Switch
                  id="have-ref"
                  checked={haveReference}
                  onCheckedChange={setHaveReference}
                />
                <Label htmlFor="have-ref">Đính kèm chuyên gia</Label>
              </div>
              {haveReference && (
                <>
                  <FormItem>
                    <FormLabel>Danh mục</FormLabel>
                    <CategoryInput
                      onChange={(cate) => {
                        setCategorySlug(cate?.vi.slug);
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                  <FormField
                    control={form.control}
                    name="reference"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Chuyên gia</FormLabel>
                        <ExpertByCategoryInput
                          category={categorySlug}
                          value={field.value}
                          onChange={(expert) => {
                            field.onChange(expert?._id);
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nhập mã link</FormLabel>
                    <div className="flex items-center justify-between gap-3">
                      <FormControl className="flex-grow">
                        <Input placeholder="Vui lòng nhập mã " {...field} />
                      </FormControl>
                      <Button
                        onClick={() => HandleRandomCode()}
                        type="button"
                        variant="defaultCustom"
                      >
                        <RefreshCcw className="mr-2 h-4 w-4" /> tự động
                      </Button>
                    </div>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tên link</FormLabel>
                    <FormControl>
                      <Input placeholder="Vui lòng nhập tên link" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mô tả</FormLabel>
                    <FormControl>
                      <Input placeholder="Nhập mô tả " {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ModalDrawerBody>
        <ModalDrawerFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit)}
          >
            Thêm mới
          </Button>
        </ModalDrawerFooter>
      </ModalDrawerContent>
    </ModalDrawer>
  );
};

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    [EStatusUser.Active]: {
      variant: "active",
      text: "Hoạt động",
    },
    [EStatusUser.Block]: {
      variant: "reject",
      text: "Đã xóa",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};

const filterLinkSchema = z.object({
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  search: z.string().optional(),
  sort: z.enum(["-1", "1"]).optional(),
});

const LinkFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const search = searchParams.get("search") || undefined;
  const sort = (searchParams.get("sort") as "-1" | "1") || undefined;
  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      startTime: startTime ? toDate(startTime) : undefined,
      endTime: endTime ? toDate(endTime) : undefined,
      search: search,
      sort: sort,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    setSearchParams((sp) => {
      Object.keys(values).forEach((key) => {
        sp.set(key, values[key as keyof typeof values]?.toString() || "");
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={"overflow-hidden duration-200"}
        style={{
          maxHeight: open ? ref.current?.clientHeight : 0,
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 sm:grid-cols-3 md:grid-cols-5"
            ref={ref}
          >
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Tìm kiếm</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian bắt đầu</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        // selected={;}
                        onSelect={field.onChange}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian kết thúc</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        // selected={;}
                        onSelect={field.onChange}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="sort"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Sắp xếp</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue defaultValue={field.value} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Mới nhất</SelectItem>
                      <SelectItem value="-1">Cũ nhất</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("search");
                    sp.delete("sort");
                    sp.delete("endTime");
                    sp.delete("startTime");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
