import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { KeyRound, User } from "lucide-react";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ERROR_AFF } from "../../apis/systemCode";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../../components/ui/input-otp";
import { useToast } from "../../components/ui/use-toast";
import {
  loginAdmin,
  loginAdminVerifyPhone,
} from "../../services/administrators";
import {
  UserLoginPhoneResponse,
  UserLoginResponse,
} from "../../services/affiliate";
import { ERoleAccount, ESelectForm } from "../../types/enum";
import ForgetPassword from "../affiliate/login/forgetPassword";

const formSchema = z.object({
  username: z.string().min(3).max(50),
  password: z.string().min(5),
});

const formOtp = z.object({
  otp: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

const LoginEmailPasswordAdmin = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isFormOtp, setFormOtp] = useState<{ isOpen: boolean; code: string }>({
    isOpen: false,
    code: "",
  });
  const [formLogin, setFormLogin] = useState<ESelectForm>(
    ESelectForm.EmailPassword,
  );

  const { mutate } = useMutation<
    UserLoginPhoneResponse,
    HTTPError,
    { username: string; password: string },
    unknown
  >({
    mutationFn: loginAdmin,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: ERROR_AFF[res.systemCode].message,
      });
    },
    onSuccess(data) {
      setFormOtp({ isOpen: true, code: data.data._id });
    },
  });

  const { mutate: mutateOtp } = useMutation<
    UserLoginResponse,
    HTTPError,
    { otp: string; accountId: string },
    unknown
  >({
    mutationFn: loginAdminVerifyPhone,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Đăng nhập thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      const cookie = new Cookies();
      cookie.set("token", data.data.token, { path: "/" });
      cookie.set("permission", JSON.stringify(data.data.permissions), {
        path: "/",
      });
      cookie.set("id", data.data._id, { path: "/" });
      cookie.set("role", "admin", { path: "/" });
      toast({
        variant: "success",
        title: "Đăng nhập thành công",
        duration: 2000,
      });
      setFormOtp({ isOpen: false, code: "" });
      navigate("/admin");
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const formOTP = useForm<z.infer<typeof formOtp>>({
    resolver: zodResolver(formOtp),
    defaultValues: {
      otp: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };
  const onSubmitOtp = (values: z.infer<typeof formOtp>) => {
    const payload = {
      otp: values.otp,
      accountId: isFormOtp.code,
    };
    mutateOtp(payload);
  };

  return (
    <>
      <h1 className="mb-3 text-5xl font-semibold text-foreground">
        {formLogin === ESelectForm.ForgetPassword
          ? "Quên mật khẩu"
          : "Đăng nhập"}
      </h1>
      {formLogin === ESelectForm.ForgetPassword ? (
        <ForgetPassword
          onBack={() => setFormLogin(ESelectForm.EmailPassword)}
          role={ERoleAccount.Admin}
        />
      ) : (
        <>
          {isFormOtp.isOpen ? (
            <Form {...formOTP}>
              <form
                onSubmit={formOTP.handleSubmit(onSubmitOtp)}
                className="w-full space-y-6"
              >
                <FormField
                  control={formOTP.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nhập otp</FormLabel>
                      <FormControl>
                        <InputOTP
                          containerClassName="justify-between"
                          maxLength={6}
                          {...field}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormDescription>Vui lòng nhập mã otp</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="text-right">
                  <Button className="w-[100px]" type="submit">
                    Gửi
                  </Button>
                </div>
              </form>
            </Form>
          ) : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <SignupFormField
                  name="username"
                  label="Tên tài khoản"
                  placeholder="Vui lòng nhập tên"
                  description="At least 3 characters."
                  formControl={form.control}
                  icon={User}
                  focus
                />
                <SignupFormField
                  name="password"
                  label="Mật khẩu"
                  placeholder="Vui lòng nhập password"
                  description="At least 8 characters."
                  inputType="password"
                  formControl={form.control}
                  icon={KeyRound}
                />

                <div className="text-end">
                  <Button
                    className="w-full rounded-[10px] py-6 text-base font-semibold"
                    variant="defaultCustom"
                    type="submit"
                  >
                    Đăng nhập
                  </Button>
                </div>

                <p
                  onClick={() => setFormLogin(ESelectForm.ForgetPassword)}
                  className="cursor-pointer text-right text-sm font-semibold text-red-600"
                >
                  Quên mật khẩu ?
                </p>
              </form>
            </Form>
          )}
        </>
      )}
    </>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof formSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof formSchema>, any>;
  icon: any;
  focus?: boolean;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
  focus = false,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold text-foreground">
            {label}
          </FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium text-foreground"
              Icon={icon}
              autoFocus={focus}
              {...field}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default LoginEmailPasswordAdmin;
