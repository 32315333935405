import {
  BadgePlus,
  Loader2,
  LoaderCircleIcon,
  Pencil,
  Trash2,
} from "lucide-react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { HTTPError } from "ky";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import TooltipContainer from "../../../components/TooltipContainer";
import FormCreateRole from "../../../components/form/formCreateRole";
import ModalContainer from "../../../components/modal/ModalContainer";
import SheetContainer from "../../../components/modalSheet/SheetContainer";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { toast } from "../../../components/ui/use-toast";
import { deleteRole, getListRole } from "../../../services/Role.service";
import { usePermissionStore } from "../../../store/permisstion.store";
import { IPermissionGroup, IResponseRole } from "../../../types/admin";
import { EActionForm, EKeyQueryApi } from "../../../types/enum";

const Roles = () => {
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "2",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyRole],
    queryFn: () => getListRole(),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const listRole = result?.data;

  return (
    <>
      <div className="mt-3">
        <h1 className="mb-8 text-3xl font-extrabold">Vai trò</h1>

        <ManagerRoleTable
          isFetching={isFetching}
          listData={listRole}
          page={skip}
          limit={limit}
        />
      </div>
    </>
  );
};

const ManagerRoleTable = ({
  isFetching,
  listData,
  page,
  limit,
}: {
  isFetching: boolean;
  listData: Array<IResponseRole>;
  page: number;
  limit: number;
}) => {
  // const [isInfoBank, setInfoBank] = useState<{
  //   openInfo: boolean;
  //   idBank: Partial<TDetailBank>;
  // }>({
  //   openInfo: false,
  //   idBank: {},
  // });
  const [isDeleteGroup, setDeleteGroup] = useState<{
    openDelete: boolean;
    idGroup: string;
  }>({
    openDelete: false,
    idGroup: "",
  });
  const [isSheetPermission, setSheetPermission] = useState<{
    open: boolean;
    action: EActionForm;
    dataForm: Partial<IPermissionGroup>;
  }>({
    open: false,
    action: EActionForm.ADD,
    dataForm: {},
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteRole,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xóa vai trò thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyRole],
      });
      toast({
        variant: "success",
        title: "Xóa vai trò thành công",
        duration: 2000,
      });
      setDeleteGroup({ idGroup: "", openDelete: false });
    },
  });

  const listGroupPermission = usePermissionStore(
    (state) => state.listGroupPermission,
  );

  const getPermission = (listPermission: Array<string>) => {
    let listPermissionChild: Array<IPermissionGroup> = [];
    if ((listPermission?.length || 0) <= 0) return [];
    if ((listGroupPermission?.length || 0) <= 0) return [];
    listGroupPermission.forEach((element) => {
      if (listPermission.includes(element._id)) {
        listPermissionChild.push(element);
      }
    });
    return listPermissionChild;
  };

  return (
    <>
      <div className="flex items-center justify-start gap-5 pb-5">
        <Button
          type="button"
          onClick={() =>
            setSheetPermission({
              action: EActionForm.ADD,
              dataForm: {},
              open: true,
            })
          }
        >
          <BadgePlus className="mr-2 size-5" />
          Tạo vai trò
        </Button>
      </div>
      <Card>
        <Table className="min-w-[1400px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Tên vai trò</TableHead>
              <TableHead className="w-[900px]">Nhóm quyền</TableHead>
              <TableHead>Ngày tạo</TableHead>
              <TableHead>
                <span className="sr-only">Thao tác</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              listData?.map((role) => (
                <TableRow key={role._id} className="font-medium">
                  <TableCell className="align-top font-medium">
                    {role.roleName}
                  </TableCell>
                  <TableCell className="flex max-w-[900px] flex-wrap items-start justify-start gap-3 align-top font-medium">
                    {getPermission(role.permissionGroups).map((item, index) => {
                      return (
                        <Badge
                          className="rounded-md"
                          key={item?._id}
                          variant="active"
                        >
                          {item.groupName}
                        </Badge>
                      );
                    })}
                  </TableCell>
                  <TableCell className="align-top">
                    {dayjs(role.createdAt).format("DD/MM/YYYY - HH:mm ")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <TooltipContainer content="Chỉnh sửa">
                      <Button
                        variant="outlineCustom"
                        onClick={() =>
                          setSheetPermission({
                            action: EActionForm.EDIT,
                            dataForm: role,
                            open: true,
                          })
                        }
                      >
                        <Pencil className="size-4" />
                      </Button>
                    </TooltipContainer>
                    <TooltipContainer content="Xóa">
                      <Button
                        onClick={() =>
                          setDeleteGroup({
                            openDelete: true,
                            idGroup: role._id,
                          })
                        }
                        variant="outlineCustom"
                      >
                        <Trash2 className="size-4" />
                      </Button>
                    </TooltipContainer>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </Card>

      {isSheetPermission.open && (
        <SheetContainer
          isVisible={isSheetPermission.open}
          closeModal={() =>
            setSheetPermission({
              action: EActionForm.ADD,
              dataForm: {},
              open: false,
            })
          }
          titleModal={
            isSheetPermission.action === EActionForm.ADD
              ? "Tạo vai trò"
              : "Chỉnh sửa vai trò"
          }
        >
          <FormCreateRole
            action={isSheetPermission.action}
            onHandle={() =>
              setSheetPermission({
                action: EActionForm.ADD,
                dataForm: {},
                open: false,
              })
            }
            dataEditGroup={isSheetPermission.dataForm}
          />
        </SheetContainer>
      )}
      {isDeleteGroup.openDelete && (
        <ModalContainer
          isVisible={isDeleteGroup.openDelete}
          closeModal={() =>
            setDeleteGroup({ ...isDeleteGroup, openDelete: false })
          }
          titleModal="Xóa nhóm quyền"
        >
          <div>
            <p>Bạn có muốn xóa nhóm quyền này không?</p>
            <div className="mt-10 flex items-center justify-end gap-5">
              <Button
                onClick={() =>
                  setDeleteGroup({ openDelete: false, idGroup: "" })
                }
                className="px-10"
                variant="ghost"
              >
                Hủy
              </Button>
              <Button
                disabled={isPending}
                onClick={() => mutate(isDeleteGroup?.idGroup || "")}
                className="px-5"
                variant={"destructive"}
              >
                {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Tiếp tục
              </Button>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};

export default Roles;
