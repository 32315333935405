import {
  ChevronDown,
  ChevronUp,
  CircleUserRound,
  Filter,
  FilterX,
  ListFilter,
  Loader2,
  LoaderCircleIcon,
  Pen,
  Pencil,
  PlusCircle,
  Shield,
  Trash2,
  Wallet,
} from "lucide-react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { HTTPError } from "ky";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import TooltipContainer from "../../../components/TooltipContainer";
import FormSetRoleAdmin from "../../../components/form/formSetRoleAdmin";
import FormUpdateUser from "../../../components/form/formUpdateUser";
import ModalContainer from "../../../components/modal/ModalContainer";
import ModalHandleContainer from "../../../components/modalHandle/ModalContainer";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { toast } from "../../../components/ui/use-toast";
import { FormatPercentNumber } from "../../../helpers/formatNumber";
import { getListUser } from "../../../services/adminAffiliates.service";
import {
  deleteAdmin,
  getListUserAdmin,
} from "../../../services/administrators";
import { IDetailAdmin } from "../../../types/admin";
import {
  EActionForm,
  EKeyQueryApi,
  ERoleAccount,
  EStatusUser,
} from "../../../types/enum";
import CreateAccount from "./CreateAccount";
import CreateTransaction from "./CreateTransaction";
import { cn } from "../../../lib/utils";

const ManagerUser = () => {
  const [isOpenAddAccount, setCreateAddAcount] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  const [isSheetPermission, setSheetPermission] = useState<{
    open: boolean;
    dataForm: Partial<IDetailAdmin>;
    action: EActionForm;
  }>({
    open: false,
    dataForm: {},
    action: EActionForm.ADD,
  });

  return (
    <>
      <div className="mt-3">
        <Tabs defaultValue={ERoleAccount.User}>
          <div className="flex flex-wrap items-center gap-4">
            <div>
              <TabsList>
                <TabsTrigger value="user">
                  <CircleUserRound className="mr-2 size-5" />
                  Người dùng
                </TabsTrigger>
                <TabsTrigger value="admin">
                  <Shield className="mr-2 size-5" />
                  Admin
                </TabsTrigger>
              </TabsList>
            </div>
            <div className="ml-auto">
              <TabsContent
                className="mt-0 flex items-center gap-2"
                value={ERoleAccount.User}
              >
                <Button
                  size="sm"
                  variant={showFilter ? "default" : "outline"}
                  className="h-8 gap-1"
                  onClick={() => setShowFilter((s) => !s)}
                >
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                  {showFilter ? (
                    <ChevronDown className="h-3.5 w-3.5" />
                  ) : (
                    <ChevronUp className="h-3.5 w-3.5" />
                  )}
                </Button>
                <CreateTransaction />

                <Button size="sm" className="h-8 gap-1">
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span
                    onClick={() => setCreateAddAcount(true)}
                    className="sr-only sm:not-sr-only sm:whitespace-nowrap"
                  >
                    Tạo tài khoản người dùng
                  </span>
                </Button>
              </TabsContent>
              <TabsContent className="mt-0" value={ERoleAccount.Admin}>
                <Button size="sm" className="h-8 gap-1">
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span
                    onClick={() =>
                      setSheetPermission({
                        open: true,
                        dataForm: {},
                        action: EActionForm.ADD,
                      })
                    }
                    className="sr-only sm:not-sr-only sm:whitespace-nowrap"
                  >
                    Tạo tài khoản admin
                  </span>
                </Button>
              </TabsContent>
            </div>
          </div>
          <TabsContent value={ERoleAccount.User}>
            <UserFilter open={showFilter} />
            <ManagerUserTable />
          </TabsContent>
          <TabsContent value={ERoleAccount.Admin}>
            <UserFilter open={showFilter} />
            <ManagerUserAdminTable
              isSheetPermission={isSheetPermission}
              setSheetPermission={setSheetPermission}
            />
          </TabsContent>
        </Tabs>
      </div>
      {isOpenAddAccount && (
        <ModalHandleContainer
          isVisible={isOpenAddAccount}
          closeModal={() => setCreateAddAcount(false)}
          titleModal="Thêm tài khoản"
        >
          <CreateAccount onClose={() => setCreateAddAcount(false)} />
        </ModalHandleContainer>
      )}
    </>
  );
};

const ManagerUserTable = () => {
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const email = searchParams.get("email") || "";
  const phone = searchParams.get("phone") || "";
  const q = searchParams.get("q") || "";
  const status = searchParams.get("status") || "";

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyListUser, limit, skip, email, phone, q, status],

    queryFn: () =>
      getListUser({
        skip: skip,
        limit: limit,
        q: q,
        status: status,
        email: email,
        phone: phone,
      }),

    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });
  const {
    data: { isOver, listData, total },
  } = result;

  return (
    <>
      <Card>
        <Table className="min-w-[1400px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Họ tên</TableHead>
              <TableHead>Email</TableHead>
              <TableHead className="whitespace-nowrap">Tên đăng nhập</TableHead>
              <TableHead className="hidden text-center md:table-cell">
                Số điện thoại
              </TableHead>
              <TableHead className="hidden text-center md:table-cell">
                Trạng thái
              </TableHead>
              <TableHead className="hidden whitespace-nowrap text-center md:table-cell">
                Hoa hồng (%)
              </TableHead>
              <TableHead className="hidden md:table-cell">Ngày tạo</TableHead>
              <TableHead>
                <span className="sr-only">Thao tác</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              listData?.map((user: any) => (
                <TableRow key={user._id} className="font-medium">
                  <TableCell className="font-medium">
                    <div className="flex items-center justify-start gap-5">
                      <Avatar className="size-16 rounded-md">
                        <AvatarImage
                          src={
                            user?.avatar?.location ||
                            "https://github.com/shadcn.png"
                          }
                          alt="avt"
                        />
                        <AvatarFallback>CN</AvatarFallback>
                      </Avatar>
                      <span>{user.fullname}</span>
                    </div>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.username}</TableCell>

                  <TableCell className="hidden text-center md:table-cell">
                    {user.phone}
                  </TableCell>
                  <TableCell className="hidden text-center md:table-cell">
                    <StatusBadge status={user.status} />
                  </TableCell>
                  <TableCell className="hidden text-center md:table-cell">
                    {FormatPercentNumber(user.commissionPercent)}
                  </TableCell>
                  <TableCell className="hidden md:table-cell">
                    {dayjs(user.createdAt).format("DD/MM/YYYY - HH:mm ")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    {/* <TooltipContainer content="Xem chi tiết">
											<Link to={`${user._id}/services`}>
												<Button variant="outlineCustom">
													<Eye className="size-4" />
												</Button>
											</Link>
										</TooltipContainer> */}
                    <FormUpdateUser data={user}>
                      <TooltipContainer content="Chỉnh sửa">
                        <Button variant="outlineCustom">
                          <Pen className="size-4" />
                        </Button>
                      </TooltipContainer>
                    </FormUpdateUser>

                    <TooltipContainer content="Thu nhập">
                      <Link to={`/admin/transactions/${user?._id}`}>
                        <Button variant="outlineCustom">
                          <Wallet className="size-4" />
                        </Button>
                      </Link>
                    </TooltipContainer>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isFetching && listData.length <= 0 && <NoData />}
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </Card>
      {!isFetching && (
        <div className="mt-2">
          <Paginate isOver={isOver} total={total} limit={limit} skip={skip} />
        </div>
      )}
    </>
  );
};
interface IPManagerAdmin {
  isSheetPermission: {
    open: boolean;
    dataForm: Partial<IDetailAdmin>;
    action: EActionForm;
  };
  setSheetPermission: (data: {
    open: boolean;
    dataForm: Partial<IDetailAdmin>;
    action: EActionForm;
  }) => void;
}
const ManagerUserAdminTable = (props: IPManagerAdmin) => {
  const {
    isSheetPermission,
    setSheetPermission = () => {
      return;
    },
  } = props;
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const phone = searchParams.get("phone") || "";
  const q = searchParams.get("q") || "";
  const status = searchParams.get("status") || "";

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyAdmin, email, phone, q, status],

    queryFn: () => getListUserAdmin(),

    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });
  const { data: listData } = result;
  const [isDeleteGroup, setDeleteGroup] = useState<{
    openDelete: boolean;
    idGroup: string;
  }>({
    openDelete: false,
    idGroup: "",
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteAdmin,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xóa Admin thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyAdmin],
      });
      toast({
        variant: "success",
        title: "Xóa Admin thành công",
        duration: 2000,
      });
      setDeleteGroup({ idGroup: "", openDelete: false });
    },
  });
  return (
    <>
      <Card>
        <Table className="min-w-[1400px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Họ tên</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Số điện thoại</TableHead>
              <TableHead>Vai trò</TableHead>
              <TableHead>Trạng thái</TableHead>
              <TableHead>Ngày tạo</TableHead>
              <TableHead>
                <span className="sr-only">Thao tác</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              listData?.map((link: any) => (
                <TableRow key={link._id} className="font-medium">
                  <TableCell className="font-medium">{link.username}</TableCell>
                  <TableCell>{link.email}</TableCell>
                  <TableCell>{link.phone}</TableCell>
                  <TableCell>
                    {link?.roles.map((item: any, index: number) => {
                      return (
                        <Badge
                          className="rounded-md"
                          key={index}
                          variant="outline"
                        >
                          {item?.roleName}
                        </Badge>
                      );
                    })}
                  </TableCell>
                  <TableCell>
                    <StatusBadge status={link.status} />
                  </TableCell>
                  <TableCell>
                    {dayjs(link.createdAt).format("DD/MM/YYYY - HH:mm ")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <TooltipContainer content="Chỉnh sửa">
                      <Button
                        type="button"
                        variant="outlineCustom"
                        onClick={() => {
                          setSheetPermission({
                            dataForm: link,
                            open: true,
                            action: EActionForm.EDIT,
                          });
                        }}
                      >
                        <Pencil className="size-4" />
                      </Button>
                    </TooltipContainer>
                    <TooltipContainer content="Xóa">
                      <Button
                        onClick={() =>
                          setDeleteGroup({
                            openDelete: true,
                            idGroup: link?._id,
                          })
                        }
                        variant="outlineCustom"
                      >
                        <Trash2 className="size-4" />
                      </Button>
                    </TooltipContainer>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isFetching && listData.length <= 0 && (
          <div className="my-10 w-full text-center">Không tìm thấy kết quả</div>
        )}
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </Card>
      {isSheetPermission.open && (
        <ModalHandleContainer
          isVisible={isSheetPermission.open}
          closeModal={() =>
            setSheetPermission({
              dataForm: {},
              open: false,
              action: EActionForm.ADD,
            })
          }
          titleModal={
            isSheetPermission.action === EActionForm.ADD
              ? "Thêm admin"
              : "Chỉnh sửa admin"
          }
        >
          <FormSetRoleAdmin
            action={isSheetPermission.action}
            onHandle={() =>
              setSheetPermission({
                action: EActionForm.ADD,
                dataForm: {},
                open: false,
              })
            }
            dataEdit={isSheetPermission.dataForm}
          />
        </ModalHandleContainer>
      )}
      {isDeleteGroup.openDelete && (
        <ModalContainer
          isVisible={isDeleteGroup.openDelete}
          closeModal={() =>
            setDeleteGroup({ ...isDeleteGroup, openDelete: false })
          }
          titleModal="Xóa nhóm quyền"
        >
          <div>
            <p>Bạn có muốn xóa nhóm quyền này không?</p>
            <div className="mt-10 flex items-center justify-end gap-5">
              <Button
                onClick={() =>
                  setDeleteGroup({ openDelete: false, idGroup: "" })
                }
                className="px-10"
                variant="ghost"
              >
                Hủy
              </Button>
              <Button
                disabled={isPending}
                onClick={() => mutate(isDeleteGroup?.idGroup || "")}
                className="px-5"
                variant={"destructive"}
              >
                {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Tiếp tục
              </Button>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    [EStatusUser.Active]: {
      variant: "active",
      text: "Hoạt động",
    },
    [EStatusUser.Block]: {
      variant: "reject",
      text: "Đã xóa",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};
export default ManagerUser;

const filterUserSchema = z.object({
  q: z.string().optional(),
  status: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
});

const UserFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email") || undefined;
  const phone = searchParams.get("phone") || undefined;
  const q = searchParams.get("q") || undefined;
  const status = searchParams.get("status") || "";
  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterUserSchema>>({
    resolver: zodResolver(filterUserSchema),
    defaultValues: {
      email: email,
      phone: phone,
      q: q,
      status: status,
    },
  });
  const onSubmit = (values: z.infer<typeof filterUserSchema>) => {
    setSearchParams((sp) => {
      Object.keys(values).forEach((key) => {
        sp.set(
          key,
          values[key as keyof typeof values]?.toString().trim() || "",
        );
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={cn("duration-200", !open ? "overflow-hidden" : "my-5")}
        style={{
          maxHeight: open ? ref.current?.clientHeight : 0,
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 pb-2 sm:grid-cols-3 md:grid-cols-5"
            ref={ref}
          >
            <FormField
              control={form.control}
              name="q"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Tìm kiếm</FormLabel>
                  <FormControl>
                    <Input placeholder="Nhập id người dùng" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Nhập email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Số điện thoại</FormLabel>
                  <FormControl>
                    <Input placeholder="Nhập số điện thoại" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Trạng thái</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder="Chọn trạng thái"
                          defaultValue={field.value}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={`${EStatusUser.Active}`}>
                        Hoạt động
                      </SelectItem>
                      <SelectItem value={`${EStatusUser.Block}`}>
                        Không hoạt động
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("q");
                    sp.delete("email");
                    sp.delete("phone");
                    sp.delete("status");
                    return sp;
                  });
                  form.reset({
                    phone: "",
                    email: "",
                    status: undefined,
                    q: "",
                  });
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
