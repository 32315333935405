import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, toDate } from "date-fns";
import { vi } from "date-fns/locale";
import dayjs from "dayjs";
import { HTTPError } from "ky";
import {
  CalendarIcon,
  ChevronDown,
  ChevronUp,
  ClipboardIcon,
  Eye,
  Filter,
  FilterX,
  ListFilter,
  LoaderCircleIcon,
  PlusCircle,
  RefreshCcw,
} from "lucide-react";
import { useRef, useState } from "react";
import { FieldNamesMarkedBoolean, FieldValues, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";
import CategoryInput from "../../../components/CategoryInput";
import ExpertByCategoryInput from "../../../components/ExpertByCategoryInput";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import TooltipContainer from "../../../components/TooltipContainer";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Card } from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import {
  ModalDrawer,
  ModalDrawerBody,
  ModalDrawerContent,
  ModalDrawerFooter,
  ModalDrawerHeader,
  ModalDrawerTitle,
  ModalDrawerTrigger,
} from "../../../components/ui/modal-drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Switch } from "../../../components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { toast, useToast } from "../../../components/ui/use-toast";
import { ROLE } from "../../../constants/roles.constants";
import { useCopyToClipboard } from "../../../hooks/useCopy";
import useUserData from "../../../hooks/useUserData";
import { cn } from "../../../lib/utils";
import {
  CreateLinkRequest,
  createLink,
  getLinks,
} from "../../../services/link";
import { EKeyQueryApi, ELinkStatus } from "../../../types/enum";
import DeleteLinkDialog from "./DeleteLinkDialog";
import EditLinkForm from "./EditLinkForm";

export default function ManagerLinkUser() {
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const status = searchParams.get("status") || "1";

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyLink, limit, skip, startTime, endTime, status],
    queryFn: () =>
      getLinks({
        skip: skip,
        limit: limit,
        startTime: startTime,
        endTime: endTime,
        status: status,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const linkList = result?.data;

  const [, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        toast({
          title: "Copy thành công",
          description: text,
        });
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };

  return (
    <>
      <div className="flex items-center">
        <h2 className="text-2xl font-bold tracking-tight">Danh sách link</h2>
      </div>
      <div className="my-5 flex items-center gap-2">
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
        <AddLinkForm />
      </div>
      <div>
        <LinkFilter open={showFilter} />
      </div>
      <div className="mt-4"></div>
      <Card>
        <Table className="min-w-[1000px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Tên link</TableHead>
              <TableHead>Code</TableHead>
              <TableHead className="text-center">Trạng thái</TableHead>
              <TableHead className="text-center">Click Desktop</TableHead>
              <TableHead className="text-center">Click Mobile</TableHead>
              <TableHead className="text-center">Lượt booking</TableHead>
              <TableHead className="text-center">Booking hoàn thành</TableHead>
              <TableHead>Ngày tạo</TableHead>
              <TableHead>Thao tác</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              linkList?.listData.map((link) => (
                <TableRow key={link._id}>
                  <TableCell className="flex items-center justify-start gap-2 font-medium">
                    <span>{link.name}</span>
                    {link.status !== -1 && (
                      <TooltipContainer content="copy link">
                        <Button
                          onClick={() => {
                            if (!!link?.reference) {
                              handleCopy(
                                `${process.env.REACT_APP_LINK_AFFILIATE}/${link._id}?expert=${
                                  link?.reference?.expertname || ""
                                }`,
                              );
                              return;
                            }
                            handleCopy(
                              `${process.env.REACT_APP_LINK_AFFILIATE}/${link._id}`,
                            );
                          }}
                          type="button"
                          variant="ghost"
                        >
                          <ClipboardIcon className="h-4 w-4" />
                        </Button>
                      </TooltipContainer>
                    )}
                  </TableCell>
                  <TableCell>
                    <span className="line-clamp-2 max-w-[400px]">
                      {link.code}
                    </span>
                  </TableCell>
                  <TableCell className="text-center">
                    <StatusBadge status={link.status} />
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalDesktopClick || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalMobileClick || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalBook || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalBookSuccess || 0}
                  </TableCell>
                  <TableCell>
                    {dayjs(link.createdAt).format("HH:mm DD/MM/YYYY")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <Link to={`${link?._id || "/"}`}>
                      <TooltipContainer content="Xem chi tiết">
                        <Button variant="outlineCustom">
                          <Eye className="size-4" />
                        </Button>
                      </TooltipContainer>
                    </Link>
                    {link?.status === ELinkStatus.ACTIVE && (
                      <>
                        <EditLinkForm link={link} />
                        <DeleteLinkDialog link={link} />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isFetching && linkList?.listData.length <= 0 && (
          <NoData content="Danh sách link trống!" />
        )}
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </Card>
      {!isFetching && (
        <div className="mt-2">
          <Paginate
            isOver={linkList.isOver}
            total={linkList.total}
            limit={limit}
            skip={skip}
          />
        </div>
      )}
    </>
  );
}

const schemaLink = z.object({
  code: z.string().min(1, "Vui lòng nhập code"),
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string().optional(),
});

const schemaLinkReference = z.object({
  code: z.string().min(1, "Vui lòng nhập code"),
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string(),
});

const AddLinkForm = () => {
  const [userData] = useUserData();
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const [categorySlug, setCategorySlug] = useState<string>();
  const [haveReference, setHaveReference] = useState(false);
  const formSchema = haveReference ? schemaLinkReference : schemaLink;
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    CreateLinkRequest,
    unknown
  >({
    mutationFn: createLink,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Tạo link thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyLink] });
      toast({
        variant: "success",
        title: "Tạo link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      name: "",
      code: "",
    },
  });

  const HandleRandomCode = () => {
    const uniqueId = Math.random().toString(36).substring(2, 14).toUpperCase();
    form.setValue("code", uniqueId);
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (haveReference) {
      mutate({
        ...values,
        affiliate: userData?.role === ROLE.USER ? userData?._id : "",
      });
    } else {
      const { reference, ...payload } = values;
      mutate({
        ...payload,
        affiliate: userData?.role === ROLE.USER ? userData?._id : "",
      });
    }
  };

  return (
    <ModalDrawer open={open} onOpenChange={setOpen}>
      <ModalDrawerTrigger asChild>
        <Button size="sm" className="h-8 gap-1">
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Tạo link
          </span>
        </Button>
      </ModalDrawerTrigger>
      <ModalDrawerContent className="sm:max-w-[425px]">
        <ModalDrawerHeader>
          <ModalDrawerTitle>Tạo link</ModalDrawerTitle>
        </ModalDrawerHeader>
        <ModalDrawerBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <div className="flex items-center space-x-2 py-2">
                <Switch
                  id="have-ref"
                  checked={haveReference}
                  onCheckedChange={setHaveReference}
                />
                <Label htmlFor="have-ref">Đính kèm chuyên gia</Label>
              </div>
              {haveReference && (
                <>
                  <FormItem>
                    <FormLabel>Danh mục</FormLabel>
                    <CategoryInput
                      onChange={(cate) => {
                        setCategorySlug(cate?.vi.slug);
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                  <FormField
                    control={form.control}
                    name="reference"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Chuyên gia</FormLabel>
                        <ExpertByCategoryInput
                          category={categorySlug}
                          value={field.value}
                          onChange={(expert) => {
                            field.onChange(expert?._id);
                          }}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nhập mã link</FormLabel>
                    <div className="flex items-center justify-between gap-3">
                      <FormControl className="flex-grow">
                        <Input
                          disabled
                          // placeholder="Vui lòng nhập mã "
                          {...field}
                        />
                      </FormControl>
                      <Button
                        onClick={() => HandleRandomCode()}
                        type="button"
                        variant="defaultCustom"
                      >
                        <RefreshCcw className="mr-2 h-4 w-4" /> tạo mã
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tên link</FormLabel>
                    <FormControl>
                      <Input placeholder="Vui lòng nhập tên link" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mô tả</FormLabel>
                    <FormControl>
                      <Input placeholder="Nhập mô tả của bạn" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ModalDrawerBody>
        <ModalDrawerFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit)}
          >
            Thêm mới
          </Button>
        </ModalDrawerFooter>
      </ModalDrawerContent>
    </ModalDrawer>
  );
};

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    [ELinkStatus.DELETE]: {
      variant: "reject",
      text: "Đã xóa",
    },
    [ELinkStatus.ACTIVE]: {
      variant: "active",
      text: "Hoạt động",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};

const filterLinkSchema = z.object({
  affiliateId: z.string().optional(),
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  status: z.string(),
});

const LinkFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const affiliateId = searchParams.get("affiliateId") || "";
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const status = searchParams.get("status") || "";

  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      affiliateId: affiliateId,
      startTime: startTime ? toDate(startTime) : undefined,
      endTime: endTime ? toDate(endTime) : undefined,
      status: status,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    if (!!values?.startTime) {
      const formatStartTime = dayjs(values.startTime).format("YYYY-MM-DD");
      values.startTime = formatStartTime as any;
    }
    if (!!values?.endTime) {
      const formatEndTime = dayjs(values.endTime).format("YYYY-MM-DD");
      values.endTime = formatEndTime as any;
    }
    const {
      formState: { dirtyFields },
    } = form;
    const payload = getDirtyFields(values, dirtyFields);
    setSearchParams((sp) => {
      Object.keys(payload).forEach((key) => {
        sp.set(key, payload[key as keyof typeof payload]?.toString() || "");
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={"overflow-hidden p-1 duration-200"}
        style={{
          maxHeight: open ? ref.current?.clientHeight : 0,
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 sm:grid-cols-3 md:grid-cols-5"
          >
            <FormField
              control={form.control}
              name="affiliateId"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Mã link</FormLabel>
                  <FormControl>
                    <Input placeholder="Nhập mã tìm kiếm" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian bắt đầu</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian kết thúc</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Trạng thái</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder="Sắp xếp theo"
                          defaultValue={status || field.value}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Hoạt động</SelectItem>
                      <SelectItem value="-1">Đã xóa</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("affiliateId");
                    sp.delete("startTime");
                    sp.delete("endTime");
                    sp.delete("status");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}
