import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Percent, User } from "lucide-react";
import React from "react";
import type {
  Control,
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { updateUser } from "../../services/adminAffiliates.service";
import { EKeyQueryApi, EStatusUser } from "../../types/enum";
import { IRequestAdminUpdateUser, IUser } from "../../types/user";
import { toast } from "../ui/use-toast";
import {
  ModalDrawer,
  ModalDrawerBody,
  ModalDrawerContent,
  ModalDrawerFooter,
  ModalDrawerHeader,
  ModalDrawerTitle,
  ModalDrawerTrigger,
} from "../ui/modal-drawer";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const formSchema = z.object({
  fullname: z.string().min(3).max(50),
  commissionPercent: z.coerce.number({ required_error: "Vui lòng nhập" }),
  status: z.coerce.number(),
});

const FormUpdateUser = ({ children, data }: { children: any; data: IUser }) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    { id: string; payload: IRequestAdminUpdateUser },
    unknown
  >({
    mutationFn: updateUser,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyListUser] });
      toast({
        variant: "success",
        title: "Tạo tài khoản thành công",
        duration: 2000,
      });
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullname: data?.fullname,
      commissionPercent:
        data?.commissionPercent <= 1
          ? (data?.commissionPercent || 0) * 100
          : data?.commissionPercent || 0,
      status: data?.status,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const {
      formState: { dirtyFields },
    } = form;
    const payload = getDirtyFields(values, dirtyFields);
    mutate({ payload, id: data._id });
  };

  return (
    <ModalDrawer>
      <ModalDrawerTrigger>{children}</ModalDrawerTrigger>
      <ModalDrawerContent>
        <ModalDrawerHeader>
          <ModalDrawerTitle>Cập nhật thông tin tài khoản</ModalDrawerTitle>
        </ModalDrawerHeader>
        <ModalDrawerBody>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mt-5 gap-10 space-y-5"
            >
              <InputField
                name="fullname"
                label="Họ và tên"
                placeholder="Nguyen van A"
                description="At least 3 characters."
                formControl={form.control}
                icon={User}
              />
              <InputField
                name="commissionPercent"
                label="Phần trăm hoa hồng"
                placeholder="ex: 20%"
                inputType="number"
                formControl={form.control}
                icon={Percent}
              />
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Status</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Chọn ngân hàng" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem
                          className="text-white"
                          value={`${EStatusUser.Active}`}
                        >
                          Hoạt động
                        </SelectItem>
                        <SelectItem
                          className="text-white"
                          value={`${EStatusUser.Block}`}
                        >
                          Đã xóa
                        </SelectItem>
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ModalDrawerBody>
        <ModalDrawerFooter>
          <Button
            className="w-fit rounded-[8px] py-4 text-sm font-medium"
            variant="defaultCustom"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit)}
          >
            Cập nhập tài khoản
          </Button>
        </ModalDrawerFooter>
      </ModalDrawerContent>
    </ModalDrawer>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof formSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof formSchema>>;
  icon: any;
}

const InputField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}
export default FormUpdateUser;
