import { create } from "zustand";
import { IInforDetailAdmin, IResponseListUser } from "../types/admin";

interface IlistUser {
  listDataUser: IResponseListUser;
  getStateListUser: (data: any) => void;
}

interface IInforAdmin {
  inforAdmin: IInforDetailAdmin;
  getInfoAdmin: (data: any) => void;
  clearInfo: () => void;
}

const initListUser: IResponseListUser = {
  isOver: false,
  listData: [],
  total: 0,
};

const defaultAdmin = {
  _id: "",
  email: "",
  password: "",
  phone: "",
  roles: [],
  status: 0,
  username: "",
  fullname: "",
  commissionPercent: 0,
};
export const useInfoAdminStore = create<IInforAdmin>((set, get) => ({
  inforAdmin: defaultAdmin,
  getInfoAdmin: (payload: any) => {
    const amountState = get().inforAdmin;
    set({ ...amountState, inforAdmin: payload });
  },
  clearInfo: () => {
    set({ ...get(), inforAdmin: defaultAdmin });
  },
}));

export const useManagerUserStore = create<IlistUser>((set, get) => ({
  listDataUser: initListUser,
  getStateListUser: (payload: IResponseListUser) => {
    const amountState = get().listDataUser;
    set({ ...amountState, listDataUser: payload });
  },
}));
