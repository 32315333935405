import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { ROLE } from "../constants/roles.constants";
import { getAdminMeInfo } from "../services/administrators";
import { getMeInfo } from "../services/affiliate";
import { EKeyQueryApi } from "../types/enum";
import { IUser } from "../types/user";

const useUserData = (): [({ role: ROLE } & IUser) | undefined, boolean] => {
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cookies, _setCookie, removeCookie] = useCookies([
		"role",
		"token",
		"id",
		"permissions",
	]);
	const {
		data: userData,
		isError,
		isLoading,
	} = useQuery({
		queryKey: [EKeyQueryApi.keyUser, cookies.role, cookies.token],
		queryFn: getMeInfo,
		retry: 0,
		enabled: !!(!cookies.role && cookies.token),
	});

	const {
		data: userAdmin,
		isError: isErrorAdmin,
		isLoading: isLoadingAdmin,
	} = useQuery({
		queryKey: [EKeyQueryApi.keyAdmin],
		queryFn: getAdminMeInfo,
		retry: 0,
		enabled: !!(cookies.role && cookies.token),
	});

	const load = isLoading && isLoadingAdmin;
	const role = userAdmin ? ROLE.ADMIN : ROLE.USER;
	const user = role === ROLE.ADMIN ? userAdmin : userData;

	// const getStateListUser = useInfoAdminStore((state) => state.getInfoAdmin);
	// getStateListUser(user);

	useEffect(() => {
		if (!load)
			if (
				(isError && role === ROLE.USER) ||
				(isErrorAdmin && role === ROLE.ADMIN) ||
				!cookies.token
			) {
				removeCookie("role");
				removeCookie("token");
				removeCookie("id");
				removeCookie("permissions");
			}
	}, [
		cookies.token,
		isError,
		isErrorAdmin,
		navigate,
		removeCookie,
		role,
		load,
	]);

	if (user) {
		return [
			{
				...user?.data,
				role: role,
			},
			load,
		];
	}
	return [undefined, load];
};

export default useUserData;
