import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ArrowUpRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { FormatMoneyToFixed } from "../../../../helpers/formatNumber";
import { getTransactions } from "../../../../services/transactions";
import { EKeyQueryApi, TransactionType } from "../../../../types/enum";

const DashboardHistoryPayment = () => {
  const {
    data: {
      data: { listData },
    },
  } = useQuery({
    queryKey: [EKeyQueryApi.keyTransactions],
    queryFn: () =>
      getTransactions({
        skip: 0,
        limit: 10,
        type: `${TransactionType.PAY}`,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });
  return (
    <Card className="col-span-3" x-chunk="dashboard-01-chunk-4">
      <CardHeader className="flex flex-row items-center">
        <div className="grid gap-2">
          <CardTitle>Giao dịch</CardTitle>
          <CardDescription>Lịch sử giao dịch từ Askany</CardDescription>
        </div>
        <Button asChild size="sm" className="ml-auto gap-1">
          <Link to="/income">
            Xem tất cả
            <ArrowUpRight className="h-4 w-4" />
          </Link>
        </Button>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Người chuyển</TableHead>
              <TableHead className="text-right">Số tiền</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(listData?.length || 0) > 0 &&
              listData.slice(0, 5).map((transaction, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="font-medium">
                        {` Askany chuyển khoản ngân hàng ${dayjs(transaction?.createdAt).format("MM/YYYY")}`}
                      </div>
                      <div className="hidden text-sm text-muted-foreground md:inline">
                        {transaction?.orderID || ""}
                      </div>
                    </TableCell>

                    <TableCell className="text-right">
                      {FormatMoneyToFixed(transaction?.amount || 0)} VNĐ
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default DashboardHistoryPayment;
