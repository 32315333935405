import { Filter, FilterX, Info, LoaderCircleIcon } from "lucide-react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FieldNamesMarkedBoolean, FieldValues, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { Card, CardContent } from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { StatusBadge } from "../../../constants";
import {
  FarsePercentNumber,
  FormatMoneyToFixed,
} from "../../../helpers/formatNumber";
import { getLinkOffersAdmin } from "../../../services/adminAffiliates.service";
import { EAffiliateLinkType, EKeyQueryApi } from "../../../types/enum";
import { TYPE_METHOD_BOOKING } from "../../../types/offer";

export default function LinkOfferAffiliate() {
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const affiliateLink = searchParams.get("affiliateId") || "";
  const percent = searchParams.get("percent") || "0";
  const sort = searchParams.get("sort") || undefined;
  const type = searchParams.get("type") || undefined;
  const {
    data: {
      data: { isOver, offers, total },
    },
    isFetching,
  } = useQuery({
    queryKey: [
      EKeyQueryApi.keyLinkOffersAdmin,
      limit,
      skip,
      affiliateLink,
      sort,
      type,
    ],
    queryFn: () =>
      getLinkOffersAdmin({
        skip: skip,
        limit: limit,
        affiliateLink,
        sort,
        type,
      }),
    initialData: {
      data: {
        offers: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const PriceOfferBook = (data: any) => {
    const dataRequest = data.offeredRequest;
    // const dataOffer = data;
    const dataPayment = data?.price;
    const payment = data?.price;
    const stateOffer = data;
    const endTime = data.endTime;
    const startTime = data.startTime;

    const toMoney = () => {
      let price = 0;
      switch (data?.type) {
        case 2:
          if (data?.contactForm === "meet") {
            price =
              (payment?.cost || stateOffer?.author?.meetPrice?.cost || 0) /
              (stateOffer?.author?.meetPrice?.totalMinutes ||
                payment?.totalMinutes ||
                1);
          } else if (
            data?.contactForm === "call" &&
            stateOffer?.author?.callPrice
          ) {
            price =
              (payment?.cost || stateOffer?.author?.callPrice?.cost || 0) /
              (stateOffer?.author?.callPrice?.totalMinutes ||
                payment?.cost ||
                1);
          } else if (
            data?.contactForm === "show" &&
            stateOffer?.author?.showPrice
          ) {
            price =
              (payment?.cost || stateOffer?.author?.showPrice?.cost || 0) /
              (stateOffer?.author?.showPrice?.totalMinutes ||
                payment?.cost ||
                1);
          }
          break;
        default:
          price = (payment?.cost || 0) / (payment?.totalMinutes || 1);
          break;
      }
      return Math.floor(
        price *
          (dayjs(endTime).diff(dayjs(startTime), "m") ||
            payment?.totalMinutes ||
            60),
      );
    };

    const TimeSelect =
      (endTime && startTime
        ? dayjs(endTime).diff(dayjs(startTime), "m")
        : dataPayment?.totalMinutes) || 0;
    const participants = dataRequest?.participantsCount || 0;
    const priceBook =
      ((dataPayment?.cost || 0) * TimeSelect) /
      (dataPayment?.totalMinutes || 1);
    const serviceFee = participants >= 3 ? priceBook * 1.5 - priceBook : 0;
    // const commissionPercentage =
    //   dataOffer?.commissionPercentage && dataOffer?.commissionPercentage < 1
    //     ? +dataOffer?.commissionPercentage * 100
    //     : dataOffer?.commissionPercentage || 30;
    const beforeFee = toMoney();

    const afterServiceFee = beforeFee + serviceFee;
    const afterTransactionFee = afterServiceFee;

    // const voucherBook = dataOffer?.voucherInstance?.voucher;

    return afterTransactionFee;
  };

  const TitleOfferBook = (data: any) => {
    const dataRequest = data.offeredRequest;
    const stateOffer = data;
    const getTitleRequest = (type: number) => {
      switch (type) {
        case 1:
          return `Tư vấn gói dịch vụ "${
            stateOffer?.offeredRequest?.title || ""
          }"`;
        case 2:
          return `Tư vấn trực tiếp với chuyên gia "${
            data?.author?.fullname || ""
          }"`;
        case 3:
          return `Tư vấn gói kỹ năng "${
            data?.offeredRequest?.title &&
            data?.offeredRequest?.title?.split("'")?.length > 1
              ? data?.offeredRequest?.title?.split("'")?.[1]
              : ""
          }"`;
        case 4:
          return `Đặt show trực tiếp thông qua kỹ năng "${
            data?.offeredRequest?.title &&
            data?.offeredRequest?.title?.split("'")?.length > 1
              ? data?.offeredRequest?.title?.split("'")?.[1]
              : ""
          }"`;

        default:
          return `Tư vấn gói dịch vụ "${data?.offeredRequest?.title || ""}"`;
      }
    };
    return getTitleRequest(dataRequest?.type || 0);
  };

  return (
    <>
      <div className="flex items-center pt-3">
        <h1 className="mb-8 text-3xl font-extrabold">Danh sách offer</h1>
      </div>{" "}
      <div>
        <LinkFilter />
      </div>
      <div className="mt-4"></div>
      <Card>
        <CardContent className="p-3">
          <Table className="min-w-[1400px] overflow-x-auto">
            <TableHeader>
              <TableRow>
                <TableHead>Chuyên gia</TableHead>
                <TableHead>Người dùng</TableHead>
                <TableHead>Tên gói dịch vụ</TableHead>
                <TableHead className="text-nowrap">Hình thức</TableHead>
                <TableHead>Giá tiền</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Ngày đặt gói</TableHead>
                <TableHead>Tiền hoa hồng (Tạm tính)</TableHead>
                <TableHead>
                  <span className="sr-only">Thao tác</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {offers.map((offer) => (
                <TableRow key={offer._id}>
                  <TableCell className="font-medium">
                    <div className="flex items-center justify-start gap-2">
                      <Avatar className="size-6">
                        <AvatarImage
                          src={
                            offer?.author?.avatar?.location ||
                            "https://github.com/shadcn.png"
                          }
                          alt="avt"
                        />
                        <AvatarFallback>CN</AvatarFallback>
                      </Avatar>
                      <span>{offer.author.fullname}</span>
                    </div>
                  </TableCell>
                  <TableCell className="font-medium">
                    <div className="flex items-center justify-start gap-2">
                      <Avatar className="size-6">
                        <AvatarImage
                          src={
                            offer?.offeredUser?.avatar?.location ||
                            "https://github.com/shadcn.png"
                          }
                          alt="avt"
                        />
                        <AvatarFallback>CN</AvatarFallback>
                      </Avatar>
                      <span>{offer.authorName}</span>
                    </div>
                  </TableCell>
                  <TableCell>{TitleOfferBook(offer)}</TableCell>
                  <TableCell>
                    {offer?.contactForm === "call"
                      ? TYPE_METHOD_BOOKING.call
                      : offer?.contactForm === "meet"
                        ? TYPE_METHOD_BOOKING.meet
                        : TYPE_METHOD_BOOKING.show}
                  </TableCell>
                  <TableCell className="text-nowrap">
                    {FormatMoneyToFixed(PriceOfferBook(offer))} vnđ
                  </TableCell>
                  <TableCell className="flex items-center justify-start gap-3">
                    <StatusBadge status={offer.status} />
                    {[-2, -3].includes(offer?.status) && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="size-4" />
                          </TooltipTrigger>
                          <TooltipContent className="border-red-500 font-medium text-red-500 backdrop-opacity-10">
                            <p className="line-clamp-4 max-w-[300px]">
                              {offer?.report?.content ||
                                offer?.cancel?.content ||
                                ""}
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </TableCell>
                  <TableCell>
                    {dayjs(offer.createdAt).format("HH:mm DD/MM/YYYY")}
                  </TableCell>
                  <TableCell className="text-center">
                    {FormatMoneyToFixed(
                      PriceOfferBook(offer) *
                        FarsePercentNumber(
                          offer?.commissionPercentageAffiliate || +percent,
                        ),
                    )}{" "}
                    vnđ
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!isFetching && (offers?.length || 0) <= 0 && <NoData />}
          {isFetching && (
            <div className="grid h-20 w-full place-content-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          )}
        </CardContent>
      </Card>
      <div className="mt-2"></div>
      <Paginate isOver={isOver} total={total} limit={limit} skip={skip} />
    </>
  );
}

const filterLinkSchema = z.object({
  affiliateLink: z.string().optional(),
  sort: z.string().optional(),
  type: z.string().optional(),
});

const LinkFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const affiliateLink = searchParams.get("affiliateLink") || "";
  const sort = searchParams.get("sort") || "";
  const type = searchParams.get("type") || `${EAffiliateLinkType.ALL}`;

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      affiliateLink: affiliateLink,
      sort: sort,
      type: type,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    const {
      formState: { dirtyFields },
    } = form;
    const payloadFilter = getDirtyFields(values, dirtyFields);
    setSearchParams((sp) => {
      Object.keys(payloadFilter).forEach((key) => {
        sp.set(
          key,
          payloadFilter[key as keyof typeof payloadFilter]?.toString() || "",
        );
      });
      return sp;
    });
  };

  return (
    <>
      <div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 py-3 sm:grid-cols-3 md:grid-cols-5"
          >
            {/* <FormField
              control={form.control}
              name="affiliateLink"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Id</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Trạng thái</FormLabel>
                  <Select
                    value={type}
                    onValueChange={(value) => {
                      setSearchParams((sp) => {
                        sp.set("type", value);
                        return sp;
                      });
                    }}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Chọn trạng thái" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={`${EAffiliateLinkType.ALL}`}>
                        Tất cả
                      </SelectItem>
                      <SelectItem
                        value={`${EAffiliateLinkType.WAIT_CONFIRMED}`}
                      >
                        Chờ xác nhận
                      </SelectItem>
                      <SelectItem value={`${EAffiliateLinkType.CONFIRMED}`}>
                        Đã xác nhận
                      </SelectItem>
                      <SelectItem value={`${EAffiliateLinkType.ADVISED}`}>
                        Đã tư vấn
                      </SelectItem>
                      <SelectItem
                        value={`${EAffiliateLinkType.BOOK_SUCCESSED}`}
                      >
                        Hoàn thành
                      </SelectItem>
                      <SelectItem value={`${EAffiliateLinkType.CANCELED}`}>
                        Hủy
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="sort"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Sắp xếp</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder="Sắp xếp theo"
                          defaultValue={field.value}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Tăng dần</SelectItem>
                      <SelectItem value="-1">Giảm dần</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("affiliateLink");
                    sp.delete("sort");
                    sp.delete("type");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}
