import { Controller } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface Props {
  titleInput?: string;
  containerClass?: string;
  spanClass?: string;
  control: any;
  disableThousandSeparator?: boolean;
}
type InputProps = Props & NumericFormatProps;

const InputFormatMoney = (props: InputProps) => {
  const {
    titleInput = "",
    containerClass = "",
    spanClass = "",
    className = "",
    control,
    name = "",
    maxLength = 13,
    disableThousandSeparator = false,
    ...inputProps
  } = props;
  return (
    <div className={containerClass}>
      {!!titleInput && (
        <label
          htmlFor={name}
          className={"text-grey-2 mb-[5px] block " + spanClass}
        >
          {titleInput}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, name, value, ref } }) => (
          <NumericFormat
            {...inputProps}
            getInputRef={ref}
            name={name}
            onValueChange={(value) => {
              onChange(value.floatValue);
            }}
            value={value}
            allowNegative={false}
            allowLeadingZeros={false}
            valueIsNumericString={true}
            decimalSeparator="."
            thousandSeparator={disableThousandSeparator ? false : ","}
            maxLength={maxLength}
            decimalScale={0}
            className={`flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
          />
        )}
      />
    </div>
  );
};

export default InputFormatMoney;
