import { Phone } from "lucide-react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { useInfoAdminStore } from "../../../store/admin.store";
import { useInfoUserStore } from "../../../store/user.store";
import { ESelectForm } from "../../../types/enum";
import ForgetPassword from "./forgetPassword";
import LoginEmailPassword from "./loginEmailPassword";
import LoginPhoneNumber from "./loginPhoneNumber";

const Login = () => {
  const [formLogin, setFormLogin] = useState<ESelectForm>(
    ESelectForm.EmailPassword,
  );
  const infoAdmin = useInfoAdminStore((state) => state.inforAdmin);
  const infoUser = useInfoUserStore((state) => state.inforUser);
  if (infoAdmin._id || infoUser._id) {
    return <Navigate to={"/"} />;
  }
  const rederUIForm = () => {
    switch (formLogin) {
      case ESelectForm.PhoneNumber:
        return (
          <LoginPhoneNumber
            onBack={() => setFormLogin(ESelectForm.EmailPassword)}
          />
        );
      case ESelectForm.ForgetPassword:
        return (
          <ForgetPassword
            onBack={() => setFormLogin(ESelectForm.EmailPassword)}
          />
        );

      default:
        return (
          <div>
            <div className="text-center text-foreground">
              <Button
                onClick={() => setFormLogin(ESelectForm.PhoneNumber)}
                variant="outlineCustom"
              >
                <Phone className="mr-2 h-4 w-4" /> Đăng nhập với số điện thoại
              </Button>
            </div>
            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300 dark:before:border-neutral-500 dark:after:border-neutral-500">
              <p className="mx-4 mb-0 text-center font-semibold dark:text-white">
                Or
              </p>
            </div>
            <p className="py-5 text-base font-semibold text-foreground">
              Đăng nhập với tài khoản
            </p>
            <LoginEmailPassword />
            <p
              onClick={() => setFormLogin(ESelectForm.ForgetPassword)}
              className="mt-3 cursor-pointer text-right text-sm font-semibold text-red-500 hover:underline"
            >
              Quên mật khẩu ?
            </p>
          </div>
        );
    }
  };

  return (
    <div>
      <div className="flex h-screen items-center justify-start bg-background">
        <div className="hidden h-screen w-[80%] lg:block">
          <img
            className="h-full w-full object-cover"
            src="https://preview.colorlib.com/theme/bootstrap/login-form-02/images/bg_1.jpg.webp"
            alt="login"
          />
        </div>
        <div className="flex h-screen w-full items-center justify-center bg-card p-8">
          <div className="w-[500px] max-w-[500px] lg:w-[400px]">
            <h1 className="mb-10 text-5xl font-semibold text-foreground">
              {formLogin === ESelectForm.ForgetPassword
                ? "Quên mật khẩu"
                : "Đăng nhập"}
            </h1>
            {rederUIForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
