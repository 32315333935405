import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
interface IPModalContainer {
  children: React.ReactElement;
  isVisible: boolean;
  closeModal: (value: boolean) => void;
  titleModal?: string;
  className?: string;
}

const ModalContainer = (props: IPModalContainer) => {
  const {
    children,
    isVisible,
    closeModal,
    titleModal = "",
    className = "w-full",
  } = props;
  return (
    <Dialog open={isVisible} onOpenChange={closeModal}>
      <DialogContent className={className}>
        <DialogHeader className="pb-3">
          <DialogTitle>{titleModal}</DialogTitle>
        </DialogHeader>
        {children}
        {/* <DialogFooter>
					<Button variant="default" type="submit">
						Save changes
					</Button>
				</DialogFooter> */}
      </DialogContent>
    </Dialog>
  );
};

export default ModalContainer;
