import { create } from "zustand";
import { IPermissionGroup } from "../types/admin";
interface IGroupPermissionStore {
	listGroupPermission: Array<IPermissionGroup>;
	getListGroupPermission: (group: Array<IPermissionGroup>) => void;
}
export const usePermissionStore = create<IGroupPermissionStore>((set, get) => ({
	listGroupPermission: [],
	getListGroupPermission: (group: Array<IPermissionGroup>) => {
		const amountState = get().listGroupPermission;
		set({ ...amountState, listGroupPermission: group });
	},
}));
