export const FormatPercentNumber = (percent?: number) => {
  if (!percent) return 0 + "%";
  return (percent <= 1 ? percent * 100 : percent) + "%";
};

export const FarsePercentNumber = (percent?: number) => {
  if (!percent) return 0;
  return percent <= 1 ? percent : percent / 100;
};

export const FormatMoneyToFixed = (money: number): string => {
  if (!money) return "0";
  const formatMoney = money
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return formatMoney;
};
