export enum EModeTheme {
  DarkMode = "dark",
  LightMode = "light",
}
export enum ESelectForm {
  PhoneNumber = "PhoneNumber",
  EmailPassword = "EmailPassword",
  ForgetPassword = "ForgetPassword",
}
export enum ERoleAccount {
  Admin = "admin",
  User = "user",
}

export enum EStatusUser {
  Block = 0,
  Active = 1,
}
export enum EStatusBank {
  Active = 1,
  Deleted = 0,
  Pending = -1,
  Reject = -2,
}
export enum EKeyCookie {
  TOKEN = "token",
}
export enum ETypeCardBank {
  CREDIT = 0,
  INLAND_ATM = 1,
  BANK_ACCOUNT = 2,
}

export enum EActionForm {
  ADD = "Add",
  EDIT = "Edit",
}

export enum ECodePermission {
  // Account
  "Create_account" = 0,
  "Read_account" = 1,
  "Update_account" = 2,
  "Delete_account" = 3,

  // Role
  "Create_role" = 4,
  "Read_role" = 5,
  "Update_role" = 6,
  "Delete_role" = 7,

  // Permisstion
  "Read_permission" = 8,

  // Permisstion Group
  "Create_permission_group" = 9,
  "Read_permission_group" = 10,
  "Update_permission_group" = 11,
  "Delete_permission_group" = 12,

  // Affiliate
  "Create_affiliate" = 13,
  "Read_affiliate" = 14,
  "Update_affiliate" = 15,
  "Delete_affiliate" = 16,

  // Bank
  "Update_bank" = 17,
  "Delete_bank" = 18,
  "Read_bank" = 19,

  // Affiliate Link
  "Read_affiliate_link" = 20,
  "Create_affiliate_link" = 21,
  "Update_affiliate_link" = 22,
  "Delete_affiliate_link" = 23,
}

export enum EKeyQueryApi {
  keyRole = "keyApi",
  keyGroupPermission = "keyGroupPermission",
  keyPermission = "keyPermission",
  keyUser = "keyUser",
  keyListUser = "keyListUser",
  keyAdmin = "keyAdmin",
  keyBank = "keyBank",
  keyLink = "keyLink",
  keyLinkAdmin = "keyLinkAdmin",
  keyLinkOffers = "keyLinkOffers",
  keyLinkOffersUser = "keyLinkOffersUser",
  keyLinkOffersAdmin = "keyLinkOffersAdmin",
  keyTransactions = "keyTransactions",
  keyTransactionsAdmin = "keyTransactionsAdmin",
  keyAffiliateCreateLink = "keyAffiliateCreateLink",
  keyCategories = "keyCategories",
  keyPopular = "keyPopular",
  keyOverview = "keyOverview",
}

export enum TransactionType {
  COMMISSION = 1, // *tiền hoa hồng chuyển về
  PAY = 2, //*giao dịch admin chuyển về
}

export enum ELinkStatus {
  DELETE = -1,
  ACTIVE = 1,
}

export enum ESystemCode {
  Success = "AFF_0000",
  Faild = "AFF_0001",
}

export enum EOrderStatus {
  ALL = 0,
  PENDING = 1,
  CONFIRMED = 2,
  CONSULTED = 3,
  COMPLETE = 4,
  CANCELED = 5,
}

export enum EAffiliateLinkType {
  ALL = 1, // lấy tất cả
  HAPPENING = 2, // 1, 2, 3, 4, 7
  BOOK_SUCCESSED = 3, // 5, 6
  CANCELED = 4, // -2, -5, -7
  WAIT_CONFIRMED = 5, // 3
  CONFIRMED = 6, // 4
  ADVISED = 7, // 7
}
