import ky from "ky";
import { Cookies } from "react-cookie";

const urlClient = process.env.REACT_APP_API_ENDPOINT;

const apiClient = ky.create({
  prefixUrl: urlClient,
  hooks: {
    beforeRequest: [
      (options) => {
        const cookies = new Cookies();
        const token = cookies.get("token");
        if (token) {
          options.headers.set("Authorization", ` Bearer ${token}`);
        }
      },
    ],
  },
});

export default apiClient;
