import { RouteApi } from "../../apis/routeApi";
import apiClient from "../../apis/user.api";
import { IResponse } from "../../types/api";
const url = RouteApi.adminAffiliate;

type AdminLoginResponse = IResponse<{
  token: string;
  permissions: any[];
  _id: string;
}>;

export const forgotPassword_Admin = async (data: { phone: string }) => {
  const res = await apiClient
    .post(url + "/administrators/forgot-pass", { json: data })
    .json<any>();
  return res;
};

export const forgotPassVerify_Admin = async (data: {
  otp: string;
  phone: string;
}) => {
  const res = await apiClient
    .post(url + "/administrators/forgot-pass-verification", { json: data })
    .json<AdminLoginResponse>();
  return res;
};

export const setPassword_Admin = async (data: {
  password: string;
  token: string;
}) => {
  const res = await apiClient
    .post(url + "/administrators/set-password", {
      json: {
        password: data.password,
      },
      headers: {
        Authorization: `Bearer ${data.token} `,
      },
    })
    .json<any>();
  return res;
};

export const resetPasswork_Admin = async (data: {
  oldPassword: string;
  password: string;
}) => {
  const res = await apiClient
    .put(url + "/administrators/reset-password", { json: data })
    .json<any>();
  return res;
};
