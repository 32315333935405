interface INodata {
	content?: string;
}

const NoData = (props: INodata) => {
	const { content = "Rất tiếc, bạn chưa có dữ liệu!" } = props;
	return (
		<>
			<div className="py-10 w-full flex flex-col items-center justify-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					className="h-10 w-10 text-muted-foreground"
					viewBox="0 0 24 24"
				>
					<circle cx="12" cy="11" r="1"></circle>
					<path d="M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5ZM8 14a5 5 0 1 1 8 0"></path>
					<path d="M17 18.5a9 9 0 1 0-10 0"></path>
				</svg>
				<h3 className="mt-4 text-lg font-semibold">{content}</h3>
			</div>
		</>
	);
};

export default NoData;
