import { KeySquare, LogOut } from "lucide-react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import useLogout from "../../hooks/useLogout";
import { useInfoAdminStore } from "../../store/admin.store";
import { useInfoUserStore } from "../../store/user.store";
import { ERoleAccount } from "../../types/enum";
import FormChangePassword from "../form/formChangePassword";
import ModalHandleContainer from "../modalHandle/ModalContainer";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export function PopoverAccount() {
  const handleLogout = useLogout({ redirect: "/login" });
  const [cookies] = useCookies(["role"]);
  const [isResetPassword, setResetPassword] = useState<boolean>(false);
  const inforAdmin = useInfoAdminStore((state) => state.inforAdmin);
  const inforUser = useInfoUserStore((state) => state.inforUser);
  const dataAccount: any = inforUser || inforAdmin || "";

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Avatar className="cursor-pointer">
            <AvatarImage
              src={
                dataAccount?.avatar?.location || "https://github.com/shadcn.png"
              }
              alt="avatar"
            />
            <AvatarFallback>{dataAccount.username}</AvatarFallback>
          </Avatar>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-56">
          <div className="grid gap-2 divide-y">
            <div className="flex flex-row items-center gap-4">
              <Avatar className="cursor-pointer">
                <AvatarImage
                  src={
                    dataAccount?.avatar?.location ||
                    "https://github.com/shadcn.png"
                  }
                  alt="avatar"
                />
                <AvatarFallback>AF</AvatarFallback>
              </Avatar>
              <div className="text-foreground">
                <p className="line-clamp-1 text-sm font-semibold">
                  {dataAccount?.fullname}
                </p>
                <p className="line-clamp-1 text-xs">@{dataAccount?.username}</p>
              </div>
            </div>
            {cookies?.role === ERoleAccount.Admin && (
              <div className="space-y-1">
                <Button
                  className="focus:none mt-2 w-full justify-start"
                  variant="ghost"
                  onClick={() => setResetPassword(true)}
                >
                  <KeySquare className="mr-2 h-4 w-4" />
                  Đổi mật khẩu
                </Button>
              </div>
            )}

            <div className="space-y-1">
              <Button
                onClick={() => handleLogout()}
                className="mt-2 w-full justify-start"
                variant="ghost"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Đăng xuất
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>

      {isResetPassword && (
        <ModalHandleContainer
          isVisible={isResetPassword}
          closeModal={() => setResetPassword(false)}
          titleModal="Đổi mật khẩu"
        >
          <FormChangePassword
            role={ERoleAccount.Admin}
            onHandle={() => {
              setResetPassword(false);
              handleLogout();
            }}
          />
        </ModalHandleContainer>
      )}
    </>
  );
}
