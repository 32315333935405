import ky from "ky";
import { Cookies } from "react-cookie";

const urlAdmin = process.env.REACT_APP_API_ENDPOINT;
const apiAdmin = ky.create({
	prefixUrl: urlAdmin,
	hooks: {
		beforeRequest: [
			(options) => {
				const cookies = new Cookies();
				const token = cookies.get("token");
				options.headers.set("Authorization", ` Bearer ${token}`);
			},
		],
	},
});

export default apiAdmin;
