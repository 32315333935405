// import TitlePage from "../../components/titlePage";
// import { EyeIcon } from '@heroicons/react/24/outline';
import { useQuery } from "@tanstack/react-query";
import { TrendingUp } from "lucide-react";
import { useState } from "react";
import FormCreateTopExpert from "../../../components/form/formCreateTopExpert";
import ModalHandleContainer from "../../../components/modalHandle/ModalContainer";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import { getOverviewAdmin } from "../../../services/adminAffiliates.service";
import { getReferencePopulars_Admin } from "../../../services/adminService/referencePopulars.services";
import { EKeyQueryApi } from "../../../types/enum";
import { Overview } from "./components/ChartAll";
import ExpertTrending from "./components/ExpertTrending";
import RecentTransaction from "./components/RecentTransaction";
import { Test } from "./components/test";
import TopLinkTracking from "./components/TopLinkTracking";

const HomeAdmin = () => {
  const [isTopTrending, setTopTrending] = useState<boolean>(false);

  const { data: dataTopTrending } = useQuery({
    queryKey: [EKeyQueryApi.keyPopular],
    queryFn: () => getReferencePopulars_Admin(),

    retry: false,
  });
  const { data: dataOverview } = useQuery({
    queryKey: [EKeyQueryApi.keyOverview],
    queryFn: () => getOverviewAdmin(),
    retry: false,
  });

  const listPopular = dataTopTrending?.data || [];
  const getListOverview = dataOverview?.data || [];

  const DataAnalitisHome = [
    {
      icon: "/icons/ic_total_user.png",
      price: getListOverview?.totalDesktopClick || 0,
      title: "Tracking desktop",
      priceChange: "",
    },
    {
      icon: "/icons/ic_total_user.png",
      price: getListOverview?.totalMobileClick || 0,
      title: "Tracking mobile",
      priceChange: "",
    },
    {
      icon: "/icons/ic_sale.png",
      price: getListOverview?.totalAffiliate || 0,
      title: "Tổng affiliate",
      priceChange: "",
    },
    {
      icon: "/icons/ic_total_user.png",
      price: getListOverview?.totalAffiliateLink || 0,
      title: "Tổng link",
      priceChange: "",
    },
  ];

  return (
    <>
      <div className="space-y-10">
        <div className="flex flex-wrap items-center justify-between gap-5">
          <div className="flex items-center gap-x-3">
            <div className="h-8 w-4 rounded-[4px] bg-primary py-1"></div>
            <p className="text-3xl font-semibold text-neutral-700 dark:text-gray-50">
              Dashboard
            </p>
          </div>
          {/* <CalendarDateRangePicker /> */}
        </div>
        <div className="grid gap-4 md:grid-cols-4">
          {DataAnalitisHome.map((item, index) => {
            return (
              <div
                key={index}
                className="rounded-lg border bg-card p-4 text-card-foreground shadow-sm"
              >
                <div className="flex items-center justify-between">
                  <span className="text-xl font-semibold uppercase">
                    {item.title}
                  </span>
                  <img
                    className="size-12 object-cover"
                    src={item.icon}
                    alt="askany"
                  />
                </div>
                <div className="mt-4 flex items-end justify-between">
                  <div>
                    <h4 className="text-title-md text-[18px] font-bold text-black dark:text-white">
                      {item.price}
                    </h4>
                  </div>
                  <span className="text-meta-3 flex items-center gap-1 text-sm font-medium text-[#40e981]">
                    {item.priceChange}
                    <TrendingUp className="size-10" />
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <Card className="mt-8 w-full">
          <Test />
        </Card>

        <div className="mt-8 grid gap-4 md:grid-cols-2 lg:grid-cols-6">
          <Card className="col-span-3">
            <Overview />
          </Card>
          <Card className="col-span-3">
            <RecentTransaction />
          </Card>
        </div>

        <TopLinkTracking />

        <div className="flex flex-wrap items-center gap-3">
          <div className="h-8 w-4 rounded-[4px] bg-primary py-1"></div>
          <p className="text-3xl font-semibold text-neutral-700 dark:text-gray-50">
            Top chuyên gia nổi bật
          </p>
          <Button onClick={() => setTopTrending(true)} type="button">
            Tạo mới
          </Button>
        </div>
        <div>
          <ExpertTrending data={listPopular} />
        </div>
      </div>
      {isTopTrending && (
        <ModalHandleContainer
          isVisible={isTopTrending}
          closeModal={() => setTopTrending(false)}
          titleModal="Thêm chuyên gia"
        >
          <FormCreateTopExpert />
        </ModalHandleContainer>
      )}
    </>
  );
};

export default HomeAdmin;
