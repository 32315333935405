export const ERROR_AFF: {
  [key: string]: {
    code: string;
    message: string;
  };
} = {
  AFF_0000: {
    code: "AFF_0000",
    message: "Thành công",
  },
  AFF_0005: {
    code: "AFF_0005",
    message: "Tài khoản đăng nhập không hợp lệ",
  },
  AFF_0009: {
    code: "AFF_0009",
    message: "Bạn không có quyền thực hiện",
  },
  AFF_0010: {
    code: "AFF_0010",
    message: "Tên đăng nhập đã tồn tại",
  },
  AFF_0014: {
    code: "AFF_0014",
    message: "Tài khoản đã tồn tại",
  },
  AFF_0016: {
    code: "AFF_0016",
    message: "Thẻ ngân hàng đã bị xóa",
  },
  AFF_0017: {
    code: "AFF_0017",
    message: "Số điện thoại đã tồn tại",
  },
};
