import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Lock, Mail, Percent, Phone, User } from "lucide-react";
import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { toast } from "../../../components/ui/use-toast";
import { createUser } from "../../../services/adminAffiliates.service";
import { EKeyQueryApi, EStatusUser } from "../../../types/enum";
import { IRequestBodyUser } from "../../../types/user";
import { ERROR_AFF } from "../../../apis/systemCode";

const formSchema = z.object({
  username: z.string().min(3, "Tên tài khoản chưa ít nhất 3 ký tự").max(50),
  fullname: z.string().min(3, "Tên phải chưa ít nhất 3 ký tự").max(50),
  phone: z.string().min(3).max(12),
  password: z.string().min(5),
  email: z.string().email({ message: "Địa chỉ email không hợp lệ" }),
  avatar: z.any(),
  commissionPercent: z.coerce.number({ required_error: "Vui lòng nhập" }),
});

interface ICreateAccount {
  onClose?: () => void;
}

const CreateAccount = (props: ICreateAccount) => {
  const {
    onClose = () => {
      return;
    },
  } = props;
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    IRequestBodyUser,
    unknown
  >({
    mutationFn: createUser,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title:
          ERROR_AFF[res.systemCode]?.message ||
          "Hệ thống đang lỗi, vui lòng thử lại!",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyListUser] });
      toast({
        variant: "success",
        title: "Tạo tài khoản thành công",
        duration: 2000,
      });
      onClose();
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      fullname: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const percent =
      values?.commissionPercent <= 1
        ? values?.commissionPercent
        : values?.commissionPercent / 100 || 0;
    mutate({
      ...values,
      status: EStatusUser.Active,
      commissionPercent: percent,
    });
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
            <InputField
              name="username"
              label="Tên tài khoản"
              placeholder="ABC"
              description="At least 3 characters."
              formControl={form.control}
              icon={User}
            />
            <InputField
              name="fullname"
              label="Họ và tên"
              placeholder="Nguyen van A"
              description="At least 3 characters."
              formControl={form.control}
              icon={User}
            />
          </div>

          <InputField
            name="email"
            label="Email"
            placeholder="abc@gmail.com"
            description="At least 8 characters."
            formControl={form.control}
            icon={Mail}
          />
          <InputField
            name="phone"
            label="Số điện thoại"
            placeholder="090xxxxxx0"
            description="At least 8 characters."
            formControl={form.control}
            icon={Phone}
          />
          <InputField
            name="commissionPercent"
            label="Phần trăm hoa hồng"
            placeholder="20"
            inputType="number"
            formControl={form.control}
            icon={Percent}
          />
          <InputField
            name="password"
            label="Mật khẩu"
            placeholder="Vui lòng nhập password"
            description="At least 8 characters."
            inputType="password"
            formControl={form.control}
            icon={Lock}
          />

          <div className="text-end">
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="defaultCustom"
              type="submit"
              disabled={isPending}
            >
              Thêm tài khoản
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof formSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof formSchema>>;
  icon: any;
}

const InputField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CreateAccount;
