import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { ArrowLeft, Phone } from "lucide-react";
import React, { useState } from "react";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ERROR_AFF } from "../../apis/systemCode";
import { editprofileUser } from "../../services/affiliate";
import { verifyPhone } from "../../services/affiliateService/auth";
import { EKeyQueryApi } from "../../types/enum";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/input-otp";
import { useToast } from "../ui/use-toast";

interface ILoginPhoneNumber {
  onBack?: () => void;
  onHandle?: () => void;
}
const formPhoneNumber = z.object({
  phone: z.string().min(3, "Vui lòng nhập số điện thoại đăng ký").max(12),
});

const formOtp = z.object({
  otp: z.string().min(6, {
    message: "Vui lòng nhập mã otp",
  }),
});
interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof formPhoneNumber>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof formPhoneNumber>, any>;
  icon: any;
  focus?: boolean;
}
const FormChangePhoneUser = (props: ILoginPhoneNumber) => {
  const {
    onBack = () => {
      return;
    },
    onHandle = () => {
      return;
    },
  } = props;
  const { toast } = useToast();
  const [isFormOtp, setFormOtp] = useState<{ isOpen: boolean; code: string }>({
    isOpen: false,
    code: "",
  });
  const queryClient = useQueryClient();
  const { mutate: mutatePhone } = useMutation<
    any,
    HTTPError,
    { phone?: string },
    unknown
  >({
    mutationFn: editprofileUser,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: ERROR_AFF[res.systemCode].message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      setFormOtp({ isOpen: true, code: data.data._id });
    },
  });

  const { mutate: mutateOtp } = useMutation<
    any,
    HTTPError,
    { otp: string },
    unknown
  >({
    mutationFn: verifyPhone,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Thay đổi thất bại",
      });
    },
    onSuccess(data, variables, context) {
      toast({
        variant: "success",
        title: "Thay đổi thành công",
      });
      setFormOtp({ isOpen: false, code: "" });
      onHandle();
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyUser] });
    },
  });

  const form = useForm<z.infer<typeof formPhoneNumber>>({
    resolver: zodResolver(formPhoneNumber),
    defaultValues: {
      phone: "",
    },
  });

  const formOTP = useForm<z.infer<typeof formOtp>>({
    resolver: zodResolver(formOtp),
    defaultValues: {
      otp: "",
    },
  });

  const onSubmitNumberPhone = (values: z.infer<typeof formPhoneNumber>) => {
    mutatePhone(values);
  };
  const onSubmitOtp = (values: z.infer<typeof formOtp>) => {
    const payload = {
      otp: values.otp,
    };
    mutateOtp(payload);
  };

  return (
    <div className="">
      {isFormOtp.isOpen ? (
        <Form {...formOTP}>
          <form
            onSubmit={formOTP.handleSubmit(onSubmitOtp)}
            className="w-full space-y-6"
          >
            <FormField
              control={formOTP.control}
              name="otp"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nhập otp</FormLabel>
                  <FormControl>
                    <InputOTP
                      containerClassName="justify-between"
                      maxLength={6}
                      {...field}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormDescription>Vui lòng nhập mã otp</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="text-right">
              <Button className="w-[100px]" type="submit">
                Gửi
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitNumberPhone)}
            className="space-y-4"
          >
            <SignupFormField
              name="phone"
              label=""
              placeholder="Vui lòng nhập sđt"
              description=""
              formControl={form.control}
              icon={Phone}
              inputType="string"
              focus
            />

            <div className="text-end">
              <Button
                className="w-full rounded-[8px] py-5 text-base font-semibold"
                variant="defaultCustom"
                type="submit"
              >
                Tiếp tục
              </Button>
            </div>
          </form>
        </Form>
      )}

      <div
        onClick={onBack}
        className="mt-5 flex w-fit cursor-pointer items-center justify-start gap-1 text-sm text-foreground hover:underline"
      >
        <ArrowLeft className="size-5" />
        <span className="font-medium">Quay lại</span>
      </div>
    </div>
  );
};

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
  focus = false,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold text-foreground">
            {label}
          </FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="text-base font-medium text-foreground"
              Icon={icon}
              autoFocus={focus}
              {...field}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormChangePhoneUser;
