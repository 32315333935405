import { create } from "zustand";
import {
  getListCardBankAsync,
  updateCardBankUser,
} from "../services/bank.services";
import { ESystemCode } from "../types/enum";
interface IInforBank {
  listBank: any[];
  getListCardBank: () => void;
  getInfoBankAccount: (data: any) => void;
  updateInfoBank: (data: any) => Promise<{ error: boolean; message?: string }>;
  infoBankUser: any;
}
export const useBankStore = create<IInforBank>((set, get) => ({
  listBank: [],
  listBankUser: [],
  infoBankUser: [],

  getListCardBank: async () => {
    const response = await getListCardBankAsync();
    const amountState = get().listBank;
    set({ ...amountState, listBank: response?.data });
  },

  getInfoBankAccount: (data: any) => {
    const tempInfoBankUser = get().infoBankUser;
    set({ ...tempInfoBankUser, infoBankUser: data });
  },

  updateInfoBank: async (data) => {
    try {
      const resBank = await updateCardBankUser(data);
      const tempInfoBankUser = get().infoBankUser;
      if (resBank.systemCode === ESystemCode.Success) {
        set({ ...tempInfoBankUser, infoBankUser: resBank?.data });
        return { error: false, message: resBank.systemCode };
      }
      return { error: true, message: resBank.systemCode };
    } catch (err) {
      const error: any = err;
      const res: { message: string; systemCode: string } =
        await error?.response.json();
      if (res?.systemCode === "AFF_0016") {
        const tempInfoBankUser = get().infoBankUser;
        set({ ...tempInfoBankUser, infoBankUser: undefined });
      }
      return { error: true, message: res?.systemCode };
    }
  },
}));
