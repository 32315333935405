import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IBodyGroupPermission, IPermission } from "../types/admin";
import { IResponse } from "../types/api";

const url = RouteApi.permissionGroups;
const urlPermission = RouteApi.permission;

export const getListGroupPermission = () => {
  return apiClient.get(url).json<IResponse<any>>();
};

export const getListPermission = () => {
  return apiClient.get(urlPermission).json<IResponse<Array<IPermission>>>();
};

export const createGroupPermission = (payload: IBodyGroupPermission) => {
  return apiClient.post(url, { json: payload }).json<IResponse<any>>();
};

export const deleteGroupPermission = async (idGroup: string) => {
  const res = await apiClient.delete(url + "/" + idGroup).json<any>();
  return res;
};
export const updateGroupPermission = async ({ idGroup, ...payload }: any) => {
  const res = await apiClient
    .put(url + "/" + idGroup, { json: payload })
    .json<any>();
  return res;
};
