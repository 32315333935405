import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FieldNamesMarkedBoolean, FieldValues, useForm } from "react-hook-form";
import { z } from "zod";
import {
	createGroupPermission,
	getListPermission,
	updateGroupPermission,
} from "../../services/permissions";
import { EActionForm, EKeyQueryApi } from "../../types/enum";

import { HTTPError } from "ky";
import { IPermission, IPermissionGroup } from "../../types/admin";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { ScrollArea } from "../ui/scroll-area";
import { toast } from "../ui/use-toast";

interface IFormPermission {
	action?: EActionForm;
	onHandle?: () => void;
	dataEditGroup: Partial<IPermissionGroup>;
}

const FormSchema = z.object({
	permissions: z
		.array(z.string())
		.refine((value) => value.some((item) => item), {
			message: "Vui lòng chọn quyền",
		}),
	groupName: z.string().min(2, {
		message: "Vui lòng điền tên nhóm",
	}),
});

const FormCreatePermission = (props: IFormPermission) => {
	const {
		action = EActionForm.ADD,
		onHandle = () => {
			return;
		},
		dataEditGroup = {},
	} = props;
	const queryClient = useQueryClient();

	const { data: result } = useQuery({
		queryKey: [EKeyQueryApi.keyPermission],
		queryFn: () => getListPermission(),
		initialData: {
			data: [],
			message: "",
			systemCode: "",
		},
		retry: false,
	});

	const { mutate: CreatePermission } = useMutation<
		any,
		HTTPError,
		any,
		unknown
	>({
		mutationFn:
			action === EActionForm.ADD
				? createGroupPermission
				: updateGroupPermission,
		async onError(error, variables, context) {
			const res: { message: string; systemCode: string } =
				await error.response.json();
			toast({
				variant: "destructive",
				title: res.message,
				duration: 2000,
			});
		},
		onSuccess(data, variables, context) {
			form.reset();
			queryClient.invalidateQueries({
				queryKey: [EKeyQueryApi.keyGroupPermission],
			});
			toast({
				variant: "success",
				title: "Thêm thành công",
				duration: 2000,
			});
			onHandle();
		},
	});
	const listPermission = result?.data;

	const getPermission = (listPermission: Array<IPermission>) => {
		let listPermissionChild: Array<string> = [];
		if ((listPermission?.length || 0) <= 0) return [];
		listPermission.forEach((element) => {
			listPermissionChild.push(element._id);
		});
		return listPermissionChild;
	};

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			permissions: getPermission(dataEditGroup?.permissions || []),
			groupName: dataEditGroup?.groupName || "",
		},
	});

	function onSubmit(data: z.infer<typeof FormSchema>) {
		if (action === EActionForm.ADD) {
			CreatePermission(data);
		} else {
			const {
				formState: { dirtyFields },
			} = form;
			const dataEdit = getDirtyFields(data, dirtyFields);
			const payloadEdit = { ...dataEdit, idGroup: dataEditGroup?._id || "" };
			CreatePermission(payloadEdit);
		}
	}
	return (
		<div>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 mb-5">
					<div className="my-3">
						<FormField
							control={form.control}
							name="groupName"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Tên nhóm</FormLabel>
									<FormControl>
										<Input placeholder="abc" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
					<ScrollArea className="h-[60vh] ">
						<FormField
							control={form.control}
							name="permissions"
							render={() => (
								<FormItem>
									<div className="my-4 flex flex-col gap-5">
										{listPermission.map((permission) => (
											<FormField
												key={permission._id}
												control={form.control}
												name="permissions"
												render={({ field }) => {
													return (
														<FormItem
															key={permission._id}
															className="flex flex-row permissions-start space-x-3 space-y-0"
														>
															<FormControl>
																<Checkbox
																	checked={field.value?.includes(
																		permission._id
																	)}
																	onCheckedChange={(checked) => {
																		return checked
																			? field.onChange([
																					...field.value,
																					permission._id,
																			  ])
																			: field.onChange(
																					field.value?.filter(
																						(value) => value !== permission._id
																					)
																			  );
																	}}
																/>
															</FormControl>
															<FormLabel className="font-normal">
																{permission.name}
															</FormLabel>
														</FormItem>
													);
												}}
											/>
										))}
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</ScrollArea>

					<Button type="submit">Tạo nhóm</Button>
				</form>
			</Form>
		</div>
	);
};
function getDirtyFields<T extends FieldValues>(
	values: T,
	dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>
) {
	const payload: Partial<T> = {};
	Object.keys(dirtyFields).forEach((key) => {
		if (dirtyFields[key as keyof typeof dirtyFields]) {
			payload[key as keyof T] = values[key as keyof T];
		}
	});
	return payload;
}
export default FormCreatePermission;
