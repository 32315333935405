import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../layouts";
import AdminManagerLink from "../pages/admin/ManageLink";
import ManagerAffiliateLink from "../pages/admin/ManageLink/linkAffiliate";
import ManagerBank from "../pages/admin/ManagerBank";
import ManagerPayment from "../pages/admin/ManagerPayment";
import ManagerUser from "../pages/admin/MangerUser";
import DetailUser from "../pages/admin/MangerUser/detail";
import AdminLogin from "../pages/admin/login";
import Permission from "../pages/admin/permission";
import Roles from "../pages/admin/roles";
import ManagerLinkUser from "../pages/affiliate/LinkAffiliate";
import LinkServicesUser from "../pages/affiliate/LinkAffiliate/services";
import Income from "../pages/affiliate/income";
import InfoUser from "../pages/affiliate/inforUser";
import Login from "../pages/affiliate/login";
import OrderBooking from "../pages/affiliate/order";
import HomeAdmin from "../pages/home/admin";
import HomeAffiliate from "../pages/home/affiliate";
import PrivateAdminRoutes from "./PrivateAdminRouter";

const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="admin/login" element={<AdminLogin />} />

          <Route element={<Dashboard />}>
            <Route path="/" element={<HomeAffiliate />} />
            <Route path="user" element={<InfoUser />} />
            <Route path="income" element={<Income />} />
            <Route path="order" element={<OrderBooking />} />
            <Route path="links" element={<ManagerLinkUser />} />
            <Route path="links/:id" element={<LinkServicesUser />} />

            {/* Router Admin */}
            <Route path="admin" element={<PrivateAdminRoutes />}>
              <Route index element={<HomeAdmin />} />
              <Route
                path="transactions/:affiliateId"
                element={<ManagerPayment />}
              />
              <Route path="manage-user">
                <Route index element={<ManagerUser />} />
                <Route path=":id" element={<DetailUser />} />
              </Route>
              <Route path="manager-bank" element={<ManagerBank />} />
              <Route path="manager-link" element={<AdminManagerLink />} />
              <Route
                path="manager-link/:affiliateId"
                element={<ManagerAffiliateLink />}
              />
              <Route path="permission" element={<Permission />} />
              <Route path="roles" element={<Roles />} />
            </Route>
          </Route>
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
