import {
  BanknoteIcon,
  BarChart4,
  BriefcaseBusiness,
  CircleUserRound,
  Link,
  UserRoundCog,
  Users,
  Wallet,
} from "lucide-react";
import { ROLE } from "./roles.constants";
import { EAffiliateLinkType } from "../types/enum";

export const menuLinks = [
  {
    title: "Dashboard",
    icon: <BarChart4 />,
    url: "/admin",
    role: ROLE.ADMIN,
  },
  {
    title: "Dashboard",
    icon: <BarChart4 />,
    url: "/",
    role: ROLE.USER,
  },

  {
    title: "Thông tin tài khoản",
    icon: <CircleUserRound />,
    url: "/user",
    role: ROLE.USER,
  },
  {
    title: "Quản lý link",
    icon: <Link />,
    url: "/links",
    role: ROLE.USER,
  },
  {
    title: "Quản lý trạng thái đơn",
    icon: <BriefcaseBusiness />,
    url: "/order",
    role: ROLE.USER,
  },
  {
    title: "Thu nhập",
    icon: <Wallet />,
    url: "/income",
    role: ROLE.USER,
  },
  {
    title: "Quản lý người dùng",
    icon: <Users />,
    url: "/admin/manage-user",
    role: ROLE.ADMIN,
    permission: [0, 1, 2, 3, 13, 14, 15, 16],
  },
  {
    title: "Quản lý thẻ",
    icon: <BanknoteIcon />,
    url: "/admin/manager-bank",
    role: ROLE.ADMIN,
    permission: [17, 18, 19],
  },
  {
    title: "Quyền tài khoản",
    icon: <Users />,
    url: "/admin/permission",
    role: ROLE.ADMIN,
    permission: [8, 9, 10, 11, 12],
  },
  {
    title: "Vai trò",
    icon: <UserRoundCog />,
    url: "/admin/roles",
    role: ROLE.ADMIN,
    permission: [4, 5, 6, 7],
  },
  {
    title: "Quản lý link ",
    icon: <Link />,
    url: "/admin/manager-link",
    role: ROLE.ADMIN,
    permission: [20, 21, 22, 23],
  },
];

export const menuTableWallet = [
  {
    title: "Mã giao dịch",
  },
  {
    title: "Trạng thái",
  },
  {
    title: "Hình thức",
  },
  {
    title: "Thời gian",
  },
  {
    title: "Số tiền",
  },
  {
    title: "Thực nhận",
  },
];

export const menuTabBooking = [
  {
    nameTab: "Tất cả",
    type: `${EAffiliateLinkType.ALL}`,
  },
  {
    nameTab: "Chờ xác nhận",
    type: `${EAffiliateLinkType.WAIT_CONFIRMED}`,
  },
  {
    nameTab: "Đã xác nhận",
    type: `${EAffiliateLinkType.CONFIRMED}`,
  },
  // {
  //   nameTab: "Đang diễn ra",
  //   type: `${EAffiliateLinkType.HAPPENING}`,
  // },
  {
    nameTab: "Đã tư vấn",
    type: `${EAffiliateLinkType.ADVISED}`,
  },
  {
    nameTab: "Hoàn thành",
    type: `${EAffiliateLinkType.BOOK_SUCCESSED}`,
  },
  {
    nameTab: "Hủy",
    type: `${EAffiliateLinkType.CANCELED}`,
  },
];
