import imageCompression from "browser-image-compression";

export const compressedImage = async (
	image: File,
	options: {
		maxSizeMB: number; // Set the maximum file size in MB;
		maxWidthOrHeight: number; // Set the maximum width or height
	}
) => {
	try {
		const compressedImage = await imageCompression(image, options);
		const compressedFile = new File([compressedImage], "name.jpeg", {
			type: "image/png",
		});
		return compressedFile;
	} catch (error) {
		console.log(error);
	}
};

export const isFileImage = (file: File) => {
	return file && file["type"].split("/")[0] === "image";
};
