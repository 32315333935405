import { RouteApi } from "../../apis/routeApi";
import apiClient from "../../apis/user.api";
import { IResPopularService } from "../../types/admin";
import { IListResponse } from "../../types/api";

const url = RouteApi.adminAffiliate;

export interface IReqReferencePopulars {
  reference: string;
}

export const getReferencePopulars_Admin = () => {
  return apiClient
    .get(url + "/reference-populars")
    .json<IListResponse<IResPopularService>>();
};

export const deleteReferencePopulars_Admin = (id: string) => {
  return apiClient.delete(url + "/reference-populars/" + id).json<any>();
};

export const createReferencePopulars_Admin = (
  payload: IReqReferencePopulars,
) => {
  return apiClient
    .post(url + "/reference-populars", { json: payload })
    .json<IResPopularService>();
};

export const getTopAffiliate = (
  payload: Partial<{
    skip?: number;
    limit?: number;
  }> = { skip: 0, limit: 5 },
) => {
  return apiClient
    .get(url + "/affiliates/top", { searchParams: { ...payload } })
    .json<any>();
};
