import { PenBox, Undo2 } from "lucide-react";
import { useState } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import CategoryInput from "../../../components/CategoryInput";
import ExpertByCategoryInput from "../../../components/ExpertByCategoryInput";
import {
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../../../components/ui/form";

export function ReferenceField<T extends FieldValues>({
	form, 
	defaultReference,
	name,
}: {
	form: UseFormReturn<T>,
	defaultReference: any,
	name: Path<T>,
}) {
	const [categorySlug, setCategorySlug] = useState<string>();
	const [isEdit, setIsEdit] = useState(false);
	if(!isEdit && !!defaultReference) {
		return <>
			<div
				className="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background h-10 px-3 py-2 w-full justify-between text-muted-foreground"
			>
				{defaultReference?.fullname}
				<button type="button" onClick={() => setIsEdit(true)}>
					<PenBox className="w-3.5 h-3.5" />
				</button>
			</div>
		</>
	}
	return <div className="border-t border-b py-2 my-2 rouned-sm">
		<FormItem>
			<div className="flex justify-between">
				<FormLabel>Danh mục</FormLabel>
				{defaultReference && <button type="button" onClick={() => setIsEdit(false)}>
					<Undo2 className="w-3.5 h-3.5" />
				</button>}
			</div>
			<CategoryInput
				onChange={(cate) => {
					setCategorySlug(cate?.vi.slug)						
				}} 
			/>
			<FormMessage />
		</FormItem>
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>Chuyên gia</FormLabel>
					<ExpertByCategoryInput category={categorySlug} 
						value={field.value}
						onChange={(expert) => {
							field.onChange(expert?._id);
						}}
						defaultExpert={defaultReference}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	</div>
}

export default ReferenceField