import ky from "ky";
import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IListResponse } from "../types/api";

const url = RouteApi.affiliateBank;
const urlAdmin = RouteApi.adminBank;

export const getListCardBankAsync = () => {
  try {
    return ky.get(`${process.env.REACT_APP_API_BANK}`).json<any>();
  } catch (error: any) {
    return error.message.json();
  }
};

export const getInfoBankUser = async () => {
  const res = await apiClient.get(url).json<any>();
  return res;
};

export const createCardBank = async (data: any) => {
  const res = await apiClient.post(url, { json: data }).json<any>();
  return res;
};
export const updateCardBankUser = async ({ idBank, ...payload }: any) => {
  const res = await apiClient
    .put(url + "/" + idBank, { json: payload })
    .json<IListResponse<any>>();
  return res;
};

export const deleteCardBankUser = async (idCard: string) => {
  const res = await apiClient.delete(url + "/" + idCard).json<any>();
  return res;
};

export const updateCardBank = async ({ idBank, ...payload }: any) => {
  const res = await apiClient
    .put(urlAdmin + "/" + idBank, { json: payload })
    .json<any>();
  return res;
};

export const deleteCardBank = async (idCard: string) => {
  const res = await apiClient.delete(urlAdmin + "/" + idCard).json<any>();
  return res;
};

export const getListBankUser = (
  payload: Partial<any> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(urlAdmin, { searchParams: { ...fields } })
    .json<IListResponse<any>>();
};
