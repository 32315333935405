import {
  CalendarIcon,
  ChevronDown,
  ChevronUp,
  Filter,
  FilterX,
  ListFilter,
  LoaderCircleIcon,
} from "lucide-react";
import WidgetTile from "../../../components/widgetTitle";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { format, toDate } from "date-fns";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { FieldNamesMarkedBoolean, FieldValues, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Card, CardContent } from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { FormatMoneyToFixed } from "../../../helpers/formatNumber";
import { cn } from "../../../lib/utils";
import { getTransactions } from "../../../services/transactions";
import { EKeyQueryApi } from "../../../types/enum";

const HistoryPayment = () => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const type = searchParams.get("type") || "1";
  const startDate = searchParams.get("startDate") || "";
  const endDate = searchParams.get("endDate") || "";
  const [showFilter, setShowFilter] = useState(false);

  const {
    data: {
      data: { isOver, listData, total },
    },
    isFetching,
  } = useQuery({
    queryKey: [
      EKeyQueryApi.keyTransactions,
      limit,
      skip,
      type,
      startDate,
      endDate,
    ],
    queryFn: () =>
      getTransactions({
        skip: skip,
        limit: limit,
        type: type,
        startDate: startDate,
        endDate: endDate,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  return (
    <div className="m mt-3 rounded-lg py-6">
      <WidgetTile title="Lịch sử giao dịch" />
      <div className="flex flex-wrap items-center justify-between gap-3">
        <Tabs defaultValue="1" className="">
          <TabsList>
            <TabsTrigger
              onClick={() => {
                setSearchParams((sp) => {
                  sp.set("type", "1");
                  return sp;
                });
              }}
              value="1"
            >
              Tiền hoa hồng(Tạm tính)
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setSearchParams((sp) => {
                  sp.set("type", "2");
                  return sp;
                });
              }}
              value="2"
            >
              Tiền về ngân hàng
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Bộ lọc
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
      </div>
      <LinkFilter open={showFilter} />
      <Card className="mt-5">
        <CardContent>
          <Table className="min-w-[1000px] overflow-x-auto">
            <TableHeader>
              <TableRow>
                {type === "1" ? (
                  <>
                    <TableHead>Mã gói dịch vụ</TableHead>
                    <TableHead>Nội dung</TableHead>
                  </>
                ) : (
                  <>
                    <TableHead>Mã giao dịch</TableHead>
                    <TableHead>Người chuyển</TableHead>
                  </>
                )}

                <TableHead>Số tiền đã chuyển</TableHead>
                <TableHead>trạng thái</TableHead>
                <TableHead>Thời gian</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!isFetching &&
                listData?.map((transaction: any) => (
                  <TableRow key={transaction._id} className="font-medium">
                    {type === "1" ? (
                      <>
                        <TableCell className="font-medium">
                          {transaction?.offer || "N/A"}
                        </TableCell>
                        <TableCell>
                          {"Tiền hoa hồng tháng " +
                            dayjs(transaction?.createdAt).format("MM/YYYY")}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className="font-medium">
                          {transaction?.orderID || "N/A"}
                        </TableCell>
                        <TableCell>CÔNG TY TNHH NDN</TableCell>
                      </>
                    )}

                    <TableCell>
                      {FormatMoneyToFixed(transaction.amount)} vnđ
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={transaction.status} />
                    </TableCell>

                    <TableCell>
                      {dayjs(transaction.createdAt).format(
                        "DD/MM/YYYY - HH:mm ",
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!isFetching && listData.length <= 0 && <NoData />}
          {isFetching && (
            <div className="grid h-20 w-full place-content-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          )}
        </CardContent>
      </Card>

      <div className="mt-2"></div>
      <Paginate isOver={isOver} total={total} limit={limit} skip={skip} />
    </div>
  );
};
const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    "1": {
      variant: "active",
      text: "Đã chuyển",
    },
    "2": {
      variant: "pending",
      text: "Đang xử lý",
    },
  };
  return <Badge variant={badge[status]?.variant}>{badge[status]?.text}</Badge>;
};
const filterLinkSchema = z.object({
  sort: z.string().optional(),
  type: z.string().optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
});
const LinkFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") || "";
  const type = searchParams.get("type") || "1";
  const startDate = searchParams.get("startDate") || "";
  const endDate = searchParams.get("endDate") || "";
  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      sort: sort,
      type: type,
      startDate: startDate ? toDate(startDate) : undefined,
      endDate: endDate ? toDate(endDate) : undefined,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    if (!!values?.startDate) {
      const formatStartTime = dayjs(values.startDate).format("YYYY-MM-DD");
      values.startDate = formatStartTime as any;
    }
    if (!!values?.endDate) {
      const formatEndTime = dayjs(values.endDate).format("YYYY-MM-DD");
      values.endDate = formatEndTime as any;
    }
    const {
      formState: { dirtyFields },
    } = form;
    const payload = getDirtyFields(values, dirtyFields);
    setSearchParams((sp) => {
      Object.keys(payload).forEach((key) => {
        sp.set(key, payload[key as keyof typeof payload]?.toString() || "");
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={cn("duration-200", !open ? "overflow-hidden" : "mt-5")}
        style={{
          maxHeight: open ? ref.current?.clientHeight : 0,
        }}
      >
        <Form {...form}>
          <form
            ref={ref}
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 sm:grid-cols-3 md:grid-cols-5"
          >
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian bắt đầu</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy")
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian kết thúc</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy")
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="sort"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Sắp xếp</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder="Sắp xếp theo"
                          defaultValue={field.value}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Tăng dần</SelectItem>
                      <SelectItem value="-1">Giảm dần</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("sort");
                    sp.delete("type");
                    sp.delete("startDate");
                    sp.delete("endDate");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}
export default HistoryPayment;
