const WidgetTile = ({ title }: { title: string }) => {
	return (
		<div className="flex mb-5 items-center gap-x-3">
			<div className="py-1 w-4 h-8 bg-primary rounded-[4px]"></div>
			<p className="text-lg font-semibold text-neutral-700 dark:text-gray-50 ">
				{title}
			</p>
		</div>
	);
};

export default WidgetTile;
