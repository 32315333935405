"use client";

import * as React from "react";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { getChartClickTracking_Admin } from "../../../../services/adminService/tracking";

const chartConfig = {
  visitors: {
    label: "lable",
  },
  totalDesktopClicks: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  totalMobileClicks: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

export function Test() {
  const [isYearCommission, setYearCommission] = React.useState("2024");
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      year: isYearCommission,
    }),
  );
  const year = parseInt(searchParams.get("year") || "2024");

  const { data: result } = useQuery({
    queryKey: ["abc", year],
    queryFn: () =>
      getChartClickTracking_Admin({
        year: year,
      }),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle>Tracking Desktop và Mobile</CardTitle>
        </div>
        <Select
          value={isYearCommission}
          onValueChange={(value) => {
            setYearCommission(value);
            setSearchParams((params) => {
              params.set("year", value);
              return params;
            });
          }}
        >
          <SelectTrigger
            className="w-[160px] rounded-lg sm:ml-auto"
            aria-label="Select a value"
          >
            <SelectValue placeholder="Last 3 months" />
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            {/* <SelectLabel>Năm</SelectLabel> */}
            <SelectItem value="2024">2024</SelectItem>
            <SelectItem value="2023">2023</SelectItem>
            <SelectItem value="2022">2022</SelectItem>
            <SelectItem value="2021">2021</SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <AreaChart data={result.data}>
            <defs>
              <linearGradient
                id="filltotalDesktopClicks"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor="var(--color-totalDesktopClicks)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="var(--color-totalDesktopClicks)"
                  stopOpacity={0.1}
                />
              </linearGradient>
              <linearGradient
                id="filltotalMobileClicks"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor="var(--color-totalMobileClicks)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="var(--color-totalMobileClicks)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="lable"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => `T${value}`}
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    return "Tracking";
                  }}
                  indicator="dot"
                />
              }
            />
            <Area
              dataKey="totalMobileClicks"
              type="monotone"
              fill="url(#filltotalMobileClicks)"
              stroke="var(--color-totalMobileClicks)"
              stackId="a"
              name="Mobile"
            />
            <Area
              dataKey="totalDesktopClicks"
              type="monotone"
              fill="url(#filltotalDesktopClicks)"
              stroke="var(--color-totalDesktopClicks)"
              stackId="a"
              name="Desktop"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
