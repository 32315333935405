import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { format, toDate } from "date-fns";
import dayjs from "dayjs";
import {
  CalendarIcon,
  ChevronDown,
  ChevronUp,
  Filter,
  FilterX,
  ListFilter,
  LoaderCircleIcon,
} from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import TitlePage from "../../../components/titlePage";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Card, CardContent } from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { StatusBadge } from "../../../constants";
import { menuTabBooking } from "../../../constants/menu";
import {
  FarsePercentNumber,
  FormatMoneyToFixed,
} from "../../../helpers/formatNumber";
import { TitleOfferBook } from "../../../helpers/title";
import { cn } from "../../../lib/utils";
import { getLinkOffersUser } from "../../../services/link";
import { useInfoUserStore } from "../../../store/user.store";
import { EAffiliateLinkType, EKeyQueryApi } from "../../../types/enum";
import { TYPE_METHOD_BOOKING } from "../../../types/offer";

const OrderBooking = () => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const params = useParams();
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  const sort = searchParams.get("sort") || undefined;
  const type = searchParams.get("type") || "1";
  const affiliateLink = searchParams.get("affiliateLink") || "";
  const [showFilter, setShowFilter] = useState(false);
  const inforUser = useInfoUserStore((state) => state.inforUser);
  const {
    data: {
      data: { isOver, offers, total },
    },
    isFetching,
  } = useQuery({
    queryKey: [
      EKeyQueryApi.keyLinkOffersUser,
      limit,
      skip,
      params?.id || "",
      sort,
      type,
      affiliateLink,
    ],
    queryFn: () =>
      getLinkOffersUser({
        skip: skip,
        limit: limit,
        affiliateLink,
        sort,
        type,
      }),
    initialData: {
      data: {
        offers: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const PriceOfferBook = (data: any) => {
    const dataRequest = data.offeredRequest;
    // const dataOffer = data;
    const dataPayment = data?.price;
    const payment = data?.price;
    const stateOffer = data;
    const endTime = data.endTime;
    const startTime = data.startTime;

    const toMoney = () => {
      let price = 0;
      switch (data?.type) {
        case 2:
          if (data?.contactForm === "meet") {
            price =
              (payment?.cost || stateOffer?.author?.meetPrice?.cost || 0) /
              (stateOffer?.author?.meetPrice?.totalMinutes ||
                payment?.totalMinutes ||
                1);
          } else if (
            data?.contactForm === "call" &&
            stateOffer?.author?.callPrice
          ) {
            price =
              (payment?.cost || stateOffer?.author?.callPrice?.cost || 0) /
              (stateOffer?.author?.callPrice?.totalMinutes ||
                payment?.cost ||
                1);
          } else if (
            data?.contactForm === "show" &&
            stateOffer?.author?.showPrice
          ) {
            price =
              (payment?.cost || stateOffer?.author?.showPrice?.cost || 0) /
              (stateOffer?.author?.showPrice?.totalMinutes ||
                payment?.cost ||
                1);
          }
          break;
        default:
          price = (payment?.cost || 0) / (payment?.totalMinutes || 1);
          break;
      }
      return Math.floor(
        price *
          (dayjs(endTime).diff(dayjs(startTime), "m") ||
            payment?.totalMinutes ||
            60),
      );
    };

    const TimeSelect =
      (endTime && startTime
        ? dayjs(endTime).diff(dayjs(startTime), "m")
        : dataPayment?.totalMinutes) || 0;
    const participants = dataRequest?.participantsCount || 0;
    const priceBook =
      ((dataPayment?.cost || 0) * TimeSelect) /
      (dataPayment?.totalMinutes || 1);
    const serviceFee = participants >= 3 ? priceBook * 1.5 - priceBook : 0;
    // const commissionPercentage =
    //   dataOffer?.commissionPercentage && dataOffer?.commissionPercentage < 1
    //     ? +dataOffer?.commissionPercentage * 100
    //     : dataOffer?.commissionPercentage || 30;
    const beforeFee = toMoney();

    const afterServiceFee = beforeFee + serviceFee;
    const afterTransactionFee = afterServiceFee;

    // const voucherBook = dataOffer?.voucherInstance?.voucher;

    return afterTransactionFee;
  };

  return (
    <>
      <TitlePage title="Trạng thái" />
      <div className="flex flex-wrap items-center justify-between gap-3">
        <Tabs
          defaultValue={`${EAffiliateLinkType.ALL}`}
          className="w-full md:w-fit"
        >
          <ScrollArea className="">
            <TabsList className="flex flex-row gap-3">
              {menuTabBooking.map((item, index) => {
                return (
                  <TabsTrigger
                    onClick={() => {
                      setSearchParams((sp) => {
                        sp.set("type", item.type);
                        return sp;
                      });
                    }}
                    value={item.type}
                    key={index}
                  >
                    {item.nameTab}
                  </TabsTrigger>
                );
              })}
            </TabsList>
            <ScrollBar className="h-0" hidden orientation="horizontal" />
          </ScrollArea>
        </Tabs>
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Bộ lọc
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
      </div>
      <LinkFilter open={showFilter} />
      <div className="mt-4"></div>
      <Card>
        <CardContent className="p-3">
          <Table className="min-w-[1400px] overflow-x-auto">
            <TableHeader>
              <TableRow>
                <TableHead>Id affiliate</TableHead>
                <TableHead>Mã affiliate</TableHead>
                <TableHead>Chuyên gia</TableHead>
                <TableHead>Tên gói dịch vụ</TableHead>
                <TableHead className="text-nowrap">Hình thức</TableHead>
                <TableHead>Giá tiền</TableHead>
                <TableHead className="text-nowrap">Ngày đặt gói</TableHead>
                <TableHead>Trạng thái</TableHead>
                <TableHead className="text-nowrap">
                  Tiền hoa hồng(Tạm tính)
                </TableHead>
                <TableHead>
                  <span className="sr-only">Thao tác</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!isFetching &&
                (offers?.length || 0) > 0 &&
                offers.map((offer) => (
                  <TableRow key={offer._id}>
                    <TableCell className="max-w-[150px] break-all">
                      {offer?.affiliateLink?._id || "N/A"}
                    </TableCell>
                    <TableCell>{offer?.affiliateLink?.code || "N/A"}</TableCell>

                    <TableCell className="font-medium">
                      <div className="flex items-center justify-start gap-2">
                        <Avatar className="size-6">
                          <AvatarImage
                            src={
                              offer?.author?.avatar?.location ||
                              "https://github.com/shadcn.png"
                            }
                            alt="avt"
                          />
                          <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                        <span>{offer.author.fullname}</span>
                      </div>
                    </TableCell>
                    <TableCell>{TitleOfferBook(offer)}</TableCell>
                    <TableCell>
                      {offer?.contactForm === "call"
                        ? TYPE_METHOD_BOOKING.call
                        : offer?.contactForm === "meet"
                          ? TYPE_METHOD_BOOKING.meet
                          : TYPE_METHOD_BOOKING.show}
                    </TableCell>
                    <TableCell>
                      {FormatMoneyToFixed(PriceOfferBook(offer))} vnđ
                    </TableCell>
                    <TableCell>
                      {dayjs(offer.createdAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={offer.status} />
                    </TableCell>

                    {offer.status === 5 || offer.status === 6 ? (
                      <TableCell className="text-center">
                        {FormatMoneyToFixed(
                          PriceOfferBook(offer) *
                            FarsePercentNumber(
                              offer?.commissionPercentageAffiliate ||
                                inforUser?.commissionPercent ||
                                0,
                            ),
                        )}{" "}
                        vnđ
                      </TableCell>
                    ) : (
                      <TableCell className="text-center">Chưa có</TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!isFetching && (offers?.length || 0) <= 0 && <NoData />}
          {isFetching && (
            <div className="grid h-20 w-full place-content-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          )}
        </CardContent>
      </Card>
      <div className="mt-2"></div>
      <Paginate isOver={isOver} total={total} limit={limit} skip={skip} />
    </>
  );
};

export default OrderBooking;

const filterLinkSchema = z.object({
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  affiliateLink: z.string().optional(),
  sort: z.enum(["-1", "1"]).optional(),
});
const LinkFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const affiliateLink = searchParams.get("affiliateLink") || "";
  const sort = (searchParams.get("sort") as "-1" | "1") || undefined;
  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      startTime: startTime ? toDate(startTime) : undefined,
      endTime: endTime ? toDate(endTime) : undefined,
      affiliateLink: affiliateLink,
      sort: sort,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    setSearchParams((sp) => {
      Object.keys(values).forEach((key) => {
        sp.set(key, values[key as keyof typeof values]?.toString() || "");
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={cn("duration-200", !open ? "overflow-hidden" : "mt-5")}
        style={{
          maxHeight: open ? ref.current?.clientHeight : 0,
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 sm:grid-cols-3 md:grid-cols-5"
            ref={ref}
          >
            <FormField
              control={form.control}
              name="affiliateLink"
              render={({ field }) => (
                <FormItem className="mt-auto w-[300px]">
                  <FormLabel>Tìm kiếm</FormLabel>
                  <FormControl>
                    <Input placeholder="Tìm kiếm theo mã link" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="startTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian bắt đầu</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy")
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian kết thúc</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy")
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="mr-2 h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("search");
                    sp.delete("sort");
                    sp.delete("endTime");
                    sp.delete("startTime");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
