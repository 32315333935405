import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { ArrowLeft, Phone } from "lucide-react";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import type { Control, FieldPath } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Button } from "../../../components/ui/button";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "../../../components/ui/input-otp";
import { useToast } from "../../../components/ui/use-toast";
import {
	UserLoginPhoneResponse,
	UserLoginResponse,
	loginUserByPhone,
	loginUserVerifyPhone,
} from "../../../services/affiliate";

interface ILoginPhoneNumber {
	onBack?: () => void;
}
const formPhoneNumber = z.object({
	phone: z.string().min(3, "Vui lòng nhập số điện thoại đăng ký").max(12),
});

const formOtp = z.object({
	otp: z.string().min(6, {
		message: "Vui lòng nhập mã otp",
	}),
});
interface SignupFormFieldProps {
	name: FieldPath<z.infer<typeof formPhoneNumber>>;
	label: string;
	placeholder: string;
	description?: string;
	inputType?: string;
	formControl: Control<z.infer<typeof formPhoneNumber>, any>;
	icon: any;
	focus?: boolean;
}
const LoginPhoneNumber = (props: ILoginPhoneNumber) => {
	const {
		onBack = () => {
			return;
		},
	} = props;
	const { toast } = useToast();
	const navigate = useNavigate();
	const [isFormOtp, setFormOtp] = useState<{ isOpen: boolean; code: string }>({
		isOpen: false,
		code: "",
	});
	const { mutate } = useMutation<
		UserLoginPhoneResponse,
		HTTPError,
		{ phone: string },
		unknown
	>({
		mutationFn: loginUserByPhone,
		async onError(error, variables, context) {
			const res: { message: string; systemCode: string } =
				await error.response.json();
			toast({
				variant: "destructive",
				title: res.message,
			});
		},
		onSuccess(data, variables, context) {
			setFormOtp({ isOpen: true, code: data.data._id });
		},
	});

	const { mutate: mutateOtp } = useMutation<
		UserLoginResponse,
		HTTPError,
		{ otp: string; affiliateId: string },
		unknown
	>({
		mutationFn: loginUserVerifyPhone,
		async onError(error, variables, context) {
			toast({
				variant: "destructive",
				title: "Đăng nhập thất bại",
			});
		},
		onSuccess(data, variables, context) {
			const cookie = new Cookies();
			cookie.set("token", data.data.token);
			cookie.set("permission", JSON.stringify(data.data.permissions));
			cookie.set("id", data.data._id);
			toast({
				variant: "success",
				title: "Đăng nhập thành công",
			});
			setFormOtp({ isOpen: false, code: "" });
			navigate("/");
		},
	});

	const form = useForm<z.infer<typeof formPhoneNumber>>({
		resolver: zodResolver(formPhoneNumber),
		defaultValues: {
			phone: "",
		},
	});

	const formOTP = useForm<z.infer<typeof formOtp>>({
		resolver: zodResolver(formOtp),
		defaultValues: {
			otp: "",
		},
	});

	const onSubmitNumberPhone = (values: z.infer<typeof formPhoneNumber>) => {
		mutate(values);
	};
	const onSubmitOtp = (values: z.infer<typeof formOtp>) => {
		const payload = {
			otp: values.otp,
			affiliateId: isFormOtp.code,
		};
		mutateOtp(payload);
	};

	return (
		<div className="mt-10">
			{isFormOtp.isOpen ? (
				<Form {...formOTP}>
					<form
						onSubmit={formOTP.handleSubmit(onSubmitOtp)}
						className="w-full space-y-6 "
					>
						<FormField
							control={formOTP.control}
							name="otp"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Nhập otp</FormLabel>
									<FormControl>
										<InputOTP
											containerClassName="justify-between"
											maxLength={6}
											{...field}
										>
											<InputOTPGroup>
												<InputOTPSlot index={0} />
												<InputOTPSlot index={1} />
											</InputOTPGroup>
											<InputOTPSeparator />
											<InputOTPGroup>
												<InputOTPSlot index={2} />
												<InputOTPSlot index={3} />
											</InputOTPGroup>
											<InputOTPSeparator />
											<InputOTPGroup>
												<InputOTPSlot index={4} />
												<InputOTPSlot index={5} />
											</InputOTPGroup>
										</InputOTP>
									</FormControl>
									<FormDescription>Vui lòng nhập mã otp</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="text-right">
							<Button className="w-[100px]" type="submit">
								Gửi
							</Button>
						</div>
					</form>
				</Form>
			) : (
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmitNumberPhone)}
						className="space-y-4"
					>
						<SignupFormField
							name="phone"
							label="Số điện thoại"
							placeholder="Vui lòng nhập sđt"
							description=""
							formControl={form.control}
							icon={Phone}
							inputType="string"
							focus
						/>

						<div className="text-end">
							<Button
								className="w-full py-5 rounded-[8px] text-base font-semibold"
								variant="defaultCustom"
								type="submit"
							>
								Tiếp tục
							</Button>
						</div>
					</form>
				</Form>
			)}

			<div
				onClick={onBack}
				className="flex items-center justify-start gap-1 mt-5 text-sm cursor-pointer text-foreground hover:underline w-fit"
			>
				<ArrowLeft className="size-5" />
				<span className="font-medium ">Quay lại</span>
			</div>
		</div>
	);
};

const SignupFormField: React.FC<SignupFormFieldProps> = ({
	name,
	label,
	placeholder,
	description,
	inputType,
	formControl,
	icon,
	focus = false,
}) => {
	return (
		<FormField
			control={formControl}
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel className="text-sm font-semibold text-foreground">
						{label}
					</FormLabel>
					<FormControl>
						<Input
							placeholder={placeholder}
							type={inputType || "text"}
							className="text-base font-medium text-foreground"
							Icon={icon}
							autoFocus={focus}
							{...field}
						/>
					</FormControl>
					{description && <FormDescription>{description}</FormDescription>}
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};

export default LoginPhoneNumber;
