import { NavLink, useLocation } from "react-router-dom";
import { cn } from "../lib/utils";
import { TMenuLink } from "../types/type";

interface MenuLinkProps {
  link: TMenuLink;
}
const MenuLink = ({ link }: MenuLinkProps) => {
  const location = useLocation();
  return (
    <NavLink
      to={link.url}
      className={({ isActive }) => {
        let active = false;
        if (location.pathname === "/admin" && link.url === "/admin") {
          active = true;
        } else {
          active = isActive && link.url !== "/admin";
        }
        return cn(
          "group flex select-none items-center gap-3 rounded-lg p-2 font-medium transition-all duration-300 xl:px-3 xl:py-2.5",
          active
            ? "bg-primary bg-opacity-10 font-medium text-white"
            : "text-neutral-500 hover:bg-primary hover:text-white",
        );
      }}
    >
      <div className="flex h-6 w-9 items-center justify-center rounded-md p-2">
        {link.icon}
      </div>
      <span className="hidden xl:block">{link.title}</span>
    </NavLink>
  );
};

export default MenuLink;
