export const TitleOfferBook = (data: any) => {
  const dataRequest = data.offeredRequest;
  const stateOffer = data;
  const getTitleRequest = (type: number) => {
    switch (type) {
      case 1:
        return `Tư vấn gói dịch vụ "${
          stateOffer?.offeredRequest?.title || ""
        }"`;
      case 2:
        return `Tư vấn trực tiếp với chuyên gia "${
          data?.author?.fullname || ""
        }"`;
      case 3:
        return `Tư vấn gói kỹ năng "${
          data?.offeredRequest?.title &&
          data?.offeredRequest?.title?.split("'")?.length > 1
            ? data?.offeredRequest?.title?.split("'")?.[1]
            : ""
        }"`;
      case 4:
        return `Đặt show trực tiếp thông qua kỹ năng "${
          data?.offeredRequest?.title &&
          data?.offeredRequest?.title?.split("'")?.length > 1
            ? data?.offeredRequest?.title?.split("'")?.[1]
            : ""
        }"`;

      default:
        return `Tư vấn gói dịch vụ "${data?.offeredRequest?.title || ""}"`;
    }
  };
  return getTitleRequest(dataRequest?.type || 0);
};
