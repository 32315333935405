import React from "react";
// import { Helmet } from 'react-helmet';

interface ItitlePage {
	className?: string;
	title?: string;
}

const TitlePage = (props: ItitlePage) => {
	const { className = "text-3xl font-extrabold mb-8", title = "" } = props;
	return (
		<>
			{/* <Helmet>
				<meta charSet="utf-8" />
				<title className={className}>{title}</title>
			</Helmet> */}

			<h1 className={className}>{title}</h1>
		</>
	);
};

export default TitlePage;
