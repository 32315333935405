import { useEffect } from "react";
import { Toaster } from "./components/ui/toaster";
import AppRoutes from "./routes/AppRoutes";

function App() {
	useEffect(() => {
		const loader = document.getElementById('app-loader');
		const root = document.getElementById('root');
		setTimeout(() => {
			if(loader) loader.style.opacity = '0';
			if(root) root.style.opacity = '1';
		}, 0)
	}, [])
	return (
		<>
			<AppRoutes />
			<Toaster />
		</>
	);
}

export default App;
