import { Skeleton } from "../ui/skeleton";

const SkeletonCardTopExpert = ({
  quantity = 3,
  className = "",
}: {
  quantity?: number;
  className?: string;
}) => {
  return (
    <div className={className}>
      {Array(quantity)
        .fill(0)
        .map((_, index) => {
          return (
            <div
              key={index}
              className="flex w-full items-start justify-start space-x-4 rounded-lg border p-4"
            >
              <Skeleton className="h-12 w-12 flex-shrink-0 rounded-full" />
              <div className="w-full space-y-2">
                <Skeleton className="h-4 max-w-[250px]" />
                <Skeleton className="h-4 max-w-[200px]" />
                <Skeleton className="h-4 max-w-[300px]" />
                <Skeleton className="h-4 max-w-[200px]" />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SkeletonCardTopExpert;
