import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { EKeyQueryApi, EStatusBank } from "../../types/enum";
import { TDetailBank } from "../../types/user";
import { Badge } from "../ui/badge";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { updateCardBank } from "../../services/bank.services";
import { Button } from "../ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { Textarea } from "../ui/textarea";
import { toast } from "../ui/use-toast";
interface IFromConfirm {
	onHandle?: () => void;
	dataBank?: Partial<TDetailBank>;
}
const FormConfirmBank = (props: IFromConfirm) => {
	const {
		onHandle = () => {
			return;
		},
		dataBank = {},
	} = props;

	const FormSchema = z.object({
		status: z
			.string({
				required_error: "Vui lòng chọn trạng thái thẻ",
			}),
		reason: z.string(),
	});


	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			status: `${dataBank.status}`,
			reason: dataBank?.reason || "",
		},
	});
	const queryClient = useQueryClient();
	const { mutate } = useMutation<any, HTTPError, any, unknown>({
		mutationFn: updateCardBank,
		async onError(error, variables, context) {
			toast({
				variant: "destructive",
				title: "Cập nhập thất bại",
				duration: 2000,
			});
		},
		onSuccess(data, variables, context) {
			form.reset();
			queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyBank] });
			toast({
				variant: "success",
				title: "Cập nhập thành công",
				duration: 2000,
			});
			onHandle();
		},
	});

	function onSubmit(data: z.infer<typeof FormSchema>) {
		const payload = {
			reason: data?.reason || "",
			status: +data?.status || -1,
			idBank: dataBank?._id || "",
		};
		mutate(payload);
	}
	return (
		<>
			<div className="flex flex-col gap-4 ">
				<div className="flex justify-between items-start gap-4">
					<p className="font-semibold text-nowrap">Họ tên: </p>
					<span>{dataBank?.accountName}</span>
				</div>
				<div className="flex justify-between items-start">
					<p className="font-semibold">Email: </p>
					<span>{dataBank?.email}</span>
				</div>
				<div className="flex justify-between items-start gap-4">
					<p className="font-semibold text-nowrap">Tên ngân hàng: </p>
					<span>{dataBank?.bankName}</span>
				</div>
				<div className="flex justify-between items-start ">
					<p className="font-semibold">Mã ngân hàng: </p>
					<span>{dataBank?.bankCode}</span>
				</div>
				<div className="flex justify-between items-start">
					<p className="font-semibold">Số tài khoản: </p>
					<span>{dataBank?.cardNumber}</span>
				</div>
				<div className="flex justify-between items-start">
					<p className="font-semibold">Mã số thuế: </p>
					<span>{dataBank?.mst}</span>
				</div>
				<div className="flex justify-between items-start">
					<p className="font-semibold">Mã căn cước: </p>
					<span>{dataBank?.cccd}</span>
				</div>
				<div className="flex justify-between items-start">
					<p className="font-semibold">Trạng thái: </p>
					<StatusBadge status={dataBank.status} />
				</div>
			</div>
			<div className="border-t mt-5 pt-3 ">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="w-full space-y-3"
					>
						<FormField
							control={form.control}
							name="status"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Chỉnh sửa trạng thái</FormLabel>
									<Select
										onValueChange={field.onChange}
										defaultValue={field.value}
									>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Chọn trạng thái" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value={`${EStatusBank.Active}`}>
												Duyệt
											</SelectItem>
											<SelectItem value={`${EStatusBank.Reject}`}>
												Từ chối
											</SelectItem>
											<SelectItem value={`${EStatusBank.Deleted}`}>
												Xóa
											</SelectItem>
											<SelectItem value={`${EStatusBank.Pending}`}>
												Chờ duyệt
											</SelectItem>
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="reason"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Lý do từ chối</FormLabel>
									<FormControl>
										<Textarea
											placeholder="nội dung"
											className="resize-none"
											{...field}
										/>
									</FormControl>

									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="text-right">
							<Button type="submit">Cập nhập</Button>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
};
const StatusBadge = ({ status }: { status: any }) => {
	const badge: any = {
		[EStatusBank.Active]: {
			variant: "active",
			text: "Đã duyệt",
		},
		[EStatusBank.Deleted]: {
			variant: "reject",
			text: "Đã xóa",
		},
		[EStatusBank.Pending]: {
			variant: "pending",
			text: "Chờ xét duyệt",
		},
		[EStatusBank.Reject]: {
			variant: "reject",
			text: "Từ chối",
		},
	};
	return <Badge variant={badge[status]?.variant}>{badge[status]?.text}</Badge>;
};
export default FormConfirmBank;
