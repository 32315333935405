import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import {
  CircleAlert,
  CircleCheck,
  Edit,
  Loader2,
  PenSquareIcon,
  Settings,
  TriangleAlert,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CroppedModal from "../../../components/CroppAvatar";
import FormChangePhoneUser from "../../../components/form/formChangePhoneUser";
import FormCreatCardBank from "../../../components/form/formCreateCardBank";
import FormEditProfile from "../../../components/form/formEditProfile";
import FormResetPassword from "../../../components/form/formResetPassword";
import ModalContainer from "../../../components/modal/ModalContainer";
import ModalHandleContainer from "../../../components/modalHandle/ModalContainer";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { toast } from "../../../components/ui/use-toast";
import WidgetTile from "../../../components/widgetTitle";
import {
  deleteCardBankUser,
  getInfoBankUser,
} from "../../../services/bank.services";
import { useBankStore } from "../../../store/bank.store";
import { useInfoUserStore } from "../../../store/user.store";
import { EActionForm, EKeyQueryApi, EStatusBank } from "../../../types/enum";

const InfoUser = () => {
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [openCardBank, setOpenCardBank] = useState<{
    open: boolean;
    statusForm: EActionForm;
  }>({ open: false, statusForm: EActionForm.ADD });
  const [isResetPassword, setResetPassword] = useState<boolean>(false);
  const inforUser = useInfoUserStore((state) => state.inforUser);
  const getInfoBankAccount = useBankStore((state) => state.getInfoBankAccount);
  const infoBankUser = useBankStore((state) => state.infoBankUser);
  const [isDeleteBank, setDeleteBank] = useState<boolean>(false);
  const [isChangePhone, setChangePhone] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const getDtailBank = async () => {
    setLoading(true);
    const result = await getInfoBankUser();
    getInfoBankAccount(result.data);
    setLoading(false);
    return result.data;
  };

  useQuery({
    queryKey: [EKeyQueryApi.keyBank],
    queryFn: () => getDtailBank(),
    retry: false,
  });

  const getListCardBank = useBankStore((state) => state.getListCardBank);
  useEffect(() => {
    getListCardBank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const listBank: any = useBankStore((state) => state.listBank);
  const indexExist = listBank?.findIndex((item: any) => {
    return item.code === infoBankUser?.bankCode;
  });

  const renderStatusCard = (status: number) => {
    switch (status) {
      case EStatusBank.Reject:
        return (
          <div>
            <div className="my-4 flex max-w-[400px] items-start justify-start gap-2 rounded-tr-sm border-l-4 border-red-500 bg-red-500/10 px-2.5 py-2 text-sm font-medium text-red-500 backdrop-opacity-10 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
              <CircleAlert className="size-5" />
              <div>
                <p>Thẻ ngân hàng của bạn đã bị từ chối</p>
                <p className="mt-2 text-sm font-semibold">
                  Lý do: {inforUser?.bank?.reason || ""}{" "}
                </p>
              </div>
            </div>
          </div>
        );
      case EStatusBank.Pending:
        return (
          <div className="my-4 flex max-w-[400px] items-start justify-start gap-2 rounded-tr-sm border-l-4 border-yellow-500 bg-yellow-500/10 px-2.5 py-2 text-sm font-medium text-yellow-500 backdrop-opacity-10 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
            <TriangleAlert className="size-5 flex-shrink-0" />
            <span className="">Thẻ ngân hàng của bạn đang được xét duyệt</span>
          </div>
        );

      default:
        return (
          <div className="my-4 flex max-w-[400px] items-start justify-start gap-2 rounded-tr-sm border-l-4 border-green-500 bg-green-500/10 px-2.5 py-2 text-sm font-medium text-green-500 backdrop-opacity-10 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2">
            <CircleCheck className="size-5 flex-shrink-0" />
            <p>Thẻ ngân hàng của bạn đã được phê duyệt</p>
          </div>
        );
    }
  };

  return (
    <>
      <div className="hidden rounded-lg border bg-card text-card-foreground shadow-sm md:block">
        <div
          style={{
            backgroundImage: `url("https://s3.ap-southeast-1.amazonaws.com/askany.dev/files/d9936911-df97-48aa-8455-65d48588e038.png")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="block h-[180px] rounded-t-[10px]"
        ></div>

        <div className="flex w-full items-start justify-start gap-5 p-5">
          <CroppedModal>
            <Avatar className="rstablet:w-[150px] rstablet:border-4 flex size-20 -translate-y-16 cursor-pointer overflow-hidden rounded-full border border-white bg-background duration-300 md:size-36">
              <AvatarImage
                src={inforUser.avatar?.location}
                alt={inforUser.fullname}
              />
              <AvatarFallback className="uppercase">
                {inforUser.fullname
                  ?.split(" ")
                  .map((s) => s[0])
                  .join("")}
              </AvatarFallback>
            </Avatar>
          </CroppedModal>
          <div className="flex flex-wrap items-center justify-between gap-5">
            <div>
              <div className="flex items-center justify-start gap-2">
                <span className="text-[17px] font-medium">
                  {inforUser?.fullname || ""}
                </span>

                <img
                  className="size-4"
                  src="/icons/ic_check_verify.png"
                  alt="verify"
                />
              </div>
              <span className="text-sm text-foreground">
                {inforUser?.username || ""}
              </span>

              <div className="mt-5 flex items-center justify-start gap-3">
                {!!inforUser?.facebook && (
                  <Link
                    to={inforUser?.facebook || "/"}
                    className="flex items-center gap-3"
                  >
                    <img
                      className="size-8"
                      src="/icons/ic_facebook.png"
                      alt=""
                    />
                  </Link>
                )}
                {!!inforUser?.tiktok && (
                  <Link
                    to={inforUser?.tiktok || "/"}
                    className="flex items-center gap-3"
                  >
                    <img className="size-8" src="/icons/ic_tiktok.png" alt="" />
                  </Link>
                )}
                {!!inforUser?.instagram && (
                  <Link
                    to={inforUser?.instagram || "/"}
                    className="flex items-center gap-3"
                  >
                    <img
                      className="size-8"
                      src="/icons/ic_instagram.png"
                      alt=""
                    />
                  </Link>
                )}
              </div>
            </div>
            <div></div>
            <Button onClick={() => setModalEdit(true)} variant="default">
              <Edit className="mr-2 h-5 w-5" /> Chỉnh sửa thông tin
            </Button>
            <Button onClick={() => setResetPassword(true)} variant="outline">
              <Settings className="mr-2 h-5 w-5" /> Đổi mật khẩu
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-5 rounded-[10px] bg-card py-8 md:hidden">
        <div className="relative">
          <Avatar className="relative size-28 rounded-full border">
            <AvatarImage
              src={inforUser.avatar?.location}
              alt={inforUser.fullname}
            />
            <AvatarFallback>
              {inforUser.fullname
                ?.split(" ")
                .map((s) => s[0])
                .join("")}
            </AvatarFallback>
          </Avatar>
          <CroppedModal>
            <div className="absolute bottom-0 right-0 size-7 rounded-full bg-primary p-2 text-white">
              <PenSquareIcon className="h-full w-full" />
            </div>
          </CroppedModal>
        </div>
        <div>
          <p className="text-center text-[17px] font-medium">
            {inforUser.fullname}
          </p>

          <p className="text-center text-gray-500">@KOL</p>
        </div>
        <Button onClick={() => setModalEdit(true)} variant="defaultCustom">
          <Edit className="mr-2 h-4 w-4" /> Chỉnh sửa thông tin
        </Button>
        <Button onClick={() => setResetPassword(true)} variant="outline">
          <Settings className="mr-2 h-5 w-5" /> Đổi mật khẩu
        </Button>
      </div>

      <div className="mt-3 rounded-lg py-6">
        <div className="border-neutral-300 dark:border-neutral-700">
          <WidgetTile title="Ngân hàng" />

          {!!infoBankUser && renderStatusCard(infoBankUser?.status || 1)}

          {loading ? (
            <div className="max-w-[400px] cursor-pointer gap-x-[20px] rounded-[8px] border-2 bg-card p-[24px] text-foreground">
              <div className="flex animate-pulse space-x-4">
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-2 rounded bg-slate-700"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="col-span-2 h-2 rounded bg-slate-700"></div>
                      <div className="col-span-1 h-2 rounded bg-slate-700"></div>
                    </div>
                    <div className="h-2 rounded bg-slate-700"></div>
                    <div className="h-2 rounded bg-slate-700"></div>
                    <div className="h-2 rounded bg-slate-700"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : !!infoBankUser?._id ? (
            <div className="max-w-[400px]">
              <div className="cursor-pointer gap-x-[20px] rounded-[8px] border-2 bg-card p-[24px] text-foreground">
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <img
                      className="w-28 flex-none object-contain"
                      src={listBank[indexExist || 0]?.logo}
                      alt="bank"
                    />
                    <p className="block text-[22px] font-bold leading-[30px]">
                      {listBank[indexExist || 0]?.shortName}
                      {/* {infoBankUser?.bankCode} */}
                    </p>
                  </div>
                  <div>
                    <StatusBadge status={infoBankUser.status} />
                  </div>
                </div>
                <div className="mt-[20px]">
                  <div className="flex flex-col justify-center gap-[5px]">
                    <p className="text-[14px] leading-[24px]">
                      Số thẻ / tài khoản{" "}
                    </p>
                    <p className="text-grey-2 text-16px font-bold uppercase">
                      {infoBankUser?.accountName}
                    </p>
                    <p className="text-grey-2 text-16px font-bold">
                      {`**** **** **** ${infoBankUser.cardNumber.slice(
                        12,
                        16,
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
              {inforUser?.bank?.status !== EStatusBank.Active && (
                <div className="mt-3 flex items-center justify-end gap-3">
                  <Button
                    type="button"
                    onClick={() => setDeleteBank(true)}
                    variant="outline"
                  >
                    Xóa thẻ
                  </Button>
                  <Button
                    type="button"
                    onClick={() =>
                      setOpenCardBank({
                        open: true,
                        statusForm: EActionForm.EDIT,
                      })
                    }
                  >
                    Chỉnh sửa
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-wrap items-center gap-5">
              <span className="inline-block text-sm font-semibold">
                Bạn chưa bổ sung thông tin thẻ ngân hàng, vui lòng nhấn tạo ?
              </span>
              <Button
                onClick={() =>
                  setOpenCardBank({ statusForm: EActionForm.ADD, open: true })
                }
                variant="default"
              >
                Tạo thẻ
              </Button>
            </div>
          )}
        </div>
      </div>
      {modalEdit && (
        <ModalHandleContainer
          isVisible={modalEdit}
          closeModal={() => setModalEdit(false)}
          titleModal="Cập nhật tài khoản"
        >
          <FormEditProfile
            onHandle={() => setModalEdit(false)}
            onChangePhone={() => {
              setChangePhone(true);
              setModalEdit(false);
            }}
            dataUser={inforUser}
          />
        </ModalHandleContainer>
      )}
      {isChangePhone && (
        <ModalHandleContainer
          isVisible={isChangePhone}
          closeModal={() => setChangePhone(false)}
          titleModal="Thay đổi số điện thoại"
        >
          <FormChangePhoneUser
            onBack={() => {
              setModalEdit(true);
              setChangePhone(false);
            }}
            onHandle={() => {
              setChangePhone(false);
            }}
          />
        </ModalHandleContainer>
      )}
      {isDeleteBank && (
        <ModalDeleteBank
          open={isDeleteBank}
          onClose={() => setDeleteBank(false)}
          idBank={inforUser?.bank?._id}
        />
      )}

      {openCardBank.open && (
        <ModalHandleContainer
          isVisible={openCardBank.open}
          closeModal={() =>
            setOpenCardBank({ statusForm: EActionForm.ADD, open: false })
          }
          titleModal={
            openCardBank.statusForm === EActionForm.ADD
              ? "Thêm thẻ"
              : "Chỉnh sửa thẻ"
          }
        >
          <FormCreatCardBank
            onHandle={() =>
              setOpenCardBank({ statusForm: EActionForm.ADD, open: false })
            }
            actionForm={openCardBank.statusForm}
            dataUser={inforUser}
            dataBank={inforUser?.bank}
          />
        </ModalHandleContainer>
      )}
      {isResetPassword && (
        <ModalHandleContainer
          isVisible={isResetPassword}
          closeModal={() => setResetPassword(false)}
          titleModal="Đổi mật khẩu"
        >
          <FormResetPassword onHandle={() => setResetPassword(false)} />
        </ModalHandleContainer>
      )}
    </>
  );
};

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    [EStatusBank.Active]: {
      variant: "active",
      text: "Đã duyệt",
    },
    [EStatusBank.Deleted]: {
      variant: "reject",
      text: "Đã xóa",
    },
    [EStatusBank.Pending]: {
      variant: "pending",
      text: "Chờ xét duyệt",
    },
    [EStatusBank.Reject]: {
      variant: "reject",
      text: "Từ chối",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="">
      {badge[status]?.text}
    </Badge>
  );
};

const ModalDeleteBank = ({
  onClose = () => {
    return;
  },
  open = false,
  idBank = "",
}: {
  onClose?: () => void;
  open?: boolean;
  idBank?: string;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteCardBankUser,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xóa thẻ thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyUser] });
      toast({
        variant: "success",
        title: "Xóa thẻ thành công",
        duration: 2000,
      });
      // setOpen(false);
      onClose();
    },
  });
  return (
    <>
      <ModalContainer
        isVisible={open}
        closeModal={onClose}
        titleModal="Xóa thẻ"
      >
        <div>
          <p>Bạn có muốn xóa thẻ hiện tại không?</p>
          <div className="mt-10 flex items-center justify-end gap-5">
            <Button onClick={onClose} className="px-10" variant="ghost">
              Hủy
            </Button>
            <Button
              disabled={isPending}
              onClick={() => mutate(idBank)}
              className="px-5"
              variant={"destructive"}
            >
              {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Tiếp tục
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default InfoUser;
