"use client";
// import TitlePage from "../../components/titlePage";
// import { EyeIcon } from '@heroicons/react/24/outline';
import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { Card } from "../../../components/ui/card";
import { ROLE } from "../../../constants/roles.constants";
import { getReferencePopulars } from "../../../services/affiliateService/referencePopulars.services";
import { EKeyQueryApi } from "../../../types/enum";
import { ChartTrackingAllAffiliate } from "./components/ChartTrackingAll";
import DashboardCard from "./components/DashboardCard";
import DashboardHistoryPayment from "./components/DashboardHistoryPayment";
import ExpertTrendingUser from "./components/ExpertTrending";
import TopLinkTracking from "./components/TopLinkTracking";

const HomeAffiliate = () => {
  const { data: dataTopTrending, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyPopular],
    queryFn: () => getReferencePopulars(),
    retry: false,
  });

  const listPopular: any = dataTopTrending?.data || [];

  const role = sessionStorage.getItem("role");
  if (role === ROLE.ADMIN) {
    return <Navigate to={"/admin"} />;
  }

  return (
    <div className="space-y-10">
      {(listPopular?.length || 0) > 0 && (
        <>
          <div className="flex items-center gap-x-3">
            <div className="h-8 w-4 rounded-[4px] bg-primary py-1"></div>
            <p className="text-3xl font-semibold text-neutral-700 dark:text-gray-50">
              Top chuyên gia nổi bật
            </p>
          </div>
          <div>
            <ExpertTrendingUser isLoading={isFetching} data={listPopular} />
          </div>
        </>
      )}

      <div className="flex flex-wrap items-center justify-between gap-5">
        <div className="flex items-center gap-x-3">
          <div className="h-8 w-4 rounded-[4px] bg-primary py-1"></div>
          <p className="text-3xl font-semibold text-neutral-700 dark:text-gray-50">
            Thống kê
          </p>
        </div>
        {/* <CalendarDateRangePicker /> */}
      </div>
      <DashboardCard />

      <ChartTrackingAllAffiliate />
      <div className="mt-8 grid gap-4 md:grid-cols-2 lg:grid-cols-7">
        <DashboardHistoryPayment />
        <Card className="col-span-4">
          <TopLinkTracking />
        </Card>
      </div>
      {/* <RecentOffer />  */}
      <div className="mt-8"></div>
    </div>
  );
};

export default HomeAffiliate;
