import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ArrowUpRight, ClipboardIcon, MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { StatusBadgeLink } from "../../../../constants";
import { getTopTracking_Admin } from "../../../../services/adminService/tracking";
import { useCopyToClipboard } from "../../../../hooks/useCopy";
import { toast } from "../../../../components/ui/use-toast";

const TopLinkTracking = () => {
  const { data: result } = useQuery({
    queryKey: ["topTracking"],
    queryFn: () => getTopTracking_Admin(),
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const getTop = result?.data || [];

  const [, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        toast({
          title: "Copy thành công",
          description: text,
        });
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };
  return (
    <div>
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader className="flex flex-row items-center">
          <div className="grid gap-2">
            <CardTitle>Top link affiliate</CardTitle>
          </div>
          <Button asChild size="sm" className="ml-auto gap-1">
            <Link to="/admin/manager-link">
              Xem tất cả
              <ArrowUpRight className="h-4 w-4" />
            </Link>
          </Button>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="hidden text-left lg:table-cell">
                  Người tạo
                </TableHead>
                <TableHead>Mã link</TableHead>
                <TableHead className="hidden 2xl:table-cell">
                  Trạng thái
                </TableHead>
                <TableHead className="text-nowrap text-center">
                  Desktop
                </TableHead>
                <TableHead className="text-center">Mobile</TableHead>
                <TableHead className="text-nowrap text-center">
                  Lượt booking
                </TableHead>
                <TableHead className="text-nowrap text-center">
                  Hoàn thành
                </TableHead>
                <TableHead className="hidden text-nowrap md:table-cell">
                  Ngày tạo
                </TableHead>
                <TableHead>
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {(getTop?.length || 0) > 0 &&
                getTop.slice(0, 5).map((item: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="hidden items-center justify-start gap-3 lg:flex">
                        <div className="flex-shrink-0">
                          <img
                            src={
                              item?.affiliateLink?.affiliate?.avatar
                                ?.location || "https://github.com/shadcn.png"
                            }
                            alt="avt"
                            className="size-10 rounded-full object-cover"
                          />
                        </div>
                        <span className="line-clamp-1">
                          {item?.affiliateLink?.affiliate?.fullname || "N/A"}
                        </span>
                      </TableCell>

                      <TableCell>
                        <div
                          onClick={() => {
                            if (!!item.affiliateLink?.reference) {
                              handleCopy(
                                `${process.env.REACT_APP_LINK_AFFILIATE}/${item.affiliateLink._id}?expert=${
                                  item.affiliateLink?.reference?.expertname ||
                                  ""
                                }`,
                              );
                              return;
                            }
                            handleCopy(
                              `${process.env.REACT_APP_LINK_AFFILIATE}/${item.affiliateLink._id}`,
                            );
                          }}
                          className="flex cursor-pointer items-center justify-start gap-1"
                        >
                          <span>{item?.affiliateLink?.code}</span>
                          <ClipboardIcon className="h-4 w-4" />
                        </div>
                      </TableCell>
                      <TableCell className="hidden font-medium 2xl:table-cell">
                        <StatusBadgeLink
                          status={item.affiliateLink?.status || 0}
                        />
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalDesktopClick}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalMobileClick}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalBook}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.affiliateLink?.totalBookSuccess}
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {dayjs(item?.affiliateLink?.createdAt || "").format(
                          "DD-MM-YYYY ",
                        )}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              aria-haspopup="true"
                              size="icon"
                              variant="ghost"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">Toggle menu</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem>
                              <Link
                                to={`/admin/manager-link/${item?.affiliateLink?.affiliate?._id}?percent=${item?.affiliateLink?.affiliate?.commissionPercent || 0}`}
                              >
                                Chi tiết
                              </Link>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CardContent>
        {/* <CardFooter>
          <div className="text-xs text-muted-foreground">
            Showing <strong>1-10</strong> of <strong>32</strong> products
          </div>
        </CardFooter> */}
      </Card>
    </div>
  );
};

export default TopLinkTracking;
