import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { LoaderCircleIcon } from "lucide-react";
import { useParams, useSearchParams } from "react-router-dom";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import { Badge } from "../../../components/ui/badge";
import { Card, CardContent } from "../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { FormatMoneyToFixed } from "../../../helpers/formatNumber";
import { getTransactionsAdmin_Admin } from "../../../services/adminTransactions";
import { EKeyQueryApi } from "../../../types/enum";

const ManagerPayment = () => {
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const { affiliateId } = useParams();
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");

  const {
    data: {
      data: { isOver, listData, total },
    },
    isFetching,
  } = useQuery({
    queryKey: [EKeyQueryApi.keyLinkOffers, limit, skip, affiliateId],
    queryFn: () =>
      getTransactionsAdmin_Admin({
        skip: skip,
        limit: limit,
        affiliateId,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  return (
    <>
      <div className="flex items-center">
        <h2 className="text-2xl font-bold tracking-tight">
          Danh sách giao dịch
        </h2>
      </div>
      {/* <div className="flex items-center gap-2 my-5">
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
      </div>
      <div>
        <LinkFilter open={showFilter} />
      </div> */}

      <div className="mt-4"></div>
      <Card className="min-w-full overflow-x-auto">
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Mã giao dịch</TableHead>
                <TableHead>Người chuyển</TableHead>
                <TableHead>Số tiền đã chuyển</TableHead>
                <TableHead>trạng thái</TableHead>
                <TableHead>Thời gian</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!isFetching &&
                listData?.map((transaction: any) => (
                  <TableRow key={transaction._id} className="font-medium">
                    <TableCell className="font-medium">
                      {transaction.orderID}
                    </TableCell>
                    <TableCell>CÔNG TY TNHH NDN</TableCell>

                    <TableCell>
                      {FormatMoneyToFixed(transaction.amount)} vnđ
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={transaction.status} />
                    </TableCell>

                    <TableCell>
                      {dayjs(transaction.createdAt).format(
                        "DD/MM/YYYY - HH:mm ",
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>

        {!isFetching && listData.length <= 0 && <NoData />}
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </Card>
      <div className="mt-2"></div>
      <Paginate isOver={isOver} total={total} limit={limit} skip={skip} />
    </>
  );
};

export default ManagerPayment;

// const filterLinkSchema = z.object({
//   status: z.string().optional(),
//   type: z.string().optional(),
// });

// const LinkFilter = ({ open = true }: { open?: boolean }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const status = searchParams.get("status") || "";
//   const type = searchParams.get("type") || "";

//   const form = useForm<z.infer<typeof filterLinkSchema>>({
//     resolver: zodResolver(filterLinkSchema),
//     defaultValues: {
//       status: status,
//       type: type,
//     },
//   });
//   const ref = useRef<HTMLFormElement>(null);
//   const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
//     setSearchParams((sp) => {
//       Object.keys(values).forEach((key) => {
//         sp.set(key, values[key as keyof typeof values]?.toString() || "");
//       });
//       return sp;
//     });
//   };

//   return (
//     <>
//       <div
//         className={cn("duration-200", !open ? "overflow-hidden" : "my-5")}
//         style={{
//           maxHeight: open ? ref.current?.clientHeight : 0,
//         }}
//       >
//         <Form {...form}>
//           <form
//             onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
//             className="grid gap-4 grid-cold-1 sm:grid-cols-3 md:grid-cols-5"
//             ref={ref}
//           >
//             <FormField
//               control={form.control}
//               name="type"
//               render={({ field }) => (
//                 <FormItem className="mt-auto">
//                   <FormLabel>Type</FormLabel>
//                   <Select onValueChange={field.onChange}>
//                     <FormControl>
//                       <SelectTrigger>
//                         <SelectValue defaultValue={field.value} />
//                       </SelectTrigger>
//                     </FormControl>
//                     <SelectContent>
//                       <SelectItem value="1">1</SelectItem>
//                       <SelectItem value="2">2</SelectItem>
//                       <SelectItem value="3">3</SelectItem>
//                       <SelectItem value="4">4</SelectItem>
//                     </SelectContent>
//                   </Select>
//                   <FormMessage />
//                 </FormItem>
//               )}
//             />

//             <FormField
//               control={form.control}
//               name="status"
//               render={({ field }) => (
//                 <FormItem className="mt-auto">
//                   <FormLabel>Status</FormLabel>
//                   <Select onValueChange={field.onChange}>
//                     <FormControl>
//                       <SelectTrigger>
//                         <SelectValue defaultValue={field.value} />
//                       </SelectTrigger>
//                     </FormControl>
//                     <SelectContent>
//                       <SelectItem value="1">1</SelectItem>
//                       <SelectItem value="-1">-1</SelectItem>
//                     </SelectContent>
//                   </Select>
//                   <FormMessage />
//                 </FormItem>
//               )}
//             />
//             <div className="flex w-full gap-2 mt-auto">
//               <Button type="submit" className="w-full">
//                 <Filter className="h-3.5 w-3.5" />
//                 <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
//                   Lọc
//                 </span>
//               </Button>
//               <Button
//                 className="w-full gap-1"
//                 type="button"
//                 variant="destructive"
//                 onClick={() => {
//                   setSearchParams((sp) => {
//                     sp.delete("affiliateLink");
//                     sp.delete("sort");
//                     sp.delete("type");
//                     return sp;
//                   });
//                   form.reset();
//                 }}
//               >
//                 <FilterX className="h-3.5 w-3.5" />
//                 <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
//                   Xóa lọc
//                 </span>
//               </Button>
//             </div>
//           </form>
//         </Form>
//       </div>
//     </>
//   );
// };

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    "1": {
      variant: "active",
      text: "Đã chuyển",
    },
    "2": {
      variant: "pending",
      text: "Đang xử lý",
    },
  };
  return <Badge variant={badge[status]?.variant}>{badge[status]?.text}</Badge>;
};
