import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, toDate } from "date-fns";
import { vi } from "date-fns/locale";
import dayjs from "dayjs";
import { HTTPError } from "ky";
import {
  CalendarIcon,
  Check,
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  ClipboardIcon,
  Eye,
  Filter,
  FilterX,
  ListFilter,
  LoaderCircleIcon,
  PenLine,
  PlusCircle,
  Trash,
} from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import Paginate from "../../../components/Paginate";
import TooltipContainer from "../../../components/TooltipContainer";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Calendar } from "../../../components/ui/calendar";
import { Card } from "../../../components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  ModalDrawer,
  ModalDrawerBody,
  ModalDrawerContent,
  ModalDrawerFooter,
  ModalDrawerHeader,
  ModalDrawerTitle,
  ModalDrawerTrigger,
} from "../../../components/ui/modal-drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { toast, useToast } from "../../../components/ui/use-toast";
import { ROLE } from "../../../constants/roles.constants";
import { useCopyToClipboard } from "../../../hooks/useCopy";
import useUserData from "../../../hooks/useUserData";
import { cn } from "../../../lib/utils";
import {
  createLinkAdmin,
  deleteLinkAdmin,
  editLinkAdmin,
  getLinksAdmin,
  getListUser,
} from "../../../services/adminAffiliates.service";
import { CreateLinkRequest, EditLinkRequest } from "../../../services/link";
import { EKeyQueryApi } from "../../../types/enum";
import ReferenceField from "../../affiliate/LinkAffiliate/ReferenceField";
import LinkOfferAffiliate from "./services";

export default function ManagerAffiliateLink() {
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
    }),
  );
  const { affiliateId } = useParams();

  const skip = parseInt(searchParams.get("skip") || "0");
  const percent = searchParams.get("percent") || "0";
  const limit = parseInt(searchParams.get("limit") || "5");
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const status = searchParams.get("status") || "";
  const affiliateIdLink = searchParams.get("affiliateId") || "";

  const { data: result, isFetching } = useQuery({
    queryKey: [
      EKeyQueryApi.keyLinkAdmin,
      limit,
      skip,
      startTime,
      endTime,
      status,
    ],
    queryFn: () =>
      getLinksAdmin({
        skip: skip,
        limit: limit,
        startTime: startTime,
        endTime: endTime,
        status: status,
        affiliateId: affiliateId,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const linkList = result?.data;

  const [, copy] = useCopyToClipboard();
  const handleCopy = (text: string) => {
    copy(text)
      .then(() => {
        toast({
          title: "Copy thành công",
          description: text,
        });
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };

  if (!!affiliateIdLink) return <LinkOfferAffiliate />;

  return (
    <>
      <div className="flex items-center">
        <h2 className="text-2xl font-bold tracking-tight">Quản lý link</h2>
      </div>
      <div className="my-5 flex items-center gap-2">
        <Button
          size="sm"
          variant={showFilter ? "default" : "outline"}
          className="h-8 gap-1"
          onClick={() => setShowFilter((s) => !s)}
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
          {showFilter ? (
            <ChevronDown className="h-3.5 w-3.5" />
          ) : (
            <ChevronUp className="h-3.5 w-3.5" />
          )}
        </Button>
        <AddLinkForm />
      </div>
      <div>
        <LinkFilter open={showFilter} />
      </div>
      <div className="mt-4"></div>
      <Card>
        <Table className="min-w-[1400px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Tên link</TableHead>
              <TableHead>Code</TableHead>
              <TableHead className="text-center">Trạng thái</TableHead>
              <TableHead className="text-center">Click Desktop</TableHead>
              <TableHead className="text-center">Click Mobile</TableHead>
              <TableHead className="text-center">Lượt booking</TableHead>
              <TableHead className="text-center">Booking hoàn thành</TableHead>
              <TableHead>Ngày tạo</TableHead>
              <TableHead>Thao tác</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              linkList?.listData.map((link) => (
                <TableRow key={link._id}>
                  <TableCell className="flex items-center justify-start gap-2 font-medium">
                    <span>{link.name}</span>
                    {link.status !== -1 && (
                      <TooltipContainer content="copy link">
                        <Button
                          onClick={() => {
                            if (!!link?.reference) {
                              handleCopy(
                                `${process.env.REACT_APP_LINK_AFFILIATE}/${link._id}?expert=${
                                  link?.reference?.expertname || ""
                                }`,
                              );
                              return;
                            }
                            handleCopy(
                              `${process.env.REACT_APP_LINK_AFFILIATE}/${link._id}`,
                            );
                          }}
                          type="button"
                          variant="ghost"
                        >
                          <ClipboardIcon className="h-4 w-4" />
                        </Button>
                      </TooltipContainer>
                    )}
                  </TableCell>
                  <TableCell className="max-w-[300px]">
                    <span className="line-clamp-2">{link?.code}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <StatusBadge status={link?.status || 0} />
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalDesktopClick || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalMobileClick || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalBook || 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {link?.totalBookSuccess || 0}
                  </TableCell>
                  <TableCell>
                    {dayjs(link?.createdAt || "").format("HH:mm DD/MM/YYYY")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <TooltipContainer content="Xem chi tiết">
                      <Link to={`?affiliateId=${link?._id}&percent=${percent}`}>
                        <Button variant="outlineCustom">
                          <Eye className="size-4" />
                        </Button>
                      </Link>
                    </TooltipContainer>
                    <EditLinkForm link={link} />
                    <DeleteLinkDialog link={link} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!isFetching && linkList?.listData.length <= 0 && (
          <div className="my-10 w-full text-center">Không tìm thấy kết quả</div>
        )}
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}

        {/* <CardFooter>
					{!isFetching && (
						<div className="text-xs text-muted-foreground">
							Đang hiện{" "}
							<strong>
								{skip + 1}-{skip + (linkList?.listData?.length || limit)}
							</strong>{" "}
							trong tổng <strong>{linkList?.total}</strong> dịch vụ
						</div>
					)}
				</CardFooter> */}
      </Card>
      {!isFetching && (
        <div className="mt-2">
          <Paginate
            isOver={linkList.isOver}
            total={linkList.total}
            limit={limit}
            skip={skip}
          />
        </div>
      )}
    </>
  );
}

const formLinkSchema = z.object({
  affiliate: z.string().min(1, "Vui lòng nhập affiliate"),
  code: z.string().min(1, "Vui lòng nhập code"),
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
});

const AddLinkForm = () => {
  const [userData] = useUserData();
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    CreateLinkRequest,
    unknown
  >({
    mutationFn: createLinkAdmin,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Tạo link thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyLinkAdmin],
      });
      toast({
        variant: "success",
        title: "Tạo link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });
  const [q, setQ] = useState("");

  const {
    data: {
      data: { listData: listUser },
    },
  } = useQuery({
    queryKey: [EKeyQueryApi.keyListUser, q],
    queryFn: () =>
      getListUser({
        skip: 0,
        limit: 10,
        q: q,
      }),

    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
    enabled: open,
  });

  const form = useForm<z.infer<typeof formLinkSchema>>({
    resolver: zodResolver(formLinkSchema),
    defaultValues: {
      description: "",
      name: "",
      affiliate: userData?.role === ROLE.USER ? userData?._id : "",
      code: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formLinkSchema>) => {
    mutate(values);
  };

  return (
    <ModalDrawer open={open} onOpenChange={setOpen}>
      <ModalDrawerTrigger asChild>
        <Button size="sm" className="h-8 gap-1">
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Tạo link
          </span>
        </Button>
      </ModalDrawerTrigger>
      <ModalDrawerContent className="sm:max-w-[425px]">
        <ModalDrawerHeader>
          <ModalDrawerTitle>Tạo link</ModalDrawerTitle>
        </ModalDrawerHeader>
        <ModalDrawerBody>
          <Form {...form}>
            <form
              className="mt-5 space-y-4"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="affiliate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Affiliate</FormLabel>
                    <Popover modal>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "w-full justify-between",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value
                              ? listUser?.find(
                                  (user) => user._id === field.value,
                                )?.fullname
                              : "Select language"}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="PopoverContent p-0">
                        <Command>
                          <CommandInput
                            placeholder="Tìm kiếm..."
                            value={q}
                            onValueChange={setQ}
                          />
                          <CommandEmpty>Không tìm thấy người dùng</CommandEmpty>
                          <CommandList>
                            {listUser?.map((user: any) => (
                              <CommandItem
                                value={user.fullname}
                                key={user._id}
                                onSelect={() => {
                                  form.setValue("affiliate", user._id);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    user._id === field.value
                                      ? "opacity-100"
                                      : "opacity-0",
                                  )}
                                />
                                {user.fullname}
                              </CommandItem>
                            ))}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {userData?.role === ROLE.ADMIN ? (
                <FormField
                  control={form.control}
                  name="affiliate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Affiliate</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <></>
              )}
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ModalDrawerBody>
        <ModalDrawerFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit)}
          >
            Tạo
          </Button>
        </ModalDrawerFooter>
      </ModalDrawerContent>
    </ModalDrawer>
  );
};

const formEditLinkSchema = z.object({
  name: z.string().min(1, "Vui lòng nhập tên link"),
  description: z.string(),
  reference: z.string().optional(),
});

const EditLinkForm = ({ link }: any) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const haveReference = !!link.reference;
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    EditLinkRequest,
    unknown
  >({
    mutationFn: editLinkAdmin,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyLinkAdmin],
      });
      toast({
        variant: "success",
        title: "Chỉnh sửa link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });

  const form = useForm<z.infer<typeof formEditLinkSchema>>({
    resolver: zodResolver(formEditLinkSchema),
    defaultValues: {
      description: link.description,
      name: link.name,
    },
  });

  const onSubmit = (values: z.infer<typeof formEditLinkSchema>) => {
    if (haveReference) {
      mutate({ id: link._id, ...values });
    } else {
      const { reference, ...payload } = values;
      mutate({ id: link._id, ...payload });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <TooltipContainer content="Chỉnh sửa">
        <DialogTrigger asChild>
          <Button size="icon" variant="outlineCustom">
            <PenLine className="size-4" />
          </Button>
        </DialogTrigger>
      </TooltipContainer>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Chỉnh sửa link</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {haveReference && (
              <ReferenceField
                form={form}
                name="reference"
                defaultReference={link.reference}
              />
            )}
            <FormItem>
              <FormLabel>Code</FormLabel>
              <FormControl>
                <Input disabled value={link.code} />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit, (e) => {
              console.log("e", e);
            })}
          >
            Lưu
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const DeleteLinkDialog = ({ link }: any) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteLinkAdmin,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyLinkAdmin],
      });
      toast({
        variant: "success",
        title: "Xóa link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <TooltipContainer content="Xóa">
        <DialogTrigger asChild>
          <Button size="icon" variant="destructive">
            <Trash className="size-4" />
          </Button>
        </DialogTrigger>
      </TooltipContainer>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Xóa link</DialogTitle>
          <DialogDescription>
            Bạn có chắc chắn muốn xóa link <strong>"{link.name}"</strong>?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            type="submit"
            disabled={isPending}
            variant="destructive"
            onClick={() => mutate(link._id)}
          >
            Xóa
          </Button>
          <Button
            type="submit"
            disabled={isPending}
            onClick={() => setOpen(false)}
          >
            Hủy
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    "-1": {
      variant: "reject",
      text: "Đã xóa",
    },
    "1": {
      variant: "active",
      text: "Hoạt động",
    },
  };
  return (
    <Badge variant={badge[status]?.variant} className="whitespace-nowrap">
      {badge[status]?.text}
    </Badge>
  );
};

const filterLinkSchema = z.object({
  affiliateId: z.string().optional(),
  startTime: z.date().optional(),
  endTime: z.date().optional(),
  status: z.string(),
});

const LinkFilter = ({ open = true }: { open?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const affiliateId = searchParams.get("affiliateId") || "";
  const startTime = searchParams.get("startTime") || "";
  const endTime = searchParams.get("endTime") || "";
  const status = searchParams.get("status") || "";
  const ref = useRef<HTMLFormElement>(null);

  const form = useForm<z.infer<typeof filterLinkSchema>>({
    resolver: zodResolver(filterLinkSchema),
    defaultValues: {
      affiliateId: affiliateId,
      startTime: startTime ? toDate(startTime) : undefined,
      endTime: endTime ? toDate(endTime) : undefined,
      status: status,
    },
  });
  const onSubmit = (values: z.infer<typeof filterLinkSchema>) => {
    setSearchParams((sp) => {
      Object.keys(values).forEach((key) => {
        sp.set(key, values[key as keyof typeof values]?.toString() || "");
      });
      return sp;
    });
  };

  return (
    <>
      <div
        className={`overflow-hidden p-1 duration-200 ${open ? "" : "hidden"}`}
        // style={{
        // 	maxHeight: open ? (ref.current?.clientHeight) : 0,
        // }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
            className="grid-cold-1 grid gap-4 sm:grid-cols-3 md:grid-cols-5"
            ref={ref}
          >
            <FormField
              control={form.control}
              name="affiliateId"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Id</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian bắt đầu</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endTime"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Thời gian kết thúc</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "dd/MM/yyyy", { locale: vi })
                          ) : (
                            <span>Chọn thời gian</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        onSelect={field.onChange}
                        selected={field.value}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="mt-auto">
                  <FormLabel>Status</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue defaultValue={field.value} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">Hoạt động</SelectItem>
                      <SelectItem value="-1">Đã xóa</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-auto flex w-full gap-2">
              <Button type="submit" className="w-full">
                <Filter className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Lọc
                </span>
              </Button>
              <Button
                className="w-full gap-1"
                type="button"
                variant="destructive"
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.delete("affiliateId");
                    sp.delete("startTime");
                    sp.delete("endTime");
                    sp.delete("status");
                    return sp;
                  });
                  form.reset();
                }}
              >
                <FilterX className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap md:sr-only lg:not-sr-only">
                  Xóa lọc
                </span>
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
