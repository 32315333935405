import { Outlet } from "react-router-dom";
import Navbar from "../components/navBar";
import Sidebar from "../components/sideBar";

const Dashboard = () => {
  return (
    <div className="wrapper relative">
      <Navbar />
      <div className="relative ml-auto grid grid-cols-1 items-start pt-8 xl:w-[calc(100%-300px)] xl:pt-0">
        <Sidebar />
        <main className="min-h-[calc(100vh-122px)] px-5 pb-[70px] lg:px-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
