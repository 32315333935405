import { create } from "zustand";
import { IInforDetailAdmin } from "../types/admin";

// interface IlistUser {
// 	listDataUser: IResponseListUser;
// 	getStateListUser: (data: any) => void;
// }

interface IInforUser {
  inforUser: IInforDetailAdmin;
  getInfoUser: (data: any) => void;
  clearInfo: () => void;
}

// const initListUser: IResponseListUser = {
// 	isOver: false,
// 	listData: [],
// 	total: 0,
// };
const defaultUser = {
  _id: "",
  email: "",
  password: "",
  phone: "",
  roles: [],
  status: 0,
  username: "",
  fullname: "",
  avatar: [],
  commissionPercent: 0,
};
export const useInfoUserStore = create<IInforUser>((set, get) => ({
  inforUser: defaultUser,
  getInfoUser: (payload: any) => {
    const amountState = get().inforUser;
    set({ ...amountState, inforUser: payload });
  },
  clearInfo: () => {
    set({ ...get(), inforUser: defaultUser });
  },
}));

// export const useManagerUserStore = create<IlistUser>((set, get) => ({
// 	listDataUser: initListUser,
// 	getStateListUser: (payload: IResponseListUser) => {
// 		const amountState = get().listDataUser;
// 		set({ ...amountState, listDataUser: payload });
// 	},
// }));
