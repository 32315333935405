import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IImage } from "../types/admin";
import { IListResponse, IResponse } from "../types/api";

const url = RouteApi.experts;

export const getListExpertsByCategory = ({ category, limit = 10, skip =0 }:{category: string, skip?: number, limit?: number}) => {
	return apiClient.get(url + '/category-v3', {
		searchParams: {
			categorySlug: category,
			limit: limit,
			skip: skip,
		}
	}).json<IListResponse<{ avatar: IImage, fullname: string, _id: string }>>();
};

export const searchExperts = ({
	search,
}: {
	search: string,
}) => {
	return apiClient.get(url, {
		searchParams: {
			search: search
		}
	}).json<IResponse<any>>();
};