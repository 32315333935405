import { Fragment } from "react/jsx-runtime";
import { menuLinks } from "../constants/menu";
import { ROLE } from "../constants/roles.constants";
import MenuLink from "./menuLink";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";

const Sidebar = () => {
  const role = sessionStorage.getItem("role");
  let getPerssion: Array<number> = [];
  let permissionssss = "";
  try {
    permissionssss = sessionStorage.getItem("permission") || "";
    getPerssion = JSON.parse(permissionssss) || [];
  } catch (error) {
    if (role !== ROLE.USER) window.location.href = "/login";
  }
  const handelRole = (data: any) => {
    if (!data) return true;
    if (getPerssion) return data.some((p: any) => getPerssion.includes(p));
    return false;
  };

  return (
    <aside className="fixed bottom-0 left-0 right-0 z-10 flex justify-center gap-5 border bg-card p-2 xl:bottom-0 xl:top-0 xl:block xl:w-[300px] xl:px-5 xl:py-8">
      <a className="mb-5 hidden items-center gap-2 py-3 xl:flex" href="/">
        <span className="flex size-10 items-center justify-center rounded-full text-lg font-bold">
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/askany.dev/files/728714cc-c183-46ee-8bb2-3a655d5eff5c.png"
            alt="logo"
            className="h-full w-full"
          />
        </span>
        <span className="text-xl font-semibold uppercase">
          Askany Affiliate
        </span>
      </a>
      <ScrollArea className="">
        <ul className="flex flex-row gap-3 xl:flex-col">
          {menuLinks.map((link, index) => {
            if (link.role !== role) {
              return <Fragment key={index}></Fragment>;
            } else if (!handelRole(link?.permission)) {
              return <Fragment key={index}></Fragment>;
            }
            return (
              <li key={index}>
                <MenuLink link={link}></MenuLink>
              </li>
            );
          })}
        </ul>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </aside>
  );
};

export default Sidebar;
