export enum RouteApi {
	administrators = "admin-affiliates/administrators",
	permission = "admin-affiliates/permissions",
	permissionGroups = "admin-affiliates/permission-groups",
	roles = "admin-affiliates/roles",
	affiliates = "affiliates",
	affiliateLinks = "affiliates/affiliate-links",
	affiliateTransactions = "affiliates/transactions",
	tracking = "affiliates/affiliate-trackings",
	affiliateBank = "affiliates/banks",
	adminBank = "admin-affiliates/banks",
	adminTransactions = "admin-affiliates/transactions",
	adminAffiliate = "admin-affiliates",
	categories = "categories",
	experts = "experts",
}
