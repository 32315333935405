import { Check, ChevronsUpDown, PlusCircle } from "lucide-react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import InputFormatMoney from "../../../components/InputFormat/InputFormatAmount";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import {
  ModalDrawer,
  ModalDrawerBody,
  ModalDrawerContent,
  ModalDrawerFooter,
  ModalDrawerHeader,
  ModalDrawerTitle,
  ModalDrawerTrigger,
} from "../../../components/ui/modal-drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { useToast } from "../../../components/ui/use-toast";
import { cn } from "../../../lib/utils";
import {
  ReqCreateTransaction,
  createTransactionAffiliate,
  getListUser,
} from "../../../services/adminAffiliates.service";
import { EKeyQueryApi } from "../../../types/enum";

const CreateTransaction = () => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const queryClient = useQueryClient();
  const formLinkSchema = z.object({
    affiliate: z.string().min(1, "Vui lòng nhập affiliate"),
    bank: z.string().min(1, "Vui lòng nhập tên link"),
    amount: z.number(),
    orderID: z.string().min(1, "Vui lòng nhập mã giao dịch"),
  });
  const { mutate, isPending } = useMutation<
    any,
    HTTPError,
    ReqCreateTransaction,
    unknown
  >({
    mutationFn: createTransactionAffiliate,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Tạo link thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyTransactionsAdmin],
      });
      toast({
        variant: "success",
        title: "Tạo link thành công",
        duration: 2000,
      });
      setOpen(false);
    },
  });
  const [q, setQ] = useState("");

  const {
    data: {
      data: { listData: listUser },
    },
  } = useQuery({
    queryKey: [EKeyQueryApi.keyListUser, q],
    queryFn: () =>
      getListUser({
        skip: 0,
        limit: 10,
        q: q,
      }),

    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
    enabled: open,
  });

  const form = useForm<z.infer<typeof formLinkSchema>>({
    resolver: zodResolver(formLinkSchema),
    defaultValues: {
      affiliate: "",
      bank: "",
      amount: 0,
      orderID: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formLinkSchema>) => {
    const payload: any = {
      affiliate: values.affiliate,
      bank: values.bank,
      amount: values.amount,
      orderID: values.orderID,
    };
    mutate(payload);
  };
  return (
    <ModalDrawer open={open} onOpenChange={setOpen}>
      <ModalDrawerTrigger asChild>
        <Button size="sm" className="h-8 gap-1">
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Tạo giao dịch
          </span>
        </Button>
      </ModalDrawerTrigger>
      <ModalDrawerContent className="sm:max-w-[425px]">
        <ModalDrawerHeader>
          <ModalDrawerTitle>Tạo giao dịch</ModalDrawerTitle>
        </ModalDrawerHeader>
        <ModalDrawerBody className="mt-5">
          <Form {...form}>
            <form className="space-y-3" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="affiliate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Affiliate</FormLabel>
                    <Popover
                      open={openSelect}
                      onOpenChange={setOpenSelect}
                      modal
                    >
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={openSelect}
                            className={cn(
                              "w-full justify-between",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value
                              ? listUser?.find(
                                  (user) => user._id === field.value,
                                )?.fullname
                              : "Lựa chọn"}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="PopoverContent p-0">
                        <Command>
                          <CommandInput
                            placeholder="Tìm kiếm..."
                            value={q}
                            onValueChange={setQ}
                          />
                          <CommandEmpty>Không tìm thấy người dùng</CommandEmpty>
                          <CommandList>
                            {listUser?.map((user) => (
                              <CommandItem
                                value={user.fullname}
                                key={user._id}
                                onSelect={() => {
                                  form.setValue("affiliate", user._id);
                                  form.setValue("bank", user.bank);
                                  setOpenSelect(false);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    user._id === field.value
                                      ? "opacity-100"
                                      : "opacity-0",
                                  )}
                                />
                                <div className="flex items-center gap-x-2">
                                  <Avatar className="size-6">
                                    <AvatarImage
                                      src={
                                        user?.avatar?.location ||
                                        "https://github.com/shadcn.png"
                                      }
                                      alt="@shadcn"
                                    />
                                    <AvatarFallback>CN</AvatarFallback>
                                  </Avatar>
                                  {user.fullname}
                                </div>
                              </CommandItem>
                            ))}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="affiliate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>ID Affiliate</FormLabel>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="bank"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mã tài khoản</FormLabel>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="orderID"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mã giao dịch</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="pt-3 text-sm font-medium leading-none text-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Số tiền cần chuyển (VNĐ)
              </p>
              <InputFormatMoney
                name="amount"
                control={form.control}
                placeholder={"amount"}
              />
            </form>
          </Form>
        </ModalDrawerBody>
        <ModalDrawerFooter>
          <Button
            type="submit"
            disabled={isPending}
            onClick={form.handleSubmit(onSubmit)}
          >
            Tạo giao dịch
          </Button>
        </ModalDrawerFooter>
      </ModalDrawerContent>
    </ModalDrawer>
  );
};

export default CreateTransaction;
