import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IListResponse, IResponse } from "../types/api";

const url = RouteApi.affiliates;

export interface IAffiliateLink {
  readonly _id: string;
  affiliate: string;
  code: string;
  createdAt: string;
  description: string;
  id: string;
  modifiedAt: string;
  name: string;
  status: number;
  totalBook: number;
  totalBookSuccess: number;
  totalClick: number;
  reference: any;
  totalDesktopClick: number;
  totalMobileClick: number;
}

export interface CreateLinkRequest {
  affiliate: string;
  code: string;
  name: string;
  description: string;
  reference?: string;
}

export interface EditLinkRequest {
  id: string;
  name: string;
  description: string;
  reference?: string;
}

interface GetLinkListRequset {
  status: string;
  sort: number;
  skip: number;
  limit: number;
  affiliateId: string;
  startTime: string;
  endTime: string;
}

// interface IAffiliateCreateLink extends GetLinkListRequset {
// 	affiliateLink: string;
// }

export const createLink = (payload: CreateLinkRequest) => {
  return apiClient
    .post(url + "/affiliate-links", { json: payload })
    .json<IAffiliateLink>();
};

export const editLink = ({ id, ...payload }: EditLinkRequest) => {
  return apiClient
    .put(url + "/affiliate-links/" + id, { json: payload })
    .json<IAffiliateLink>();
};

export const deleteLink = (id: string) => {
  return apiClient
    .delete(url + "/affiliate-links/" + id)
    .json<IAffiliateLink>();
};

export const getLinks = (
  payload: Partial<GetLinkListRequset> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliate-links", { searchParams: { ...fields } })
    .json<IListResponse<IAffiliateLink>>();
};

export const getLinkOffersUser = (
  payload: Partial<{
    skip?: number;
    limit?: number;
    sort?: string;
    affiliateId?: string;
    affiliateLink?: string;
    type: string;
  }> = { skip: 0, limit: 5 },
) => {
  const fields = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => v !== "" && v !== undefined),
  );
  return apiClient
    .get(url + "/affiliate-links/offers", { searchParams: { ...fields } })
    .json<
      IResponse<{
        isOver: boolean;
        offers: any[];
        total: number;
      }>
    >();
};
