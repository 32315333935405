import React from "react";
import { Skeleton } from "../ui/skeleton";

const CardTransactionRecent = ({ quantity = 5 }: { quantity?: number }) => {
	return (
		<>
			{Array(quantity)
				.fill(0)
				.map((_, index) => {
					return (
						<div
							key={index}
							className="flex items-center justify-start space-x-4 w-full"
						>
							<Skeleton className="h-12 w-12 rounded-full" />
							<div className="space-y-2">
								<Skeleton className="h-4 w-[250px]" />
								<Skeleton className="h-4 w-[200px]" />
							</div>
						</div>
					);
				})}
		</>
	);
};

export default CardTransactionRecent;
