import { useCookies } from "react-cookie";
import { useInfoAdminStore } from "../store/admin.store";
import { useInfoUserStore } from "../store/user.store";

const useLogout = ({ redirect = "/login" }: { redirect?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie, removeCookie] = useCookies([
    "role",
    "token",
    "id",
    "permissions",
    "permission",
  ]);

  const clearInfoAdmin = useInfoAdminStore((state) => state.clearInfo);
  const clearInfoUser = useInfoUserStore((state) => state.clearInfo);

  const handleLogout = () => {
    removeCookie("role", { path: "/" });
    removeCookie("token", { path: "/" });
    removeCookie("id", { path: "/" });
    removeCookie("permissions", { path: "/" });
    removeCookie("permission", { path: "/" });
    clearInfoAdmin();
    clearInfoUser();
    window.location.href = redirect;
  };

  return handleLogout;
};

export default useLogout;
