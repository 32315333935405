import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Control,
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldValues,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { EActionForm, EKeyQueryApi } from "../../types/enum";

import { HTTPError } from "ky";
import { Lock, Mail, PhoneCall, User } from "lucide-react";
import { createAdmin, updateAdmin } from "../../services/administrators";
import { useRoleStore } from "../../store/role.store";
import { IDetailAdmin, IResponseRole } from "../../types/admin";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { ScrollArea } from "../ui/scroll-area";
import { toast } from "../ui/use-toast";

interface IFormAdmin {
  action?: EActionForm;
  onHandle?: () => void;
  dataEdit: Partial<IDetailAdmin>;
}

const FormSchema = z.object({
  roles: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "Vui lòng chọn quyền",
  }),
  username: z.string().min(3).max(50),
  phone: z.string().min(3).max(12),
  password: z.string().min(5),
  email: z.string().email({ message: "Địa chỉ email không hợp lệ" }),
});

const FormSetRoleAdmin = (props: IFormAdmin) => {
  const {
    action = EActionForm.ADD,
    onHandle = () => {
      return;
    },
    dataEdit = {},
  } = props;
  const queryClient = useQueryClient();

  const { mutate: CreateRoleAccount } = useMutation<
    any,
    HTTPError,
    any,
    unknown
  >({
    mutationFn: action === EActionForm.ADD ? createAdmin : updateAdmin,
    async onError(error, variables, context) {
      const res: { message: string; systemCode: string } =
        await error.response.json();
      toast({
        variant: "destructive",
        title: res.message,
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      form.reset();
      queryClient.invalidateQueries({
        queryKey: [EKeyQueryApi.keyAdmin],
      });
      toast({
        variant: "success",
        title: "Thêm thành công",
        duration: 2000,
      });
      onHandle();
    },
  });

  const listRole = useRoleStore((state) => state.listRole);

  const listPermission = listRole;

  const getPermission = (listPermission: Array<IResponseRole>) => {
    let listPermissionChild: Array<string> = [];
    if ((listPermission?.length || 0) <= 0) return [];
    listPermission.forEach((element) => {
      listPermissionChild.push(element._id);
    });
    return listPermissionChild;
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      roles: getPermission(dataEdit?.roles || []),
      username: dataEdit?.username || "",
      phone: dataEdit?.phone || "",
      password: dataEdit?.password || "",
      email: dataEdit?.email || "",
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (action === EActionForm.ADD) {
      CreateRoleAccount(data);
    } else {
      const {
        formState: { dirtyFields },
      } = form;
      const dataEditForm = getDirtyFields(data, dirtyFields);
      const payloadEdit = { ...dataEditForm, idRole: dataEdit._id || "" };
      CreateRoleAccount(payloadEdit);
    }
  }
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y- mb-5">
          <UpdateFormField
            name="username"
            label="Họ tên"
            placeholder="ABC"
            description="At least 3 characters."
            formControl={form.control}
            icon={User}
          />
          <UpdateFormField
            name="phone"
            label="Số điện thoại"
            placeholder="090xxxxxx"
            description="At least 3 characters."
            formControl={form.control}
            icon={PhoneCall}
          />
          <UpdateFormField
            name="email"
            label="Email"
            placeholder="abc@gmail.com"
            description="At least 3 characters."
            formControl={form.control}
            icon={Mail}
          />
          <UpdateFormField
            name="password"
            label="Mật khẩu"
            placeholder="vui lòng nhập password"
            description="At least 3 characters."
            formControl={form.control}
            icon={Lock}
            inputType="password"
          />
          <p className="border-t pt-4 text-sm font-semibold uppercase">
            Vai trò của admin
          </p>
          <ScrollArea className="h-[100px]">
            <FormField
              control={form.control}
              name="roles"
              render={() => (
                <FormItem>
                  <div className="my-4 flex flex-wrap gap-5">
                    {listPermission.map((permission) => (
                      <FormField
                        key={permission._id}
                        control={form.control}
                        name="roles"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={permission._id}
                              className="permissions-start flex flex-row space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(
                                    permission._id,
                                  )}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([
                                          ...field.value,
                                          permission._id,
                                        ])
                                      : field.onChange(
                                          field.value?.filter(
                                            (value) => value !== permission._id,
                                          ),
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="font-normal">
                                {permission.roleName}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </ScrollArea>
          <div className="text-right">
            <Button type="submit">
              {action === EActionForm.ADD ? "Thêm tài khoản" : "Cập nhập"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}

interface UpdateFormFieldProps {
  name: FieldPath<z.infer<typeof FormSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof FormSchema>, any>;
  icon: any;
}

const UpdateFormField: React.FC<UpdateFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              type={inputType || "text"}
              className="font-medium"
              Icon={icon}
              {...field}
            />
          </FormControl>
          <div className="py-1"></div>
          {/* {description && <FormDescription>{description}</FormDescription>} */}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
export default FormSetRoleAdmin;
