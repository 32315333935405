import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ClipboardIcon, ExternalLink, LoaderCircleIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import Paginate from "../../../components/Paginate";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../components/ui/table";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../../components/ui/tooltip";
import { getLinksAdmin } from "../../../services/adminAffiliates.service";
import { EKeyQueryApi } from "../../../types/enum";
import DeleteLinkDialog from "../../affiliate/LinkAffiliate/DeleteLinkDialog";
import EditLinkForm from "../../affiliate/LinkAffiliate/EditLinkForm";
import { useCopyToClipboard } from "../../../hooks/useCopy";
import { useToast } from "../../../components/ui/use-toast";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "../../../components/ui/avatar";

const DetailUser = () => {
	const params = useParams();
	const { toast } = useToast();
	const {
		data: { data: linkList },
		isFetching,
	} = useQuery({
		queryKey: [EKeyQueryApi.keyLinkAdmin],
		queryFn: () =>
			getLinksAdmin({
				skip: 0,
				limit: 10,
				affiliateId: params.id,
			}),
		initialData: {
			data: {
				listData: [],
				isOver: true,
				total: 0,
			},
			message: "",
			systemCode: "",
		},
		retry: false,
	});
	const [, copy] = useCopyToClipboard();

	const handleCopy = (text: string) => {
		copy(text)
			.then(() => {
				toast({
					title: "Copy thành công",
					description: text,
				});
			})
			.catch((error) => {
				console.error("Failed to copy!", error);
			});
	};

	return (
		<>
			<Card>
				<CardHeader>Chi tiết tài khoản</CardHeader>
				<CardContent>
					<Avatar>
						<AvatarImage src="https://github.com/shadcn.png" />
						<AvatarFallback>CN</AvatarFallback>
					</Avatar>
					Tên người dùng
				</CardContent>
			</Card>
			<div className="mt-4"></div>
			<Card>
				<Table className="overflow-x-auto  min-w-[1400px]">
					<TableHeader>
						<TableRow>
							<TableHead>Tên link</TableHead>
							<TableHead>Code</TableHead>
							<TableHead>Trạng thái</TableHead>
							<TableHead>Lượt click</TableHead>
							<TableHead>Ngày tạo</TableHead>
							<TableHead>Actions</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{!isFetching &&
							linkList?.listData.map((link) => (
								<TableRow key={link._id}>
									<TableCell className="font-medium">{link.name}</TableCell>
									<TableCell>{link.code}</TableCell>
									<TableCell className="text-center">
										<StatusBadge status={link.status} />
									</TableCell>
									<TableCell className="text-center">
										{link.totalClick}
									</TableCell>
									<TableCell>
										{dayjs(link.createdAt).format("HH:mm DD/MM/YYYY")}
									</TableCell>
									<TableCell className="flex gap-2">
										<Link to={`${link._id}/services`} target="blank">
											<Button variant="ghost">
												<ExternalLink className="h-3.5 w-3.5" />
											</Button>
										</Link>
										<EditLinkForm link={link} />
										<DeleteLinkDialog link={link} />
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<Button
														onClick={() => handleCopy("jhjhj")}
														type="button"
														variant="ghost"
													>
														<ClipboardIcon className="h-4 w-4" />
													</Button>
												</TooltipTrigger>
												<TooltipContent>
													<p>Click to copy</p>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				{!isFetching && linkList?.listData.length <= 0 && (
					<div className="my-10 w-full text-center">Không tìm thấy kết quả</div>
				)}
				{isFetching && (
					<div className="grid place-content-center h-20 w-full">
						<LoaderCircleIcon className="animate-spin" />
					</div>
				)}
			</Card>
			{!isFetching && (
				<div className="mt-2">
					<Paginate
						isOver={linkList.isOver}
						total={linkList.total}
						limit={10}
						skip={0}
					/>
				</div>
			)}
		</>
	);
};

export default DetailUser;

const StatusBadge = ({ status }: { status: any }) => {
	const badge: any = {
		"-1": {
			variant: "reject",
			text: "Đã xóa",
		},
		"1": {
			variant: "active",
			text: "Hoạt động",
		},
	};
	return (
		<Badge variant={badge[status]?.variant} className="whitespace-nowrap ">
			{badge[status]?.text}
		</Badge>
	);
};
