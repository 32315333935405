import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Loader2, MoreVertical, Phone, Users } from "lucide-react";
import { useState } from "react";
import ModalContainer from "../../../../components/modal/ModalContainer";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "../../../../components/ui/avatar";
import { Button } from "../../../../components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { toast } from "../../../../components/ui/use-toast";
import { FormatMoneyToFixed } from "../../../../helpers/formatNumber";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { deleteReferencePopulars_Admin } from "../../../../services/adminService/referencePopulars.services";
import { EKeyQueryApi } from "../../../../types/enum";

interface IExpertTopTrending {
	data: any;
}

const ExpertTrending = (props: IExpertTopTrending) => {
	const { data = [] } = props;
	const desktop = "(min-width: 640px)";
	const isDesktop = useMediaQuery(desktop);
	const [isDeleteTop, setDeleteTop] = useState<{
		idCard: string;
		openDelete: boolean;
	}>({
		idCard: "",
		openDelete: false,
	});

	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
		mutationFn: deleteReferencePopulars_Admin,
		async onError(error, variables, context) {
			toast({
				variant: "destructive",
				title: "Xóa thẻ thất bại",
				duration: 2000,
			});
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyPopular] });
			toast({
				variant: "success",
				title: "Xóa thẻ thành công",
				duration: 2000,
			});
			setDeleteTop({ openDelete: false, idCard: "" });
		},
	});
	return (
		<>
			<div
				className={` ${
					isDesktop
						? "grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-5"
						: "grid_snap_topExpert"
				}`}
			>
				{data.map((item: any, index: number) => {
					return (
						<div
							key={index}
							className="flex items-start gap-3 p-5 bg-white border shadow-sm rounded-xl dark:bg-card dark:border-neutral-700 dark:shadow-neutral-700/70"
						>
							<div className="w-full  min-w-12 max-w-12 hidden min-[500px]:block ">
								<Avatar className="size-12">
									<AvatarImage
										src={
											item?.avatar?.location || "https://github.com/shadcn.png"
										}
										alt="@shadcn"
										className="object-cover w-full h-full"
									/>
									<AvatarFallback>CN</AvatarFallback>
								</Avatar>
							</div>
							<div className="flex flex-wrap">
								<div className="flex flex-wrap items-center justify-between w-full gap-3">
									<h3 className="max-w-full overflow-hidden text-lg font-bold text-gray-800 dark:text-white line-clamp-2">
										{item?.fullname || ""}
									</h3>
									<div className="flex items-center gap-2">
										<div className="flex items-start">
											<svg
												className="leading-5 text-yellow-300 size-5"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="currentColor"
												viewBox="0 0 22 20"
											>
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<p className="ml-1 text-sm font-bold text-gray-900 dark:text-white">
												{item?.stars || 5}.0
											</p>
										</div>
										<DropdownMenu>
											<DropdownMenuTrigger asChild>
												<Button variant="ghost" size="icon">
													<MoreVertical className="w-4 h-4" />
													<span className="sr-only">More</span>
												</Button>
											</DropdownMenuTrigger>
											<DropdownMenuContent align="end">
												<DropdownMenuItem
													onClick={() =>
														setDeleteTop({
															openDelete: true,
															idCard: item?._id || "",
														})
													}
													className="font-medium text-red-500"
												>
													Xóa
												</DropdownMenuItem>
											</DropdownMenuContent>
										</DropdownMenu>
									</div>
								</div>
								<div className="space-y-1">
									<div className="flex items-center justify-start gap-2 text-foreground">
										<Phone className="size-[15px]" />
										<span className="mt-1 text-sm font-medium">
											{FormatMoneyToFixed(item?.callPrice?.cost)} vnđ (15 phút)
										</span>
									</div>
									{(item?.meetPrice?.cost || 0) <= 0 ? (
										""
									) : (
										<div className="flex items-center justify-start gap-2 text-foreground">
											<Users className="size-[15px]" />
											<span className="mt-1 text-sm font-medium">
												{FormatMoneyToFixed(item?.meetPrice?.cost)} vnđ (60
												phút)
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<ModalContainer
				isVisible={isDeleteTop.openDelete}
				closeModal={() => setDeleteTop({ ...isDeleteTop, openDelete: false })}
				titleModal="Xóa thẻ"
			>
				<div>
					<p>Bạn có muốn xóa thẻ của chuyên gia này không?</p>
					<div className="flex items-center justify-end gap-5 mt-10">
						<Button
							onClick={() => setDeleteTop({ openDelete: false, idCard: "" })}
							className="px-10"
							variant="ghost"
						>
							Hủy
						</Button>
						<Button
							disabled={isPending}
							onClick={() => mutate(isDeleteTop?.idCard || "")}
							className="px-5"
							variant={"destructive"}
						>
							{isPending && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
							Tiếp tục
						</Button>
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export default ExpertTrending;
