import { useQuery } from "@tanstack/react-query";
import { MoreVertical, Phone, Users } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import SkeletonCardTopExpert from "../../../../components/SkeletonContainer/CardTopExpert";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../components/ui/avatar";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { FormatMoneyToFixed } from "../../../../helpers/formatNumber";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { getListCategories } from "../../../../services/categories.services";
import {
  CATEGORY_CHILDS_MODEL,
  resGetCategoryListWithChilds,
} from "../../../../types";
import { EKeyQueryApi } from "../../../../types/enum";

interface IExpertTopTrending {
  data: any;
  isLoading?: boolean;
}

const ExpertTrendingUser = (props: IExpertTopTrending) => {
  const { data = [], isLoading = false } = props;
  const desktop = "(min-width: 768px)";
  const isDesktop = useMediaQuery(desktop);

  const { data: listAllCate } = useQuery({
    queryKey: [EKeyQueryApi.keyCategories],
    queryFn: getListCategories,
    initialData: {
      data: [],
      message: "",
      systemCode: "",
    },
  });

  const mapCate = React.useCallback(
    (
      cateList: any[] = [],
      cate: CATEGORY_CHILDS_MODEL | string,
    ): resGetCategoryListWithChilds | undefined => {
      const idCate = typeof cate === "string" ? cate : cate?._id;
      const isCate = cateList.find((c) => c._id === idCate);
      if (isCate) return isCate;
      for (let i = 0; i < cateList.length; i++) {
        const p = mapCate(cateList[i].childs, cate);
        if (p) return p;
      }
    },
    [],
  );

  const listCategory = listAllCate.data || [];

  const renderTitleCate = (list: any) => {
    if ((list?.length || 0) <= 0) return "";
    let newList: string[] = [];
    list.forEach((element: any) => {
      const cateDetail = mapCate(listCategory, element);
      newList.push(cateDetail?.vi?.name || "");
    });
    if ((newList?.length || 0) <= 0) return "";
    if (newList.length === 2) return `Chuyên gia ${newList.join(", ")}`;
    if (newList.length >= 3)
      return `Chuyên gia về ${newList[0]} và ${
        newList.length - 1
      } lĩnh vực khác`;
  };

  if (isLoading)
    return (
      <SkeletonCardTopExpert
        className={`${
          isDesktop
            ? "grid grid-cols-2 gap-5 lg:grid-cols-3"
            : "grid_snap_topExpert"
        }`}
      />
    );

  return (
    <>
      <div
        className={`grid_snap_topExpert grid xl:grid-cols-2 xl:gap-8 2xl:grid-cols-3`}
      >
        {data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="flex items-start gap-3 rounded-lg border bg-white p-5 shadow-sm dark:border-neutral-700 dark:bg-card dark:shadow-neutral-700/70"
            >
              <div className="hidden w-full min-w-12 max-w-12 xl:block">
                <Avatar className="size-12">
                  <AvatarImage
                    src={
                      item?.avatar?.location || "https://github.com/shadcn.png"
                    }
                    alt="@shadcn"
                    className="h-full w-full object-cover"
                  />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-wrap gap-y-2">
                <div className="flex w-full items-start justify-between gap-3">
                  <div>
                    <h3 className="line-clamp-2 max-w-full overflow-hidden text-lg font-bold text-gray-800 dark:text-white">
                      {item?.fullname || ""}
                    </h3>
                    <p className="line-clamp-1 text-sm font-semibold text-slate-400">
                      {renderTitleCate(item?.categories)}
                    </p>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="flex items-start">
                      <svg
                        className="size-5 leading-5 text-yellow-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <p className="ml-1 text-sm font-bold text-gray-900 dark:text-white">
                        {item?.stars || 5}.0
                      </p>
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <MoreVertical className="h-4 w-4" />
                          <span className="sr-only">More</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem className="font-medium">
                          <Link
                            target="_blank"
                            to={`https://askany.com/${item?.expertname}`}
                          >
                            Chi tiết chuyên gia
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem className="font-medium">
                          <Link to={`/links`}>Tạo link với chuyên gia</Link>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center justify-start gap-2">
                    <Phone className="size-[15px]" />
                    <span className="mt-1 text-sm font-medium">
                      {FormatMoneyToFixed(item?.callPrice?.cost)} vnđ / 15 phút
                    </span>
                  </div>
                  {(item?.meetPrice?.cost || 0) <= 0 ? (
                    ""
                  ) : (
                    <div className="flex items-center justify-start gap-2">
                      <Users className="size-[15px]" />
                      <span className="mt-1 text-sm font-medium">
                        {FormatMoneyToFixed(item?.meetPrice?.cost)} vnđ / 60
                        phút
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ExpertTrendingUser;
