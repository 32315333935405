import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { Mail, Phone } from "lucide-react";
import React from "react";
import {
  Control,
  FieldNamesMarkedBoolean,
  FieldPath,
  FieldValues,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { editprofileUser } from "../../services/affiliate";
import { IInforDetailAdmin } from "../../types/admin";
import { EKeyQueryApi } from "../../types/enum";
import { IRequestBodyEditUser } from "../../types/user";
import { toast } from "../ui/use-toast";

const schemaEditProfile = z.object({
  phone: z.string().optional(),
  email: z.string().email({ message: "Địa chỉ email không hợp lệ" }),
  avatar: z.any(),
  facebook: z.union([
    z.literal(""),
    z.string().trim().url("Vui lòng nhập link hợp lệ"),
  ]),
  tiktok: z.union([
    z.literal(""),
    z.string().trim().url("Vui lòng nhập link hợp lệ"),
  ]),
  instagram: z.union([
    z.literal(""),
    z.string().trim().url("Vui lòng nhập link hợp lệ"),
  ]),
});

interface IFromReset {
  onHandle?: () => void;
  onChangePhone?: () => void;
  dataUser: IInforDetailAdmin;
}
const FormEditProfile = (props: IFromReset) => {
  const {
    onHandle = () => {
      return;
    },
    onChangePhone = () => {
      return;
    },
    dataUser,
  } = props;
  const queryClient = useQueryClient();
  const { mutate } = useMutation<any, HTTPError, IRequestBodyEditUser, unknown>(
    {
      mutationFn: editprofileUser,
      async onError(error, variables, context) {
        const res: { message: string; systemCode: string } =
          await error.response.json();
        toast({
          variant: "destructive",
          title: res.message,
          duration: 2000,
        });
      },
      onSuccess(data, variables, context) {
        formResetPassword.reset();
        toast({
          variant: "success",
          title: "Cập nhập thành công",
          duration: 2000,
        });
        queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyUser] });
        onHandle();
      },
    },
  );

  const formResetPassword = useForm<z.infer<typeof schemaEditProfile>>({
    resolver: zodResolver(schemaEditProfile),
    defaultValues: {
      email: dataUser?.email || "",
      phone: dataUser?.phone || "",
      facebook: dataUser?.facebook || "",
      tiktok: dataUser?.tiktok || "",
      instagram: dataUser?.instagram || "",
    },
  });

  const {
    formState: { dirtyFields },
  } = formResetPassword;
  const onSubmit = async (values: z.infer<typeof schemaEditProfile>) => {
    const payload = getDirtyFields(values, dirtyFields);
    mutate(payload);
  };

  return (
    <div>
      <Form {...formResetPassword}>
        <form
          onSubmit={formResetPassword.handleSubmit(onSubmit)}
          className="space-y-4"
        >
          <SignupFormField
            name="email"
            label="Email"
            placeholder="abc@gmail.com"
            description="At least 8 characters."
            formControl={formResetPassword.control}
            icon={Mail}
          />
          <SignupFormField
            name="phone"
            label="Số điện thoại"
            placeholder="090xxxxxx0"
            description="At least 8 characters."
            formControl={formResetPassword.control}
            inputType="number"
            icon={Phone}
            disabled
          />

          <SignupFormField
            name="facebook"
            label="Facebook"
            placeholder=""
            formControl={formResetPassword.control}
            icon={() => (
              <img className="" src="/icons/ic_facebook.png" alt="" />
            )}
          />
          <SignupFormField
            name="tiktok"
            label="Tiktok"
            placeholder=""
            formControl={formResetPassword.control}
            icon={() => <img className="" src="/icons/ic_tiktok.png" alt="" />}
          />
          <SignupFormField
            name="instagram"
            label="Instagram"
            placeholder=""
            formControl={formResetPassword.control}
            icon={() => (
              <img className="" src="/icons/ic_instagram.png" alt="" />
            )}
          />
          <div className="flex items-center justify-end gap-3 pt-4">
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="outlineCustom"
              type="button"
              onClick={onChangePhone}
            >
              Thay đổi số điện thoại
            </Button>
            <Button
              className="w-fit rounded-[8px] py-4 text-sm font-medium"
              variant="defaultCustom"
              type="submit"
            >
              Cập nhập
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

interface SignupFormFieldProps {
  name: FieldPath<z.infer<typeof schemaEditProfile>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: React.HTMLInputTypeAttribute;
  formControl: Control<z.infer<typeof schemaEditProfile>, any>;
  icon: any;
  disabled?: boolean;
}

const SignupFormField: React.FC<SignupFormFieldProps> = ({
  name,
  label,
  placeholder,
  description,
  inputType,
  formControl,
  icon,
  disabled,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
          <FormControl>
            <Input
              {...field}
              placeholder={placeholder}
              type={"text"}
              className="font-medium"
              Icon={icon}
              disabled={disabled}
              onChange={(e) => {
                if (inputType === "number") {
                  field.onChange(e.target.value.replaceAll(/[^0-9]+/g, ""));
                } else {
                  field.onChange(e);
                }
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormEditProfile;

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach((key) => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}
