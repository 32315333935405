import { RouteApi } from "../apis/routeApi";
import apiClient from "../apis/user.api";
import { IImage } from "../types/admin";
import { IResponse } from "../types/api";

const url = RouteApi.categories;

interface ICategoryLang {
  _id: string;
  name: string;
  slug: string;
}

export interface ICategory {
  _id: string;
  parent: ICategory;
  vi: ICategoryLang;
  en: ICategoryLang;
  icon: IImage;
  totalExpert: number;
  childs: Array<ICategory>;
}

export const getListCategories = () => {
  return apiClient.get(url).json<IResponse<Array<ICategory>>>();
};
