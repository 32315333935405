import {
  Eye,
  ListFilter,
  Loader2,
  LoaderCircleIcon,
  Trash2,
} from "lucide-react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { HTTPError } from "ky";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import NoData from "../../../components/NoData";
import Paginate from "../../../components/Paginate";
import FormConfirmBank from "../../../components/form/formConfirmBank";
import ModalContainer from "../../../components/modal/ModalContainer";
import ModalHandleContainer from "../../../components/modalHandle/ModalContainer";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { toast } from "../../../components/ui/use-toast";
import {
  deleteCardBank,
  getListBankUser,
} from "../../../services/bank.services";
import { EKeyQueryApi, EStatusBank } from "../../../types/enum";
import { TDetailBank } from "../../../types/user";

const ManagerBank = () => {
  const [position, setPosition] = useState("-1");
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      skip: "0",
      limit: "5",
      status: position,
    }),
  );
  const skip = parseInt(searchParams.get("skip") || "0");
  const limit = parseInt(searchParams.get("limit") || "5");
  // const status = parseInt(searchParams.get("status") || "1");

  const { data: result, isFetching } = useQuery({
    queryKey: [EKeyQueryApi.keyBank, limit, skip, position],
    queryFn: () =>
      getListBankUser({
        skip: skip,
        limit: limit,
        status: +position,
      }),
    initialData: {
      data: {
        listData: [],
        isOver: true,
        total: 0,
      },
      message: "",
      systemCode: "",
    },
    retry: false,
  });

  const listBank = result?.data;

  return (
    <>
      <div className="mt-3">
        <h1 className="mb-8 text-3xl font-extrabold">Quản lý thẻ</h1>
        <div className="flex items-center pb-5">
          <div className="flex items-center justify-start gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-8 gap-1">
                  <ListFilter className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Filter
                  </span>
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Panel Position</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={position}
                  onValueChange={(value) => {
                    setPosition(value);
                    setSearchParams((params) => {
                      params.set("status", value);
                      return params;
                    });
                  }}
                >
                  <DropdownMenuRadioItem value={"1"}>
                    Đã duyệt
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={"-1"}>
                    Chờ duyệt
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={"0"}>
                    Đã xóa
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={"-2"}>
                    Từ chối
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <ManagerBankTable
          isFetching={isFetching}
          listData={listBank}
          page={skip}
          limit={limit}
        />
      </div>
    </>
  );
};

const ManagerBankTable = ({
  isFetching,
  listData,
  page,
  limit,
}: {
  isFetching: boolean;
  listData: {
    total: number;
    isOver: boolean;
    listData: Array<TDetailBank>;
  };
  page: number;
  limit: number;
}) => {
  const [isInfoBank, setInfoBank] = useState<{
    openInfo: boolean;
    idBank: Partial<TDetailBank>;
  }>({
    openInfo: false,
    idBank: {},
  });
  const [isDeleteBank, setDeleteBank] = useState<{
    openDelete: boolean;
    idBank: Partial<TDetailBank>;
  }>({
    openDelete: false,
    idBank: {},
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation<any, HTTPError, string, unknown>({
    mutationFn: deleteCardBank,
    async onError(error, variables, context) {
      toast({
        variant: "destructive",
        title: "Xóa thẻ thất bại",
        duration: 2000,
      });
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [EKeyQueryApi.keyBank] });
      toast({
        variant: "success",
        title: "Xóa thẻ thành công",
        duration: 2000,
      });
      // setOpen(false);
      setDeleteBank({ openDelete: false, idBank: {} });
    },
  });

  return (
    <>
      <Card>
        <Table className="min-w-[1400px] overflow-x-auto">
          <TableHeader>
            <TableRow>
              <TableHead>Họ tên</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Tên Ngân hàng</TableHead>
              <TableHead>Số tài khoản</TableHead>
              <TableHead>Trạng thái</TableHead>
              <TableHead>Ngày tạo</TableHead>
              <TableHead>
                <span>Thao tác</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isFetching &&
              listData.listData?.map((link) => (
                <TableRow key={link._id} className="font-medium">
                  <TableCell className="font-medium">
                    {link.accountName}
                  </TableCell>
                  <TableCell>{link.email}</TableCell>

                  <TableCell className="line-clamp-1 text-left">
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className="cursor-default">
                            {link.bankCode}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>{link.bankName}</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell>{link.cardNumber}</TableCell>
                  <TableCell>
                    <StatusBadge status={link.status} />
                  </TableCell>
                  <TableCell>
                    {dayjs(link.createdAt).format("DD/MM/YYYY - HH:mm ")}
                  </TableCell>
                  <TableCell className="flex gap-2">
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="iconCustom"
                            onClick={() =>
                              setInfoBank({ idBank: link, openInfo: true })
                            }
                          >
                            <Eye className="size-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Chi tiết</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() =>
                              setDeleteBank({ openDelete: true, idBank: link })
                            }
                            variant="iconCustom"
                          >
                            <Trash2 className="size-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Xóa</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isFetching && (
          <div className="grid h-20 w-full place-content-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
        {!isFetching && (listData.listData?.length || 0) <= 0 && <NoData />}
      </Card>
      {!isFetching && (
        <div className="mt-2">
          <Paginate
            isOver={listData.isOver}
            total={listData.total}
            limit={limit}
            skip={page}
          />
        </div>
      )}

      {isInfoBank.openInfo && (
        <ModalHandleContainer
          isVisible={isInfoBank.openInfo}
          closeModal={() => setInfoBank({ ...isInfoBank, openInfo: false })}
          titleModal="Chi tiết thẻ"
        >
          <FormConfirmBank
            onHandle={() => setInfoBank({ openInfo: false, idBank: {} })}
            dataBank={isInfoBank.idBank}
          />
        </ModalHandleContainer>
      )}
      {isDeleteBank.openDelete && (
        <ModalContainer
          isVisible={isDeleteBank.openDelete}
          closeModal={() =>
            setDeleteBank({ ...isDeleteBank, openDelete: false })
          }
          titleModal="Xóa thẻ"
        >
          <div>
            <p>
              Bạn có muốn xóa thẻ của{" "}
              <span className="font-semibold">
                {isDeleteBank.idBank.accountName}
              </span>{" "}
              không?
            </p>
            <div className="mt-10 flex items-center justify-end gap-5">
              <Button
                onClick={() => setDeleteBank({ openDelete: false, idBank: {} })}
                className="px-10"
                variant="ghost"
              >
                Hủy
              </Button>
              <Button
                disabled={isPending}
                onClick={() => mutate(isDeleteBank?.idBank?._id || "")}
                className="px-5"
                variant={"destructive"}
              >
                {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Tiếp tục
              </Button>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};
const StatusBadge = ({ status }: { status: any }) => {
  const badge: any = {
    [EStatusBank.Active]: {
      variant: "active",
      text: "Đã duyệt",
    },
    [EStatusBank.Deleted]: {
      variant: "reject",
      text: "Đã xóa",
    },
    [EStatusBank.Pending]: {
      variant: "pending",
      text: "Chờ xét duyệt",
    },
    [EStatusBank.Reject]: {
      variant: "reject",
      text: "Từ chối",
    },
  };
  return <Badge variant={badge[status]?.variant}>{badge[status]?.text}</Badge>;
};
export default ManagerBank;
